import { FetchClient } from "../utils/fetchClient";

export async function getFranchiseCustomers(franchiseId = 0, phoneNumber = "", zipCode = "", page = 1, pageSize = 50, customerName = "", createdOnOrAfter = "", createdBefore = "") {
    return await FetchClient(`/data/service-titan/customers?franchise_id=${franchiseId}&phone=${phoneNumber}&zip=${zipCode}&name=${customerName}&page=${page}&pageSize=${pageSize}&createdOnOrAfter=${createdOnOrAfter}&createdBefore=${createdBefore}`).GET({})
}

export async function createFranchiseCustomer(serviceTitanTenantId, stClientId, clientSecret, customerCreateRequest = {}) {
    return await FetchClient(`/data/service-titan/customer?serviceTitanTenantId=${serviceTitanTenantId}&stClientId=${stClientId}&stClientSecret=${clientSecret}`).POST({ payload: JSON.stringify(customerCreateRequest) })
}

export async function updateFranchiseCustomer(franchiseId, customerId, customerUpateRequest = {}) {
    return await FetchClient(`/data/service-titan/customer?franchise_id=${franchiseId}&id=${customerId}`).PUT({ payload: JSON.stringify(customerUpateRequest) })
}

export async function getJobTypes(franchiseId) {
    return await FetchClient(`/data/service-titan/job-types?franchise_id=${franchiseId}`).GET({})
}

export async function getBusinessUnits(franchiseId, isActive = true) {
    return await FetchClient(`/data/service-titan/business-units?franchise_id=${franchiseId}&active=${isActive}`).GET({})
}

export async function getLeads(ids = [], brandId = "", franchiseId = "", serviceTitanTenantId = "", status = "Open", page = 1, pageSize = 50, createdStartDate = "", createdEndDate = "", followUpStartDate = "", followUpEndDate = "", middlewareAssignedToId = 0, priority = "") {
    let idQueryString = ""
    if (Array.isArray(ids)) {
        idQueryString = ids?.join(",")
    }
    return await FetchClient(`/data/service-titan/leads?id=${idQueryString}&brandId=${brandId}&franchiseId=${franchiseId}&serviceTitanTenantId=${serviceTitanTenantId}&status=${status}&createdStartDate=${createdStartDate}&createdEndDate=${createdEndDate}&page=${page}&pageSize=${pageSize}&followUpStartDate=${followUpStartDate}&followUpEndDate=${followUpEndDate}&middlewareAssignedToId=${middlewareAssignedToId}&priority=${priority}`).GET({})
}

export async function getLeadById(leadId = "") {
    return await FetchClient(`/data/service-titan/lead?id=${leadId}`).GET({})
}

export async function getServiceTitanCustomerLeads(franchiseId = "", customerId = "") {
    return await FetchClient(`/data/service-titan/customer/leads?franchise_id=${franchiseId}&customerId=${customerId}`).GET({})
}

export async function getCampaigns(franchiseId = 0) {
    return await FetchClient(`/data/service-titan/campaigns?franchise_id=${franchiseId}`).GET({})
}

export async function getCampaignById(serviceTitanTenantId, stClientId, clientSecret, campaignId) {
    return await FetchClient(`/data/service-titan/campaign?serviceTitanTenantId=${serviceTitanTenantId}&stClientId=${stClientId}&stClientSecret=${clientSecret}&campaignId=${campaignId}`).GET({})
}

export async function getCallReasons(serviceTitanTenantId, stClientId, clientSecret) {
    return await FetchClient(`/data/service-titan/call-reasons?serviceTitanTenantId=${serviceTitanTenantId}&stClientId=${stClientId}&stClientSecret=${clientSecret}`).GET({})
}

export async function getServiceTitanLeadSpecificNote(serviceTitanTenantId, stClientId, clientSecret, leadId, page = 1, pageSize = 50) {
    return await FetchClient(`/data/service-titan/lead-specific/notes?serviceTitanTenantId=${serviceTitanTenantId}&stClientId=${stClientId}&stClientSecret=${clientSecret}&lead_id=${leadId}&page=${page}&page_size=${pageSize}`).GET({})
}

export async function addServiceTitanLeadSpecificNote(serviceTitanTenantId, stClientId, clientSecret, franchiseId, leadId, addNoteRequest) {
    return await FetchClient(`/data/service-titan/lead-specific/notes?serviceTitanTenantId=${serviceTitanTenantId}&stClientId=${stClientId}&stClientSecret=${clientSecret}&franchise_id=${franchiseId}&lead_id=${leadId}`).POST({ payload: JSON.stringify(addNoteRequest) })
}

export async function getServiceTitanFranchisesByBrandId(brandId) {
    return await FetchClient(`/data/service-titan/service-titan-franchises?brandId=${brandId}`).GET({})
}

export async function getNonDeletedFranchisesByBrandId(brandId, crm = "") {
    return await FetchClient(`/data/franchises/crm?brand_id=${brandId}&crm=${crm}`).GET({})
}

export async function createLead(body) {
    return await FetchClient(`/data/service-titan/leads`).POST({ payload: JSON.stringify(body) })
}
export async function getCustomerById(serviceTitanTenantId, stClientId, clientSecret, customerId) {
    return await FetchClient(`/data/service-titan/customer?serviceTitanTenantId=${serviceTitanTenantId}&stClientId=${stClientId}&stClientSecret=${clientSecret}&customerId=${customerId}`).GET({})
}

export async function getACustomerContacts(franchiseId, customerId) {
    return await FetchClient(`/data/service-titan/customer/contacts?franchise_id=${franchiseId}&customerId=${customerId}`).GET({})
}

export async function getJobCancelReasons(franchiseId) {
    return await FetchClient(`/data/service-titan/job-cancel-reasons?franchise_id=${franchiseId}`).GET({})
}

export async function getJobsCancelReasons(franchiseId, jobIds = []) {
    let idQueryString = ""
    if (Array.isArray(jobIds)) {
        for (let i = 0; i < jobIds.length; i++) {
            idQueryString += `&job_id=${jobIds[i]}`
        }
    }
    return await FetchClient(`/data/service-titan/jobs-cancel-reasons?franchise_id=${franchiseId}${idQueryString}`).GET({})
}

export async function updateCustomerContact(franchise_id, customerId, contactId, contactUpdateRequest) {
    return await FetchClient(`/data/service-titan/customer/contacts?franchise_id=${franchise_id}&customer_id=${customerId}&contact_id=${contactId}`).PUT({ payload: JSON.stringify(contactUpdateRequest) })
}

export async function deleteCustomerContacts(franchise_id, customerId, contactId) {
    return await FetchClient(`/data/service-titan/customer/contacts?franchise_id=${franchise_id}&customer_id=${customerId}&contact_id=${contactId}`).DELETE({})
}

export async function getLocationsOfACustomer(franchiseId = 0, customerId = 0) {
    return await FetchClient(`/data/service-titan/locations?franchise_id=${franchiseId}&customerId=${customerId}`).GET({})
}

export async function getLocationById(serviceTitanTenantId, stClientId, clientSecret, locationId) {
    return await FetchClient(`/data/service-titan/location?serviceTitanTenantId=${serviceTitanTenantId}&stClientId=${stClientId}&stClientSecret=${clientSecret}&locationId=${locationId}`).GET({})
}
export async function getJobTypeById(serviceTitanTenantId, stClientId, clientSecret, jobTypeId) {
    return await FetchClient(`/data/service-titan/job-type?serviceTitanTenantId=${serviceTitanTenantId}&stClientId=${stClientId}&stClientSecret=${clientSecret}&jobTypeId=${jobTypeId}`).GET({})
}

export async function getTagTypes(serviceTitanTenantId, stClientId, clientSecret) {
    return await FetchClient(`/data/service-titan/tag-types?serviceTitanTenantId=${serviceTitanTenantId}&stClientId=${stClientId}&stClientSecret=${clientSecret}`).GET({})
}

export async function getZones(franchiseId) {
    return await FetchClient(`/data/service-titan/zones?franchise_id=${franchiseId}`).GET({})
}

export async function getBusinessUnitById(serviceTitanTenantId, stClientId, clientSecret, businessUnitId) {
    return await FetchClient(`/data/service-titan/business-unit?serviceTitanTenantId=${serviceTitanTenantId}&stClientId=${stClientId}&stClientSecret=${clientSecret}&businessUnitId=${businessUnitId}`).GET({})
}

export async function editLead(serviceTitanTenantId, stClientId, clientSecret, leadId, editLeadBody = { businessUnitId: "", campaignId: "", jobTypeId: "", priority: "" }) {
    return await FetchClient(`/data/service-titan/leads?serviceTitanTenantId=${serviceTitanTenantId}&stClientId=${stClientId}&stClientSecret=${clientSecret}&leadId=${leadId}`).PUT({ payload: JSON.stringify(editLeadBody) })
}

export async function dismissLead(serviceTitanTenantId, stClientId, clientSecret, leadId, dismissLeadBody = {}) {
    return await FetchClient(`/data/service-titan/leads/dismiss?serviceTitanTenantId=${serviceTitanTenantId}&stClientId=${stClientId}&stClientSecret=${clientSecret}&leadId=${leadId}`).POST({ payload: JSON.stringify(dismissLeadBody) })
}
export async function addFollowUpDate(serviceTitanTenantId, stClientId, clientSecret, leadId, followUpdateBody = { followUpdate: "", text: "", pinToTop: false }) {
    return await FetchClient(`/data/service-titan/leads/follow-up?serviceTitanTenantId=${serviceTitanTenantId}&stClientId=${stClientId}&stClientSecret=${clientSecret}&leadId=${leadId}`).POST({ payload: JSON.stringify(followUpdateBody) })
}

export async function getEmployeeById(serviceTitanTenantId, stClientId, clientSecret, employeeId) {
    return await FetchClient(`/data/service-titan/employee?serviceTitanTenantId=${serviceTitanTenantId}&stClientId=${stClientId}&stClientSecret=${clientSecret}&employeeId=${employeeId}`).GET({})
}
export async function updateMiddlewareFollowUp(updateMiddlewareFollowUpRequest = {}) {
    return await FetchClient(`/data/service-titan/middleware-follow-up`).PUT({ payload: JSON.stringify(updateMiddlewareFollowUpRequest) })
}

export async function getServiceTitanCredentialsValidation(serviceTitanTenantId = "", stClientId = "", clientSecret = "") {
    return await FetchClient(`/data/service-titan/validate-credentials?serviceTitanTenantId=${serviceTitanTenantId}&stClientId=${stClientId}&stClientSecret=${clientSecret}`).GET({})
}

export async function getServiceTitanJobs(franchiseId, page = 1, pageSize = 50, createdStart = "", createdEnd = "", jobStatus = "", customerId = "", locationId = "") {
    return await FetchClient(`/data/service-titan/jobs?franchise_id=${franchiseId}&page=${page}&pageSize=${pageSize}&createdStart=${createdStart}&createdEnd=${createdEnd}&jobStatus=${jobStatus}&customerId=${customerId}&locationId=${locationId}`).GET({});
}

export async function createServiceTitanJobs(serviceTitanTenantId, stClientId, clientSecret, jobCreateRequest = {}, uniqueId = "", isInitialRequest) {
    return await FetchClient(`/data/service-titan/jobs?serviceTitanTenantId=${serviceTitanTenantId}&stClientId=${stClientId}&stClientSecret=${clientSecret}&uniqueId=${uniqueId}&isInitialRequest=${isInitialRequest}`).POST({ payload: JSON.stringify(jobCreateRequest) });
}

export async function getServiceTitanAppointments(franchiseId = "", jobId = "", customerId = "") {
    return await FetchClient(`/data/service-titan/appointments?franchise_id=${franchiseId}&jobId=${jobId}&customerId=${customerId}`).GET({});
}

export async function createCustomerContact(serviceTitanTenantId = "", stClientId = "", clientSecret = "", customerId = "", createCustomerContactRequest = {}) {
    return await FetchClient(`/data/service-titan/customer/contacts?serviceTitanTenantId=${serviceTitanTenantId}&stClientId=${stClientId}&stClientSecret=${clientSecret}&customerId=${customerId}`).POST({ payload: JSON.stringify(createCustomerContactRequest) });
}

export async function createCustomerLocation(serviceTitanTenantId = "", stClientId = "", clientSecret = "", createCustomerLocationRequest = {}) {
    return await FetchClient(`/data/service-titan/location?serviceTitanTenantId=${serviceTitanTenantId}&stClientId=${stClientId}&stClientSecret=${clientSecret}`).POST({ payload: JSON.stringify(createCustomerLocationRequest) });
}

export async function updateCustomerLocation(franchiseId, locationId, updateCustomerLocationRequest = {}) {
    return await FetchClient(`/data/service-titan/location?franchise_id=${franchiseId}&id=${locationId}`).PUT({ payload: JSON.stringify(updateCustomerLocationRequest) });
}

export async function addAppointmentToJob(serviceTitanTenantId = "", stClientId = "", clientSecret = "", appointment = {}) {
    return await FetchClient(`/data/service-titan/appointments?serviceTitanTenantId=${serviceTitanTenantId}&stClientId=${stClientId}&stClientSecret=${clientSecret}`).POST({ payload: JSON.stringify(appointment) });
}

export async function getServiceTitanTechnicians(serviceTitanTenantId = "", stClientId = "", clientSecret = "", ids = []) {
    let idsString = ""
    for (const id of ids) {
        idsString += `&ids=${id}`
    }
    return await FetchClient(`/data/service-titan/technicians?serviceTitanTenantId=${serviceTitanTenantId}&stClientId=${stClientId}&stClientSecret=${clientSecret}${idsString}`).GET({});
}

export async function getServiceTitanEmployees(franchiseId = 0) {
    return await FetchClient(`/data/service-titan/employees?franchise_id=${franchiseId}`).GET({});
}

export async function rescheduleAppointment(serviceTitanTenantId = "", stClientId = "", clientSecret = "", appointmentId, appointmentRecheduleRequest = {}) {
    return await FetchClient(`/data/service-titan/appointments?serviceTitanTenantId=${serviceTitanTenantId}&stClientId=${stClientId}&stClientSecret=${clientSecret}&appointmentId=${appointmentId}`).PUT({ payload: JSON.stringify(appointmentRecheduleRequest) });
}

export async function cancelJob(franchiseId, jobId, jobCancelRequest = {}, isInitialRequest = false) {
    return await FetchClient(`/data/service-titan/job-cancel?franchise_id=${franchiseId}&jobId=${jobId}&isInitialRequest=${isInitialRequest}`).PUT({ payload: JSON.stringify(jobCancelRequest) });
}

export async function restoreJob(franchiseId, jobId) {
    return await FetchClient(`/data/service-titan/job-restore?franchise_id=${franchiseId}&jobId=${jobId}`).PUT({});
}
export async function getServiceTitanJobById(franchiseId, jobId) {
    return await FetchClient(`/data/service-titan/job?franchise_id=${franchiseId}&jobId=${jobId}`).GET({});
}

export async function getServiceTitanAppointmentAssignments(serviceTitanTenantId = "", stClientId = "", clientSecret = "", jobId = "", appointmentIds = []) {
    const appointmentIdsString = appointmentIds.join(",")
    return await FetchClient(`/data/service-titan/appointment-assignments?serviceTitanTenantId=${serviceTitanTenantId}&stClientId=${stClientId}&stClientSecret=${clientSecret}&jobId=${jobId}&appointmentIds=${appointmentIdsString}`).GET({});
}

export async function createServiceTitanAppointmentAssignments(serviceTitanTenantId = "", stClientId = "", clientSecret = "", assignTechniciansRequest = {}) {
    return await FetchClient(`/data/service-titan/appointment-assignments?serviceTitanTenantId=${serviceTitanTenantId}&stClientId=${stClientId}&stClientSecret=${clientSecret}`).POST({ payload: JSON.stringify(assignTechniciansRequest) });
}

export async function serviceTitanAppointmentUnassignment(serviceTitanTenantId = "", stClientId = "", clientSecret = "", unassignTechniciansRequest = {}) {
    return await FetchClient(`/data/service-titan/appointment-un-assignments?serviceTitanTenantId=${serviceTitanTenantId}&stClientId=${stClientId}&stClientSecret=${clientSecret}`).POST({ payload: JSON.stringify(unassignTechniciansRequest) });
}

export async function getServiceTitanTechnicianAvailabiliity(serviceTitanTenantId = "", stClientId = "", clientSecret = "", startsOnOrAfter = "", endsOnOrBefore = "") {
    return await FetchClient(`/data/service-titan/technician-availability?serviceTitanTenantId=${serviceTitanTenantId}&stClientId=${stClientId}&stClientSecret=${clientSecret}&startsOnOrAfter=${startsOnOrAfter}&endsOnOrBefore=${endsOnOrBefore}`).GET({});
}

export async function updateServiceTitanJob(serviceTitanTenantId = "", stClientId = "", clientSecret = "", jobId, updateJobRequest = {}) {
    return await FetchClient(`/data/service-titan/jobs?serviceTitanTenantId=${serviceTitanTenantId}&stClientId=${stClientId}&stClientSecret=${clientSecret}&jobId=${jobId}`).PUT({ payload: JSON.stringify(updateJobRequest) });
}

export async function getServiceTItanDraftJobs(brandId = 0, franchiseId = 0, updatedOnOrAfter = "", updatedOnOrBefore = "", page = 1, pageSize = 50) {
    return await FetchClient(`/data/service-titan/draft-jobs?brandId=${brandId}&franchiseId=${franchiseId}&updatedOnOrAfter=${updatedOnOrAfter}&updatedOnOrBefore=${updatedOnOrBefore}&page=${page}&pageSize=${pageSize}`).GET({});
}

export async function createServiceTitanJobDraft(jobDraft = {}) {
    return await FetchClient(`/data/service-titan/draft-jobs`).POST({ payload: JSON.stringify(jobDraft) })
}

export async function updateServiceTitanJobDraft(jobDraft = {}) {
    return await FetchClient(`/data/service-titan/draft-jobs`).PUT({ payload: JSON.stringify(jobDraft) })
}

export async function deleteServiceTitanJobDraft(jobDraftId = 0) {
    return await FetchClient(`/data/service-titan/draft-job?jobDraftId=${jobDraftId}`).DELETE({})
}

export async function getServiceTitanJobDraftById(jobDraftId = 0) {
    return await FetchClient(`/data/service-titan/draft-job?id=${jobDraftId}`).GET({})
}

export async function getServiceTitanAvailability(franchiseId = "", brandId="",brandShortCode = "", startDate = "", endDate = "", duration = "", technicianShiftStartDate = "") {
    return await FetchClient(`/data/servicetitan/availability?franchise_id=${franchiseId}&brandId=${brandId}&brandCode=${brandShortCode}&from=${startDate}&to=${endDate}&duration=${duration}&technician_shifts_start_time=${technicianShiftStartDate}`).GET({})
}

export async function getServiceTitaLeadById(middlewareLeadId = 0) {
    return await FetchClient(`/data/service-titan/lead?id=${middlewareLeadId}`).GET({})
}

export async function getServiceTitaLeadByServiceTitanLeadId(ServiceTitanLeadLeadId = 0) {
    return await FetchClient(`/data/service-titan/lead/byServiceTitanLeadId?id=${ServiceTitanLeadLeadId}`).GET({})
}

export async function updateServiceTitanMiddlewareLead(updateMiddlewareLeadRequest = {}) {
    return await FetchClient(`/data/service-titan/lead`).PUT({ payload: JSON.stringify(updateMiddlewareLeadRequest) })
}

export async function getServiceTitanLeadFollowUpHistory(middlewareLeadId = 0) {
    return await FetchClient(`/data/service-titan/leads/middleware-follow-up/history?id=${middlewareLeadId}`).GET({})
}

export async function getServiceTitanJobSpecificNotes(franchiseId, jobId, page = 1, pageSize = 50) {
    return await FetchClient(`/data/service-titan/job-specific/notes?franchise_id=${franchiseId}&job_id=${jobId}&page=${page}&page_size=${pageSize}`).GET({})
}

export async function addServiceTitanJobSpecificNote(franchiseId, jobId, addNoteRequest) {
    return await FetchClient(`/data/service-titan/job-specific/notes?franchise_id=${franchiseId}&job_id=${jobId}`).POST({ payload: JSON.stringify(addNoteRequest) })
}

export async function getServiceTitanCustomerNotes(franchiseId, customerId, page = 1, pageSize = 50) {
    return await FetchClient(`/data/service-titan/customer/notes?franchise_id=${franchiseId}&customer_id=${customerId}&page=${page}&page_size=${pageSize}`).GET({})
}

export async function addServiceTitanCustomerNote(franchiseId, customerId, addNoteRequest) {
    return await FetchClient(`/data/service-titan/customer/notes?franchise_id=${franchiseId}&customer_id=${customerId}`).POST({ payload: JSON.stringify(addNoteRequest) })
}

export async function getServiceTitanLocationNotes(franchiseId, locationId, page = 1, pageSize = 50) {
    return await FetchClient(`/data/service-titan/location/notes?franchise_id=${franchiseId}&location_id=${locationId}&page=${page}&page_size=${pageSize}`).GET({})
}

export async function addServiceTitanLocationNote(franchiseId, locationId, addNoteRequest) {
    return await FetchClient(`/data/service-titan/location/notes?franchise_id=${franchiseId}&location_id=${locationId}`).POST({ payload: JSON.stringify(addNoteRequest) })
}

export async function getServiceTitanJobDetails(franchiseId, jobId) {
    return await FetchClient(`/data/service-titan/job?franchise_id=${franchiseId}&jobId=${jobId}`).GET({})
}

export async function getInboundCallDetailsByServiceTitanJobId(serviceTitalJobId) {
    return await FetchClient(`/data/jobs/map?service_tital_job_id=${serviceTitalJobId}`).GET({})
}

export async function getInboundCallDetailsByServiceTitanLeadId(leadId) {
    return await FetchClient(`/data/jobs/map?lead_id=${leadId}`).GET({})
}

export async function getOutboundCallDetailsByServiceTitanJobId(serviceTitalJobId) {
    return await FetchClient(`/data/outbound-call/by-id?service_tital_job_id=${serviceTitalJobId}`).GET({})
}
