import { Box, CircularProgress, Grid, IconButton, Paper, Tooltip } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { AgentMonitor } from "./agentMonitor";
import { AgentTile } from "./agentTile";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CustomTextField from "../../../../components/CustomTextField";
import ScreenMonitoringDefaultImg from "../../../../assets/img/screen_monitoring_images/default.png";
import AlreadyMonitoringImg from "../../../../assets/img/screen_monitoring_images/alreadyMonitoring.png"
import SomethingWentWrongImg from "../../../../assets/img/screen_monitoring_images/somethingWentWrong.png"
import MonitorMessageCard from "./monitorMessageCard";
import { filterData, getAllAgents, handleCloseWhenTabChange, sortAgents } from "../utils";
import ScreenMonitoringTimeOutMessage from "./screenMonitoringTimeOutMessage";
import { fetchConnectedAgents } from "../../../../services/screenMonitoring";
import { CommonContext } from "../../../../contexts/CommonContextProvider";

const AGENT_REFRESH_TIMEOUT = 1000 * 60

export function AgentMonitorMainPage({ handleCloseAgentMonitoring, webRtcConnection, setWebRtcConnection, searchValue, setSearchValue, agents, setAgents, selectedAgent, setSelectedAgent, isAgentMonitorError,
    setIsAgentMonitorError, lastInsertedId, setlastInsertedId, isDisconnected, setIsDisconnected, setDialogOpen }) {

    const [agentsLoading, setAgentsLoading] = useState(false)
    const [serverConfig, setServerConfig] = useState({ iceServers: null, iceCandidatePoolSize: 0 })
    const [isMonitoringTimeOutDialogOpen, setIsMonitoringTimeOutDialogOpen] = useState(false)
    const [reconnectCount, setReconnectCount] = useState(0)
    const { adminId } = useContext(CommonContext);
    const [timerCount, setTimerCount] = useState(0)
    const [isOffline, setIsOffline] = useState(false)
    const [isErrorOccured, setisErrorOccured] = useState(false)

    useEffect(() => {
        getAllAgents(setAgentsLoading, setAgents, setServerConfig)
        window.addEventListener('offline', () => {
            if (!isOffline) {
                setIsOffline(true)
                if (webRtcConnection) {
                    webRtcConnection?.close()
                }
            }
        },)
        window.addEventListener('online', () => {
            setIsOffline(false)
        })
        return () => {
            if (localStorage.getItem("agent")) {
                handleCloseWhenTabChange(webRtcConnection, adminId)
            }
            window.removeEventListener('offline', () => { })
            window.removeEventListener('online', () => { })
        }
    }, [])

    useEffect(() => {
        let timeoutId;
        if (agents.length > 0) {
            timeoutId = setTimeout(async () => {
                setTimerCount(timerCount + 1)
                let onlineAgents = await fetchConnectedAgents()
                agents?.forEach((user) => {
                    { onlineAgents?.some((agent) => agent?.SessionId.includes("Desktop") && agent?.UserId == user?.user_id) ? user.isOnline = true : user.isOnline = false }
                })
                onlineAgents?.forEach((agent) => {
                    agents?.forEach((user) => {
                        if (agent?.UserId == user?.user_id && agent?.SessionId && agent?.SessionId.includes("Desktop")) {
                            user.session_id = agent?.SessionId
                        }
                    })
                })
                agents?.forEach((user) => {
                    user.isAdminOnline = true
                })
                setAgents(sortAgents([...agents]))
                setIsDisconnected(false)
            }, AGENT_REFRESH_TIMEOUT)
        }
        return () => {
            if (timeoutId) clearTimeout(timeoutId)
        }
    }, [agents, timerCount])

    const handleSetSearchValue = async (e) => {
        let keyWord = e.target.value.replace(/^\s+/g, '')
        if (keyWord !== '') {
            setSearchValue(keyWord)
        } else {
            setSearchValue('')
        }
    };

    const handleCloseTimeOutDialog = () => {
        setIsMonitoringTimeOutDialogOpen(false)
        setIsDisconnected(false)
        setSelectedAgent(null)
    }

    const handleReconnect = () => {
        setIsDisconnected(false)
        setIsMonitoringTimeOutDialogOpen(false)
        setReconnectCount(reconnectCount + 1)
    }

    return (
        <Paper sx={{ position: "absolute", left: 10, right: 10, top: 93, bottom: 0, zIndex: 1001, height: "81vh", padding: "1em" }}>
            <div className="agent-monitoring-top">
                <Tooltip title="Back"><IconButton onClick={() => {
                    setDialogOpen(false)
                    handleCloseAgentMonitoring()
                }} size="small" aria-label="delete">
                    < ArrowBackIcon style={{ height: "30px", width: "30px" }} />
                </IconButton></Tooltip>
            </div>
            <Box style={{ height: "72vh" }}>
                <Grid container spacing={1}>
                    <Grid item xs={3}>
                        {agentsLoading ?
                            <div className="agents-loading">
                                <CircularProgress color="inherit" />
                            </div>
                            :
                            <Box className={"agents-section bg-shadow"} >
                                <Box sx={{ width: "95%" }}>
                                    <CustomTextField
                                        onChange={handleSetSearchValue}
                                        id="outlined-basic"
                                        label={"Search"}
                                        size={"small"}
                                        style={{ width: "100%", marginTop: "3%" }}
                                        variant="outlined"
                                        value={searchValue}
                                    />
                                </Box>
                                <Paper style={{ height: "65vh", marginTop: "5%", overflow: "auto" }}>
                                    <Box className="agents">
                                        {
                                            filterData(agents, searchValue)?.length !== 0 ?
                                                filterData(agents, searchValue)?.map((agent, index) => {
                                                    return (
                                                        <AgentTile index={index} agent={agent} setIsAgentMonitorError={setIsAgentMonitorError} webRtcConnection={webRtcConnection} isAgentMonitorError={isAgentMonitorError}
                                                            setSelectedAgent={setSelectedAgent} lastInsertedId={lastInsertedId} selectedAgent={selectedAgent} isDisconnected={isDisconnected} setIsDisconnected={setIsDisconnected} setisErrorOccured={setisErrorOccured} isOffline={isOffline} >
                                                        </AgentTile>
                                                    )
                                                })
                                                : <Box sx={{ paddingLeft: "40%", marginTop: "45%" }}>
                                                    <p>No Agents</p>
                                                </Box>
                                        }
                                    </Box>
                                </Paper>
                            </Box>
                        }
                    </Grid>
                    <Grid item xs={9}>
                        <Paper style={{ display: "flex", flexDirection: "row", gap: "0.8em", alignItems: "center", height: "4vh", marginBottom: "1%", borderRadius: "7px" }} sx={{backgroundColor: 'background.main'}}>
                            <h3 style={{ marginLeft: "3%", fontWeight: "500" }}>Agent Screen Monitoring </h3>
                            <p style={{ fontSize: "17px", fontWeight: "500", marginBottom: "12px" }}>{selectedAgent && `| ${selectedAgent?.first_name} ${selectedAgent?.last_name}`}</p>
                        </Paper>
                        <Paper className={"caller-details bg-shadow"} style={{ height: "62vh" }}>
                            {isDisconnected || isOffline ?
                                <MonitorMessageCard type={"error"} image={SomethingWentWrongImg} />
                                :
                                isAgentMonitorError ?
                                    <MonitorMessageCard type={"monitoring"} image={AlreadyMonitoringImg} agent={selectedAgent} />
                                    :
                                    !selectedAgent || isErrorOccured ?
                                        <MonitorMessageCard type={"default"} image={ScreenMonitoringDefaultImg} />
                                        :
                                        <AgentMonitor selectedAgent={selectedAgent} setSelectedAgent={setSelectedAgent} serverConfig={serverConfig} webRtcConnection={webRtcConnection} setWebRtcConnection={setWebRtcConnection} setIsAgentMonitorError={setIsAgentMonitorError} lastInsertedId={lastInsertedId} CloseAgentMonitoring={handleCloseAgentMonitoring}
                                            setlastInsertedId={setlastInsertedId} isMonitoringTimeOutDialogOpen={isMonitoringTimeOutDialogOpen} setIsMonitoringTimeOutDialogOpen={setIsMonitoringTimeOutDialogOpen} reconnectCount={reconnectCount} isDisconnected={isDisconnected} setIsDisconnected={setIsDisconnected} agents={agents} setAgents={setAgents} setisErrorOccured={setisErrorOccured}  ></AgentMonitor>
                            }
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
            <ScreenMonitoringTimeOutMessage handleCloseTimeOutDialog={handleCloseTimeOutDialog} handleReconnect={handleReconnect} open={isMonitoringTimeOutDialogOpen} />
        </Paper>
    );
}
