import { Autocomplete, Box, Button, Typography } from "@mui/material";
import CustomTextField from "../../../../components/CustomTextField";
import RenderInput from "../../../../components/servicetitan/RenderInput";
import CustomerTypesRadioButton from "../serviceTitanJobs/components/CustomerTypesRadioButton";
import LoadingButton from "../../../../components/LoadingButton";
import { NOTIFICATION_TYPES } from "../../../../constants/common";
import { CUSTOMER_TYPES } from "./ServiceTitanMainSection";
import { updateFranchiseCustomer } from "../../../../services/serviceTitan";
import { useState } from "react";
import HistorySection from "./servicetitan/HistorySection";
import EditWarningMessage from "../components/EditWarningMessage";
import CustomerSearchSection from "./CustomerSearchSection";

export function CustomerDetailsSection(props) {
    const { franchise, isNewCustomer, newCustomer, setNewCustomer, customers, isCustomersLoading, selectedCustomer, setSelectedCustomer, location, setLocation, isBillingSameAsLocation, setIsBillingSameAsLocation, isEditCustomer, editedCustomer, setEditedCustomer, onEditDone, onEditClose, showNotification, customerSearchCount,
        isDisabled, customerPhoneNumber, setCustomerPhoneNumber, customerZipCode, setCustomerZipCode, customerName, setCustomerName, SearchCustomer, setPreviousAppointmentForRescheduleJob, setjobForTheAppointment, setisOpenAppointmentRescheduleModal, jobNote, setJobNote } = props
    if (isNewCustomer) {
        return <NewCustomerDetailsSection franchise={franchise} newCustomer={newCustomer} setNewCustomer={setNewCustomer} isBillingSameAsLocation={isBillingSameAsLocation} setIsBillingSameAsLocation={setIsBillingSameAsLocation} location={location} setLocation={setLocation} />
    } else {
        if (isEditCustomer) {
            return <EditCustomerDetailsSection franchise={franchise} editedCustomer={editedCustomer} setEditedCustomer={setEditedCustomer} onEditDone={onEditDone} onEditClose={onEditClose} showNotification={showNotification} />
        } else {
            return <ExistingCustomerDetailsSection franchise={franchise} customers={customers} isCustomersLoading={isCustomersLoading} selectedCustomer={selectedCustomer} setSelectedCustomer={setSelectedCustomer} showNotification={showNotification} customerSearchCount={customerSearchCount}
                isDisabled={isDisabled} customerPhoneNumber={customerPhoneNumber} setCustomerPhoneNumber={setCustomerPhoneNumber} customerZipCode={customerZipCode} setCustomerZipCode={setCustomerZipCode} customerName={customerName} setCustomerName={setCustomerName} SearchCustomer={SearchCustomer}
                setPreviousAppointmentForRescheduleJob={setPreviousAppointmentForRescheduleJob} setjobForTheAppointment={setjobForTheAppointment} setisOpenAppointmentRescheduleModal={setisOpenAppointmentRescheduleModal} jobNote={jobNote} setJobNote={setJobNote} />
        }
    }
}

function NewCustomerDetailsSection(props) {
    const { newCustomer, setNewCustomer, location, setLocation } = props

    function onTypeChanged(_, value) {
        setNewCustomer({ ...newCustomer, type: value })
    }

    function onNameChanged(e) {
        setNewCustomer({ ...newCustomer, name: e.target.value });
        setLocation({ ...location, name: e.target.value })
    }

    return <>
        <Box sx={{ display: 'flex', flexDirection: "row", width: "100%", marginRight: "1em", gap: "1em" }}>
            <CustomTextField data-test="customer_name" style={{ width: "45%" }} required={true} value={newCustomer?.name ?? ""} size="small" label="Customer Name" onChange={onNameChanged} />
            <CustomerTypesRadioButton data-test="customer_types_radio" options={CUSTOMER_TYPES} value={newCustomer?.type ?? ""} onChange={onTypeChanged} />
        </Box>
    </>
}

function ExistingCustomerDetailsSection(props) {
    const { franchise, customers, isCustomersLoading, selectedCustomer, setSelectedCustomer, showNotification, customerSearchCount,
        isDisabled, customerPhoneNumber, setCustomerPhoneNumber, customerZipCode, setCustomerZipCode, customerName, setCustomerName, SearchCustomer, setPreviousAppointmentForRescheduleJob, setjobForTheAppointment, setisOpenAppointmentRescheduleModal, jobNote, setJobNote } = props
    return <>
        <CustomerSearchSection
            isDisabled={isDisabled}
            customerPhoneNumber={customerPhoneNumber}
            onCustomerPhoneNumberChanged={(e) => setCustomerPhoneNumber(e?.target?.value)}
            customerZipCode={customerZipCode} onCustomerZipCodeChanged={(e) => setCustomerZipCode(e?.target?.value)}
            customerName={customerName} onCustomerNameChanged={(e) => setCustomerName(e?.target?.value)}
            isSearchResultCutomersLoading={isCustomersLoading} onSearch={() => SearchCustomer()}
        />
        <Box sx={{ display: 'flex', flexDirection: "row", width: "100%", marginRight: "1em", gap: "1em" }}>
            <Autocomplete
                sx={{ width: "45%" }}
                size="small"
                noOptionsText={franchise ? "No customer found" : "Please select a franchise"}
                loading={isCustomersLoading}
                value={selectedCustomer ?? null}
                data-test="customer_search_selector"
                disablePortal
                disableClearable
                options={customers}
                getOptionLabel={(customer) => customer.name}
                renderOption={(props, customer) => <RenderInput {...props} key={customer?.id} content={customer?.name} />}
                onChange={(_, customer) => {
                    setSelectedCustomer(customer);
                }}
                renderInput={(params) => <CustomTextField {...params} inputProps={{
                    ...params.inputProps,
                    'data-test': 'job_customer_search_select'
                }} required={true} label="Select Customer" />}
            />
            <CustomerTypesRadioButton isDisabled={true} options={CUSTOMER_TYPES} value={selectedCustomer?.type ?? ""} />
        </Box>
        {!isCustomersLoading ? customerSearchCount.current > 0 ?
            customers?.length > 0 ?
                <Typography sx={{ color: 'text.green' }} variant="subtitle1">{customers?.length === 1 ? "Customer Found" : "Customers Found"}</Typography>
                :
                <Typography style={{ color: "red" }} variant="subtitle1">{"Customer Not Found"}</Typography>
            : <></>
            :
            <></>
        }
        <HistorySection franchise={franchise} customerId={selectedCustomer?.id} showNotification={showNotification} setPreviousAppointmentForRescheduleJob={setPreviousAppointmentForRescheduleJob} setjobForTheAppointment={setjobForTheAppointment} setisOpenAppointmentRescheduleModal={setisOpenAppointmentRescheduleModal} jobNote={jobNote} setJobNote={setJobNote} />
    </>
}

function EditCustomerDetailsSection(props) {
    const { franchise, editedCustomer, setEditedCustomer, onEditDone, onEditClose, showNotification } = props
    const [isEditCustomerLoading, setIsEditCustomerLoading] = useState(false)
    function onTypeChanged(_, value) {
        setEditedCustomer({ ...editedCustomer, type: value })
    }

    function onNameChanged(e) {
        setEditedCustomer({ ...editedCustomer, name: e.target.value });
    }


    function onErrorEditCustomer() {
        showNotification({ message: "Important: Could not edit the customer. Please try again", type: NOTIFICATION_TYPES.ERROR })
    }

    function onCompleteEditCustomer(editedCustomer) {
        showNotification({ message: "Successfully saved", type: NOTIFICATION_TYPES.SUCCESS })
        onEditDone(editedCustomer)
    }

    return <>
        <Box sx={{ display: 'flex', flexDirection: "row", width: "100%", marginRight: "1em", gap: "1em" }}>
            <CustomTextField inputProps={{ "data-test": "job_edit_customer_name" }} style={{ width: "45%" }} required={true} value={editedCustomer?.name ?? ""} size="small" label="Customer Name" onChange={onNameChanged} />
            <CustomerTypesRadioButton options={CUSTOMER_TYPES} value={editedCustomer?.type ?? ""} onChange={onTypeChanged} />
        </Box>
        <Box display="flex" flexDirection="column" alignItems="right" >
            <Box sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "right", gap: "1em" }} >
                <Button data-test="job_edit_customer_cancel_button" size={"small"} variant="contained" onClick={onEditClose} className={"btn-secondary"}>Cancel</Button>
                <LoadingButton
                    onClick={() => { handleEditCustomer(franchise, editedCustomer, setIsEditCustomerLoading, onCompleteEditCustomer, onErrorEditCustomer) }}
                    size={"small"}
                    data-test="job_edit_customer_save_button"
                    variant="contained"
                    disabled={isEditCustomerLoading}
                    loading={isEditCustomerLoading}
                    className={isEditCustomerLoading ? "btn-disable" : "btn-primary"} >
                    {"Update Customer"}
                </LoadingButton>
            </Box>
            <EditWarningMessage />
        </Box>
    </>
}

async function handleEditCustomer(franchise, editedCustomer, setIsEditedCustomerLoading, onDone, onError) {
    try {
        setIsEditedCustomerLoading(true)
        const res = await updateFranchiseCustomer(franchise.id, editedCustomer.id, editedCustomer)
        onDone(res)
    } catch {
        onError()
    } finally {
        setIsEditedCustomerLoading(false)
    }
}