import moment from "moment";
import { Typography, IconButton, Tooltip, Box, Paper, Grid } from "@mui/material";
import UpdateIcon from '@mui/icons-material/Update';
import SmallCard, { SmallCardForTechnicians } from "../../../../../components/servicetitan/SmallCard";
import { getDuration } from "../../../../../utils/miscellaneous";
import { useCallback, useEffect, useState } from "react";
import { getServiceTitanAppointmentAssignments } from "../../../../../services/serviceTitan";

export default function AppointmentCard({ index, job, total, appointment, franchise, isResheduleFromViewJob = false, setAppointmentToReschedule, setPreviousAppointmentForRescheduleJob = () => { }, setjobForTheAppointment = () => { }, setisOpenAppointmentRescheduleModal = () => { }, shouldAllowCancelAndReschedule }) {

    const [assingedTechnicians, setAssingedTechnicians] = useState([])

    const fetchServiceTitanAppointmentAssignments = useCallback(
        async () => {
            try {
                const response = await getServiceTitanAppointmentAssignments(franchise?.service_titan_tenant_id, franchise?.service_titan_client_id, franchise?.service_titan_client_secret, "", [appointment?.id])
                if (Array.isArray(response?.data)) {
                    setAssingedTechnicians(response?.data)
                }
            } catch (error) {
                setAssingedTechnicians([])
            }
        },
        [franchise?.service_titan_tenant_id, franchise?.service_titan_client_id, franchise?.service_titan_client_secret, appointment?.id],
    )

    useEffect(() => {
        fetchServiceTitanAppointmentAssignments()
        return () => {
            setAssingedTechnicians([])
        }
    }, [fetchServiceTitanAppointmentAssignments])

    function handleSetAppointmentToReschedule(appointment) {
        if (isResheduleFromViewJob) {
            setAppointmentToReschedule(appointment);
        } else {
            setPreviousAppointmentForRescheduleJob(appointment);
            setjobForTheAppointment(job);
            setisOpenAppointmentRescheduleModal(true);
        }
    }

    return <Paper sx={{ width: "100%", borderRadius: "0.5em", border: "1px solid gray", borderLeft: "4px solid #00a79d", marginTop: "0.5em" }}>
        <Box sx={{ display: "flex", flexDirection: "row", padding: "0.4em", alignItems: "center", borderRadius: "0.5em", gap: "1em" }}>
            <Typography style={{ fontWeight: "bold" }} >{(`#${appointment?.appointmentNumber}` ?? "")}</Typography> |
            <Typography style={{ fontWeight: "500", color: "gray" }} >{(appointment?.status ?? "")}</Typography> |
            <Typography style={{ fontWeight: "500", color: "gray" }} >{(`${(index + 1)} of ${total}`)}</Typography>
            {(appointment?.status !== 'Canceled' && shouldAllowCancelAndReschedule) ? <Tooltip title="Reschedule Appointment"><IconButton onClick={() => {
                handleSetAppointmentToReschedule(appointment)
            }}
                data-test={"reschedule_appointment_button"}
                size="small"
                style={{ marginLeft: "auto" }}>
                <UpdateIcon />
            </IconButton>
            </Tooltip> : <></>}
        </Box>
        {franchise?.time_zone ? <></> : <p style={{ color: "red", marginLeft: "1em" }}>*Franchise time zone not provided. Time is based on UTC time</p>}
        <Grid container justifyContent={"space-evenly"} spacing={2}>
            <Grid item xs={2} >
                <SmallCard heading={"Start Date & Time"} value={appointment?.start ? (franchise?.time_zone ? moment(new Date(appointment?.start))?.tz(franchise?.time_zone)?.format('MM/DD/YYYY - h:mm A') : moment(new Date(appointment?.start))?.utc()?.format('MM/DD/YYYY - h:mm A')) : "-"} />
            </Grid>
            <Grid item xs={2}>
                <SmallCard heading={"End Date & Time"} value={appointment?.end ? (franchise?.time_zone ? moment(new Date(appointment?.end))?.tz(franchise?.time_zone)?.format('MM/DD/YYYY - h:mm A') : moment(new Date(appointment?.end))?.utc()?.format('MM/DD/YYYY - h:mm A')) : "-"} />
            </Grid>
            <Grid item xs={2}>
                <SmallCard heading={"Arrival Window"} value={appointment?.arrivalWindowStart && appointment?.arrivalWindowEnd ? `${franchise?.time_zone ? moment(new Date(appointment?.arrivalWindowStart))?.tz(franchise?.time_zone)?.format('MM/DD/YYYY - h:mm A') : moment(new Date(appointment?.arrivalWindowStart))?.utc()?.format('MM/DD/YYYY - h:mm A')} ${franchise?.time_zone ? moment(new Date(appointment?.arrivalWindowEnd))?.tz(franchise?.time_zone)?.format('MM/DD/YYYY - h:mm A') : moment(new Date(appointment?.arrivalWindowEnd))?.utc()?.format('MM/DD/YYYY - h:mm A')}` : "-"} />
            </Grid>
            <Grid item xs={1}>
                <SmallCard heading={"Duration"} value={appointment?.start && appointment?.end ? getDuration(appointment?.start, appointment?.end) : "-"} />
            </Grid>
            <Grid item xs={4}>
                < SmallCardForTechnicians heading="Technicians" technicians={assingedTechnicians} />
            </Grid>
        </Grid>
    </Paper>
}