import { IconButton, TableCell, TableRow, Typography } from '@mui/material'
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import CustomTextField from "../../../../components/CustomTextField";
import { imposibleToMatchRegex, isValidEmail } from '../../../../utils/validators';
import { Box } from '@mui/system';
import { getIsEmailAlreadyExists } from '../../franchise/model/OwnerTable/utils/common';

const INVALID_EMAIL = 'Invalid Email Address'
const MAX_OWNER_NAME_LENGTH = 50

export default function EditBrandOwnerRow(props) {
    const { key, owner, existingEmails = [], onOwnerChanged, onCancel, onSave, helperText = "" } = props
    const isInvalidEmail = !isValidEmail(owner.owner_email) || getIsEmailAlreadyExists(owner.owner_email, existingEmails)
    const emailPattern = isInvalidEmail ? imposibleToMatchRegex : undefined

    return <TableRow key={key} >
        <TableCell colSpan={1} >
            <Box sx={{ height: "5em" }} >
                <CustomTextField
                    inputProps={{
                        "data-test": "brand_owner_name"
                    }}
                    required
                    value={owner?.owner_name ?? null}
                    error={owner.owner_name ? owner.owner_name.length > MAX_OWNER_NAME_LENGTH : false}
                    helperText={owner.owner_name && owner.owner_name.length > MAX_OWNER_NAME_LENGTH ? "max length exceeded" : ""}
                    onChange={(e) => onOwnerChanged({ ...owner, owner_name: e?.target?.value })}
                    label={"Name"}
                    size={"small"}
                    margin="normal"
                    variant="outlined"
                />
            </Box>
        </TableCell>
        <TableCell colSpan={1} >
            <Box sx={{ height: "5em" }} >
                <CustomTextField
                    required
                    error={owner.owner_email ? isInvalidEmail : false}
                    inputProps={{ pattern: emailPattern, "data-test": "brand_owner_email" }}
                    helperText={owner.owner_email && !isValidEmail(owner.owner_email) ? INVALID_EMAIL : getIsEmailAlreadyExists(owner.owner_email, existingEmails) ? "Email already exists" : ""}
                    value={owner.owner_email ?? null}
                    onChange={(e) => onOwnerChanged({ ...owner, owner_email: e?.target?.value?.trim?.() })}
                    size={"small"}
                    label="Email"
                    margin="normal"
                    variant="outlined"
                />
            </Box>
        </TableCell>
        <TableCell colSpan={1} align='left' sx={{paddingLeft:'0px'}}>
            <Box sx={{ display: "flex", alignItems: "center", height: "4em" }} >
                <Typography style={{ color: "#B00020", marginRight: "6em"}} >{helperText}</Typography>
                <IconButton data-test="franchise_owner_close" color="primary" title='Cancel' onClick={onCancel}>
                    <CloseIcon style={{ height: "20px", width: "20px" }} />
                </IconButton>
                <IconButton data-test="franchise_owner_save" type={"button"} color="primary" title='Save' onClick={onSave}>
                    <SaveIcon style={{ height: "20px", width: "20px" }} />
                </IconButton>
            </Box>
        </TableCell>
    </TableRow>
}
