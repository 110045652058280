import { AVAILABLE_CRMS } from "./common";

export const createFranchiseSectionsMap = Object.freeze({
    main: {
        index: 0,
        name: "Main",
        test_automation_label: "Main",
        isApplicableForTollFree: true
    },
    brands: {
        index: 1,
        name: "Brands",
        test_automation_label: "Brands",
        isApplicableForTollFree: true
    },
    serviceTitan: {
        index: 2,
        name: AVAILABLE_CRMS.servicetitan.label,
        test_automation_label: "ServiceTitan",
        isApplicableForTollFree: false
    },
    angiesList: {
        index: 3,
        name: "Angie's List",
        test_automation_label: "Angies List",
        isApplicableForTollFree: false
    },
    lumin: {
        index: 4,
        name: "Lumin",
        test_automation_label: "Lumin",
        isApplicableForTollFree: false
    },
    owners: {
        index: 5,
        name: "Owners",
        test_automation_label: "Owners",
        isApplicableForTollFree: true
    },
})

export const INITIAL_CHANNEL = { channel_id: 0, channel_name: "" }