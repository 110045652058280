import { NOTIFICATION_TYPES } from "../constants/common"
import { getFaqs } from "../services/faqService"

export const handleFaqs = async (brandId = 0, franchiseId = 0, fetchType, debouncedSearchTerm, selectedSearchKeywords = [], setLoading, setFaqs, showNotification, setFaqCount, page, size) => {
    try {
        setLoading(true)
        let result = await getFaqs(brandId, franchiseId, fetchType, debouncedSearchTerm, selectedSearchKeywords, page, size)
        setFaqs(result?.faqs ? result.faqs : []);
        setFaqCount({ all: result?.count?.all, active: result?.count?.active, inactive: result?.count?.inactive })
    } catch (error) {
        setFaqs([])
        setFaqCount({ all: 0, active: 0, inactive: 0 })
        showNotification({ message: "Unable to find Faqs at this moment", type: NOTIFICATION_TYPES.ERROR })
    } finally {
        setLoading(false)
    }
}