import IBSLogo from "../../../../assets/img/IBS_lOGO.png"
import DeskTopAppIcon from "../../../../assets/img/desktopAppIcon.png";
import { getFromSessionStorage, setSessionStorage } from '../../../../utils/browserStorage';
import { onSyncdesktopAppButtonHandler } from '../../../../pages/other/utils/desktopapp';
import { SESSION_STORAGE_KEYS } from '../../../../constants/browserStorage';
import { useEffect } from 'react';

export default function AgentAssistHead({ setToggle }) {
    useEffect(() => {
        if (getFromSessionStorage(SESSION_STORAGE_KEYS.Brand_Image) === null) {
            setSessionStorage(SESSION_STORAGE_KEYS.Brand_Image, IBSLogo)
        }
    }, [])

    const imageOnErrorHandler = ({ currentTarget }) => {
        currentTarget.onerror = null;
        currentTarget.src = IBSLogo;
    }

    return (
        <div className="aa-head">
            <div>
                <img onError={imageOnErrorHandler} src={getFromSessionStorage(SESSION_STORAGE_KEYS.Brand_Image)} alt="B" height={35} />
            </div>
            <div>
                <small>Franchise</small>
                <p title={getFromSessionStorage(SESSION_STORAGE_KEYS.Franchise_Name)}>
                    {
                        getFromSessionStorage(SESSION_STORAGE_KEYS.Franchise_Name)?.length > 40 ?
                            getFromSessionStorage(SESSION_STORAGE_KEYS.Franchise_Name)?.substring(0, 40) + "..." :
                            getFromSessionStorage(SESSION_STORAGE_KEYS.Franchise_Name)
                    }
                </p>
            </div>
            <img className="btn-primary aa-connect-img" title='Reconnect to IBS call Agent Assist' alt="reconnectimg" src={DeskTopAppIcon} width={35} height={35}
                onClick={onSyncdesktopAppButtonHandler}
            />
        </div>
    )
}
