import { useContext, useEffect, useState } from "react";
import moment from "moment";
import { Box, TableContainer, TableBody, Table, TableCell, TableRow, TableHead, Pagination, Tooltip, Typography, Container } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import CallIcon from '@mui/icons-material/Call';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ReportIcon from "@mui/icons-material/Report";
import ErrorView from "../../../../components/ErrorView";
import CreateLeadModal from "./components/CreateLeadModal";
import ExpandedLeadModal from "./components/ExpandedLeadModal";
import AdvanceFilterArea from "./components/AdvanceFilterArea";
import { ERROR_COMPONENTS } from "../../../../constants/common";
import iBSBrandLogo from "../../../../assets/img/brand.png"
import { getPhoneNumberWithInputMask } from "../../../../utils/inputMasks"
import { getMatchingBrandByBrandId, getMatchingFranchiseById, getCredentialsMissingWarning } from "../../../../utils/miscellaneous"
import { ALL_AGENT, ALL_BRAND, ALL_USER, CONTACTS_TYPES, FILTERATION_PRIORITIES, INITIAL_LEAD_STATISTICS, SERVICETITAN_PAGE_SIZE, SORT_BY, SORT_ORDER, STATUSES } from "../../../../constants/serviceTitan"
import { handleGetAllBrands } from "../../../../state-services-dublicate/brandServices";
import { handleGetAllUsers } from "../../../../state-services-dublicate/userServices";
import { handleGetLeadById, handleGetLeadByServiecTitanLeadId, handleGetLeads, handleGetServiceTitanJobDraftById, handleGetServiceTitanUniqueFranchises } from "../../../../state-services-dublicate/serviceTitan";
import { compareTwoRFCDateStringsAssendingOrder } from "../../../../utils/compareFunctions";
import { handleGetAllFranchises } from "../../../../state-services-dublicate/franchiseServices";
import { getMatchingPriorityImage } from "../../../../utils/imageGetters"
import { EncryptionWarningBadge } from "../../../../components/EncryptionWarningBadge";
import { CommonContext } from "../../../../contexts/CommonContextProvider";
import { useHistory, useLocation } from "react-router-dom";
import EditLeadModal from "./components/EditLead/EditLeadModal";
import ImageBox from "../../../../components/LogoBox/ImageBox";
import GlobalSearch from "../../../../components/globalSearch/GlobalSearch";
import useDebounce from "../../../../hooks/useDebounce";
import AgentFeedback from "../../../other/home/components/agentFeedback";
import { handleSetAgentFeedbackData } from "../../../other/utils/common";
import { getTenantTimeZoneTimeString } from "../../../../utils/time/time";

const LEAD_INDEX = "LEAD";

export default function ServiceTitan(props) {

    const { showNotification, socketMessage, popPhoneDialer, generalTenantConfiguration, timezones } = useContext(CommonContext);

    const urlParams = new URLSearchParams(window.location.search);
    const jobDraftId = parseInt(urlParams.get("jobDraftId"), 10)
    const selectedBrandId = parseInt(urlParams.get("brandId"), 10);
    const selectedFranchiseId = parseInt(urlParams.get("franchiseId"), 10);
    const selectedTenantId = urlParams.get("tenantId")
    const selectedPhoneNumber = urlParams.get("callerId");
    const selectedZipCode = urlParams.get("zipCode");
    const dayStartTime = moment(new Date()).startOf('day');
    const dayEndTime = moment(new Date()).endOf('day')
    const initialFilters = { brandId: "allBrands", franchiseId: "allFranchises", serviceTitanTenantId: "allFranchises", userId: ALL_USER.user_id, status: STATUSES[0], priority: FILTERATION_PRIORITIES[0], startFollowUpDate: dayStartTime.toISOString(), endFollowUpDate: dayEndTime.toISOString(), universalSearchString: "", ids: [], sortBy: SORT_BY[1], sortOrder: SORT_ORDER[0] };
    const selectedLeadId = urlParams.get("id");
    const selectedServiceTitanLeadId = urlParams.get("serviceTitanLeadId");

    const location = useLocation()
    const [selectedLeadToView, setSelectedLeadToView] = useState(undefined);
    const [selectedLeadToEdit, setSelectedLeadToEdit] = useState(undefined);
    const [allLeads, setAllLeads] = useState([])
    const [showingInViewLeads, setShowingInViewLeads] = useState([]);
    const [filterState, setFilterState] = useState(initialFilters)
    const [allFranchises, setAllFranchises] = useState([])
    const [uniqueFranchises, setUniqueFranchises] = useState([]);
    const [isEncryptionFailed, setIsEncyptionFailed] = useState(false)
    const [brands, setBrands] = useState([]);
    const [users, setUsers] = useState([]);
    const [draftJob, setDraftJob] = useState()
    const [page, setPage] = useState(1);
    const [leadStatistics, setLeadStatistics] = useState(INITIAL_LEAD_STATISTICS)

    const [isFranchisesLoading, setIsFranchisesLoading] = useState(false);
    const [isBrandsLoading, setIsBrandsLoading] = useState(false);
    const [isLeadsLoading, setIsLeadsLoading] = useState(false);
    const [isLeadsLoadingError, setIsLeadsLoadingError] = useState(false);

    const [isCreateLeadModalOpen, setIsCreateLeadModalOpen] = useState(false);
    const [isExpandedLeadModalOpen, setIsExpandedLeadModalOpen] = useState(false);
    const [isEditLeadModalOpen, setIsEditLeadModalOpen] = useState(false)
    const [toggleLeadLoading, setToggleLeadLoading] = useState(false)
    const [isOpenAgentFeedbackDialog, setIsOpenAgentFeedbackDialog] = useState(false)
    const [agentFeedbackData, setAgentFeedbackData] = useState({ sentimentId: 0, customerScore: 0.0, customerMagnitude: 0.0, agentScore: 0.0, agentMagnitude: 0.0 })
    const history = useHistory()

    const [isAddNewLeadButtonClick, setIsAddNewLeadButtonClick] = useState(false)

    const debounceHandleGetLeads = useDebounce({ ids: filterState.ids, startFollowUpDate: filterState.startFollowUpDate, endFollowUpDate: filterState.endFollowUpDate, brandId: filterState.brandId, serviceTitanTenantId: filterState.serviceTitanTenantId, priority: filterState.priority, userId: filterState.userId, status: filterState.status }, ({ ids, startFollowUpDate, endFollowUpDate, brandId, serviceTitanTenantId, priority, userId, status }) => {
        setAllLeads([])
        const trimmedSearchString = filterState.universalSearchString.trim()
        let idsInput = []
        let startFollowUpInput = startFollowUpDate;
        let endFollowUpInput = endFollowUpDate;
        let brandIdInput = brandId === "allBrands" ? "" : brandId;
        let serviceTitanTenantIdInput = serviceTitanTenantId === "allFranchises" ? "" : serviceTitanTenantId;
        let priorityInput = priority === FILTERATION_PRIORITIES[0] ? "" : priority;
        let middlewareAssignedToIdInput = userId === ALL_USER.user_id ? "" : userId
        let statusInput = status
        if (trimmedSearchString && ids?.length === 0) {
            return
        } else if (trimmedSearchString && ids?.length > 0) {
            idsInput = filterState?.ids
            startFollowUpInput = ""
            endFollowUpInput = ""
            brandIdInput = ""
            serviceTitanTenantIdInput = ""
            priorityInput = ""
            middlewareAssignedToIdInput = ""
            statusInput = ""
        }
        handleGetLeads(idsInput, brandIdInput, "", serviceTitanTenantIdInput, statusInput, page, SERVICETITAN_PAGE_SIZE, startFollowUpInput, endFollowUpInput, priorityInput, middlewareAssignedToIdInput, setAllLeads, setLeadStatistics, setIsLeadsLoading, setIsLeadsLoadingError)
    }, 1000)

    useEffect(() => {
        if (generalTenantConfiguration?.time_zone) {
            const dayStartTime = moment(new Date()).tz(generalTenantConfiguration?.time_zone).startOf('day');
            const dayEndTime = moment(new Date()).tz(generalTenantConfiguration?.time_zone).endOf('day')
            setFilterState((filterState) => { return { ...filterState, startFollowUpDate: dayStartTime.toISOString(), endFollowUpDate: dayEndTime.toISOString() } })
        }
    }, [generalTenantConfiguration?.time_zone])

    useEffect(() => {
        if (socketMessage?.title) setToggleLeadLoading((l) => !l)
    }, [socketMessage])

    useEffect(() => {
        handleGetLeadById(selectedLeadId, setSelectedLeadToView, setIsExpandedLeadModalOpen, showNotification)
    }, [selectedLeadId, location, showNotification])

    useEffect(() => {
        handleGetLeadByServiecTitanLeadId(selectedServiceTitanLeadId, setSelectedLeadToView, setIsExpandedLeadModalOpen, showNotification)
    }, [selectedServiceTitanLeadId, showNotification])

    useEffect(() => {
        if (socketMessage?.message?.includes("SentimentId")) {
            handleSetAgentFeedbackData(socketMessage, setAgentFeedbackData, setIsOpenAgentFeedbackDialog)
        }
    }, [socketMessage])

    useEffect(() => {
        if (selectedBrandId && selectedFranchiseId && selectedTenantId) {
            setIsCreateLeadModalOpen(true);
        } else if (jobDraftId) {
            handleGetServiceTitanJobDraftById(jobDraftId, (draft) => {
                setDraftJob(draft)
                setIsCreateLeadModalOpen(true)
            })
        }
        handleGetAllBrands(undefined, undefined, () => { }, () => { }, setIsBrandsLoading, (brands) => {
            setBrands([ALL_BRAND, ...brands]);
        });
        handleGetAllFranchises(setAllFranchises)
        handleGetAllUsers(setUsers, () => { }, (users) => {
            let usersWithoutAllUsers = [ALL_AGENT, ...users]
            usersWithoutAllUsers = [ALL_USER, ...usersWithoutAllUsers]
            let allUsers = usersWithoutAllUsers
            setUsers(allUsers)
        });
    }, [selectedBrandId, selectedFranchiseId, selectedTenantId, jobDraftId])

    useEffect(() => {
        if (filterState.brandId && filterState.brandId !== ALL_BRAND.brand_id) {
            return handleGetServiceTitanUniqueFranchises(filterState.brandId, setUniqueFranchises, setIsFranchisesLoading, setIsEncyptionFailed);
        }
    }, [filterState.brandId])

    useEffect(() => {
        setPage(1);
    }, [filterState.universalSearchString, filterState.brandId, filterState.serviceTitanTenantId, filterState.status, filterState.startFollowUpDate, filterState.endFollowUpDate, filterState.userId, filterState.priority])

    useEffect(() => {
        setIsLeadsLoading(true)
        debounceHandleGetLeads({ ids: filterState.ids, startFollowUpDate: filterState.startFollowUpDate, endFollowUpDate: filterState.endFollowUpDate, brandId: filterState.brandId, serviceTitanTenantId: filterState.serviceTitanTenantId, priority: filterState.priority, userId: filterState.userId, status: filterState.status })
    }, [filterState?.ids, filterState.universalSearchString, filterState.brandId, filterState.serviceTitanTenantId, filterState.status, filterState.startFollowUpDate, filterState.endFollowUpDate, filterState.userId, filterState.priority, filterState.middlewareAssignedToId, page, toggleLeadLoading, debounceHandleGetLeads])

    useEffect(() => {
        const showingLeads = filterLeadsAccordingSortBy(allLeads, filterState.sortBy, filterState.sortOrder);
        setShowingInViewLeads(showingLeads);
    }, [allLeads, filterState.sortBy, filterState.sortOrder])

    const handleAgentFeedBackDialogClose = () => {
        setIsOpenAgentFeedbackDialog(false)
    }

    const handleCreateLeadDialogOpen = (lead) => {
        setIsCreateLeadModalOpen(true)
        setIsAddNewLeadButtonClick(true)
    }

    const handleEditLeadDialogOpen = (lead) => {
        setSelectedLeadToEdit(lead)
        setIsEditLeadModalOpen(true)
    }

    const handleEditLeadDialogClose = (lead, isEditLead) => {
        setSelectedLeadToEdit(null)
        setIsEditLeadModalOpen(false)
        if (!isEditLead) {
            window.location.reload()
        }
        if (isEditLead) {
            setTimeout(() => {
                handleExpandedLeadModalOpen(lead);
            }, 800);
        }
    }

    const handleCreateLeadDialogClose = (newLeadCreated, isNewLead = true) => {
        setIsCreateLeadModalOpen(false)
        setIsAddNewLeadButtonClick(false)
        if (jobDraftId && !newLeadCreated) {
            urlParams.delete("jobDraftId")
            history.push({ pathname: history.location.pathname, search: urlParams.toString() })
        }
        if (newLeadCreated) {
            if (filterState.franchiseId === "allFranchises" || newLeadCreated.franchiseId === filterState.franchiseId) {
                if (isNewLead) {
                    setAllLeads([...allLeads, newLeadCreated])
                    const newLeadStatistics = getUpdatedleadStatistics(newLeadCreated?.leadResponse?.priority, leadStatistics)
                    setLeadStatistics(newLeadStatistics)
                } else {
                    const allLeadsWithEditedLead = allLeads.map((lead) => {
                        if (lead?.leadResponse?.id === newLeadCreated?.leadResponse?.id && lead?.middlewareServiceTitanLead?.tenantId === newLeadCreated?.middlewareServiceTitanLead?.tenantId) {
                            return newLeadCreated
                        } else {
                            return lead
                        }
                    });
                    setAllLeads(allLeadsWithEditedLead)
                }
            }
            setTimeout(() => { //TO prevent quick respose 
                handleExpandedLeadModalOpen(newLeadCreated);
            }, 800);
        }
    }

    const handleExpandedLeadModalOpen = (lead) => {
        urlParams.append("id", lead?.middlewareServiceTitanLead?.id)
        history.push({ pathname: history.location.pathname, search: urlParams.toString() })
        setSelectedLeadToView(lead);
        setIsExpandedLeadModalOpen(true)
    }

    const handleExpandedLeadDialogClose = (updatedLead) => {
        setSelectedLeadToView(undefined);
        setIsExpandedLeadModalOpen(false)
        if (updatedLead) {
            const index = allLeads.findIndex((lead) => lead.leadResponse.id === updatedLead.leadResponse.id && lead?.middlewareServiceTitanLead?.tenantId === updatedLead.middlewareServiceTitanLead?.tenantId)
            allLeads[index] = updatedLead;
            allLeads.forEach((lead) => {
                if (lead?.middlewareServiceTitanLead?.customerId === updatedLead?.middlewareServiceTitanLead?.customerId) {
                    lead.middlewareServiceTitanLead.customerName = updatedLead.middlewareServiceTitanLead.customerName
                    lead.middlewareServiceTitanLead.customerContacts = updatedLead.middlewareServiceTitanLead.customerContacts
                }
            })
            setAllLeads([...allLeads])
        }
        if (selectedLeadId) {
            urlParams.delete("id")
            history.push({ pathname: history.location.pathname, search: urlParams.toString() })
        }
        if (jobDraftId) {
            urlParams.delete("jobDraftId")
            history.push({ pathname: history.location.pathname, search: urlParams.toString() })
        }
    }

    function getUpdatedleadStatistics(priority = "",) {
        if (priority) {
            leadStatistics.totalLeadCount++
        }
        switch (priority) {
            case FILTERATION_PRIORITIES[1]:
                leadStatistics.urgentLeadCount++
                break;
            case FILTERATION_PRIORITIES[2]:
                leadStatistics.highLeadCount++
                break;
            case FILTERATION_PRIORITIES[3]:
                leadStatistics.NormalLeadCount++
                break;
            case FILTERATION_PRIORITIES[4]:
                leadStatistics.LowLeadCount++
                break;
            default:
                break;
        }
        return leadStatistics
    }

    function getLeadStatisticsString(leadStatistics = INITIAL_LEAD_STATISTICS) {
        return `${leadStatistics.urgentLeadCount} ${FILTERATION_PRIORITIES[1]} | ${leadStatistics.highLeadCount} ${FILTERATION_PRIORITIES[2]} | ${leadStatistics.NormalLeadCount} ${FILTERATION_PRIORITIES[3]} | ${leadStatistics.LowLeadCount} ${FILTERATION_PRIORITIES[4]}`

    }

    function getCustomerPhoneNumbers(contacts = []) {
        const phoneNumbers = contacts.filter((contact) => {
            if (contact.type === CONTACTS_TYPES[0] || contact.type === CONTACTS_TYPES[3]) {
                return true
            } else {
                return false
            }
        })
        return phoneNumbers
    }

    function getCustomerEmails(contacts = []) {
        const emails = contacts.filter(contact => contact.type === CONTACTS_TYPES[1]).map((contact) => {
            return contact.value
        })
        return emails
    }

    async function handlePopPhoneDialer(contact, franchiseId, brandId, leadId) {

        popPhoneDialer(contact, franchiseId, brandId, leadId, "ServiceTitanLead")
    }

    return <Box>
        <div
            style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "25px",
            }}
        >
            <div>
                <div style={{ marginTop: "12px" }} className={"text-color"}>
                    <small>
                        <strong>{leadStatistics.totalLeadCount} {leadStatistics.totalLeadCount === 1 ? "Lead" : "Leads"} | </strong>{getLeadStatisticsString(leadStatistics)}
                    </small>
                </div>
            </div>
            <div style={{ display: "flex", flexDirection: 'row', flexWrap: "wrap", justifyContent: "end", gap: "1em" }}>
                <GlobalSearch
                    indexName={LEAD_INDEX}
                    setIsLoading={setIsLeadsLoading}
                    onSuggestions={(records, searchValue) => {
                        const ids = records?.map?.((r) => r?.properties?.id)
                        setFilterState((state) => { return { ...state, ids: ids } })
                    }}
                    onSearch={(searchValue) => {
                        setFilterState((state) => { return { ...state, universalSearchString: searchValue } })
                    }}
                    searchValueTransformer={((searchValue = "") => searchValue.replace(/\s+/g, ''))}
                />
                <div style={{ position: "relative" }}>
                    {(!isFranchisesLoading && isEncryptionFailed) ? <EncryptionWarningBadge title={"Temporary failure, could not create lead"} /> : <></>}
                    <Tooltip title={isEncryptionFailed ? "" : "Create Lead"} >
                        <IconButton className={isEncryptionFailed ? "icon-btn-disabled" : "icon-btn"} aria-label="delete" onClick={() => handleCreateLeadDialogOpen(undefined)}>
                            <AddIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
        </div>
        <AdvanceFilterArea brands={brands} isBrandsLoading={isBrandsLoading} isFranchisesLoading={isFranchisesLoading} uniqueFranchises={uniqueFranchises} users={users} filterState={filterState} setFilterState={setFilterState} isEncryptionFailed={isEncryptionFailed} />
        <TableContainer sx={{ flexGrow: 2 }}>
            <Table aria-label="simple table" stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell className="table-header" style={{ textAlign: "left" }} >#</TableCell>
                        <TableCell className="table-header" style={{ textAlign: "left" }} >Lead Id</TableCell>
                        <TableCell className="table-header" style={{ textAlign: "left" }}  >Brand</TableCell>
                        <TableCell className="table-header" style={{ textAlign: "left", overflow: "hidden", textOverflow: "ellipsis" }}  >Franchise</TableCell>
                        <TableCell className="table-header" style={{ textAlign: "left", overflow: "hidden", textOverflow: "ellipsis" }}  >Customer Name</TableCell>
                        <TableCell className="table-header" style={{ textAlign: "left" }}  >Customer Phone</TableCell>
                        <TableCell className="table-header" style={{ textAlign: "left" }}  >Customer Email</TableCell>
                        <TableCell className="table-header" style={{ textAlign: "left" }}  >Status</TableCell>
                        <TableCell className="table-header" style={{ textAlign: "left" }} >Priority</TableCell>
                        <TableCell className="table-header" style={{ textAlign: "left" }} ><Container style={{ padding: 0, cursor: "pointer" }} sx={{ display: "flex", flexDirection: "row", columnGap: "0.5em", alignItems: "center", justifyContent: "left", }} onClick={() => {
                            if (filterState.sortBy === SORT_BY[1]) {
                                return
                            } else {
                                setFilterState({ ...filterState, sortBy: SORT_BY[1], sortOrder: SORT_ORDER[0] });
                            }
                        }}>{"Follow-Up Date & Time"}{filterState.sortBy === SORT_BY[1] ? getArrowButton(filterState) : ""}</Container></TableCell>
                        <TableCell className="table-header" style={{ textAlign: "left", minWidth: "100px" }} >Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {isLeadsLoadingError ? buildErrorView() : isLeadsLoading ?
                        buildLoadingView()
                        : showingInViewLeads.length === 0 ?
                            buildNoLeadsToShowView()
                            :
                            showingInViewLeads.map((lead, i) => (
                                buildLeadRow(i, lead)
                            ))}
                </TableBody>
            </Table>
        </TableContainer>
        <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "end" }}>
            <Pagination onChange={(e, v) => {
                setPage(v);
            }} color="standard" count={filterState.universalSearchString ? 1 : Math.ceil(leadStatistics.totalLeadCount / SERVICETITAN_PAGE_SIZE)} sx={{ alignSelf: "right" }}
            />
        </div>
        {isExpandedLeadModalOpen && selectedLeadToView ? <ExpandedLeadModal showNotification={showNotification} onClose={handleExpandedLeadDialogClose} open={isExpandedLeadModalOpen} data={undefined} lead={selectedLeadToView} brand={getMatchingBrandByBrandId(selectedLeadToView.brandId, brands)} franchise={getMatchingFranchiseById(selectedLeadToView?.franchiseId, allFranchises)} /> : ""}
        {(isCreateLeadModalOpen || draftJob) ? <CreateLeadModal selectedFranchise={getMatchingFranchiseById(selectedLeadToEdit ? selectedLeadToEdit?.franchiseId : filterState?.franchiseId, allFranchises)} showNotification={showNotification} onClose={handleCreateLeadDialogClose} open={isCreateLeadModalOpen} selectedLeadToEdit={selectedLeadToEdit} selectedDraftToContinoue={draftJob} selectedBrandId={selectedBrandId} selectedFranchiseId={selectedFranchiseId} selectedPhoneNumber={selectedPhoneNumber} selectedZipCode={selectedZipCode} selectedTenantId={selectedTenantId} isAddNewLeadButtonClick={isAddNewLeadButtonClick} /> : <></>}
        {isEditLeadModalOpen ? <EditLeadModal onClose={handleEditLeadDialogClose} open={isEditLeadModalOpen} selectedLeadToEdit={selectedLeadToEdit} selectedFranchise={getMatchingFranchiseById(selectedLeadToEdit?.franchiseId, allFranchises)} selectedBrand={getMatchingBrandByBrandId(selectedLeadToEdit?.brandId, brands)} showNotification={showNotification} /> : <></>}
        {isOpenAgentFeedbackDialog ? <AgentFeedback handleAgentFeedbackDialogClose={handleAgentFeedBackDialogClose} open={isOpenAgentFeedbackDialog} agentFeedbackData={agentFeedbackData} /> : <></>}
    </Box>;

    function buildLeadRow(i, lead) {
        const franchise = getMatchingFranchiseById(lead.franchiseId, allFranchises);
        const isActionsDisabled = (!franchise || (franchise?.service_titan_tenant_id === "" || franchise?.service_titan_client_id === "" || franchise?.service_titan_client_secret === ""));
        const customerPhoneNumbers = getCustomerPhoneNumbers(lead.middlewareServiceTitanLead.customerContacts)
        const customerPhoneNumber = customerPhoneNumbers?.[0]?.value
        const brand = getMatchingBrandByBrandId(lead.brandId, brands)

        return <TableRow key={i}>
            <TableCell className="table-cell"  ><Typography variant="p">{i + 1}</Typography></TableCell>
            <TableCell className="table-cell" ><Typography variant="p" >{lead?.leadResponse?.id}</Typography></TableCell>
            <TableCell className="table-cell" >
                <ImageBox alt="brand-logo" src={brand?.brand_img ?? iBSBrandLogo} />
            </TableCell>
            <TableCell className="table-cell" style={{ maxWidth: "250px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}><Typography variant="p" noWrap>{franchise?.franchise_name}</Typography></TableCell>
            <TableCell className="table-cell" style={{ maxWidth: "200px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }} ><Typography variant="p" noWrap>{lead?.middlewareServiceTitanLead?.customerName ?? "-"}</Typography></TableCell>
            <TableCell className="table-cell"  >
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <Tooltip title="Call" >
                        <IconButton sx={{ color: "#00a79d" }} aria-label="Call" component="span" disabled={!customerPhoneNumber} onClick={() => {
                            if (customerPhoneNumber) {
                                handlePopPhoneDialer(customerPhoneNumber, franchise?.id, brand?.id, lead?.leadResponse?.id)
                            }
                        }}>
                            <CallIcon />
                        </IconButton>
                    </Tooltip>
                    <Typography variant="p" noWrap>{getPhoneNumberWithInputMask(customerPhoneNumber) ?? "-"}</Typography>
                </Box></TableCell>
            <TableCell className="table-cell" style={{ maxWidth: "250px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}><Typography variant="p" maxWidth={"200px"} overflow={"hidden"} noWrap >{getCustomerEmails(lead.middlewareServiceTitanLead.customerContacts)?.[0] ?? "-"}</Typography></TableCell>
            <TableCell className="table-cell" ><Typography variant="p" >{lead.leadResponse.status}</Typography></TableCell>
            <TableCell className="table-cell" >
                <Tooltip enterDelay={1000} enterTouchDelay={1000} title={lead.leadResponse.priority}><img alt={lead.leadResponse.priority} src={getMatchingPriorityImage(lead.leadResponse.priority)} height="25px" /></Tooltip>
            </TableCell>
            <TableCell className="table-cell" >
                <Typography variant="p" >{lead.leadResponse.followUpDate ? getTenantTimeZoneTimeString(lead.leadResponse.followUpDate, generalTenantConfiguration?.time_zone, timezones) : "No Follow-Up Scheduled"}</Typography>
            </TableCell>
            <TableCell className="table-cell" >
                <Box height={"100%"} position={"relative"}>
                    <IconButton disabled={isActionsDisabled} onClick={() => handleExpandedLeadModalOpen(lead)} color="primary" size={"medium"} style={{ marginRight: "10px" }}>
                        <Tooltip title={isActionsDisabled ? "" : "View Lead"}><VisibilityIcon style={{ height: "20px", width: "20px" }} /></Tooltip>
                    </IconButton>
                    {lead?.leadResponse?.status !== "Dismissed" &&
                        <IconButton disabled={isActionsDisabled} onClick={() => handleEditLeadDialogOpen(lead)} color="primary" size={"medium"} style={{ marginRight: "10px" }}>
                            <Tooltip title={isActionsDisabled ? "" : "Edit Lead"}><EditIcon style={{ height: "20px", width: "20px" }} /></Tooltip>
                        </IconButton>
                    }
                    {isActionsDisabled ? <Box height={"100%"} width={"3em"} >
                        <Box position={"absolute"} bottom={"0"} left={"0"} >
                            <Tooltip title={getCredentialsMissingWarning(franchise)}>
                                <ReportIcon
                                    style={{ height: "16px", width: "16px" }} color="warning"
                                />
                            </Tooltip>
                        </Box>
                    </Box> : <></>}
                </Box>
            </TableCell>
        </TableRow>;
    }

    function buildErrorView() {
        return buildInformationRowContainer(<ErrorView type={ERROR_COMPONENTS.SomthingWentWrong.type} />)
    }

    function buildNoLeadsToShowView() {
        return buildInformationRowContainer(<ErrorView type={ERROR_COMPONENTS.NoLeadsFound.type} />)
    }

    function buildLoadingView() {
        return buildInformationRowContainer(<CircularProgress size={30} color="inherit" />)
    }

    function buildInformationRowContainer(children) {
        return <TableRow><TableCell colSpan={11} align="center" style={{ border: "none", height: "300px", color: "grey" }}>
            {children}
        </TableCell></TableRow>;
    }

    function getArrowButton(filterState) {
        return <IconButton onClick={() => {
            filterState.sortOrder === SORT_ORDER[0] ? setFilterState({ ...filterState, sortOrder: SORT_ORDER[1] }) : setFilterState({ ...filterState, sortOrder: SORT_ORDER[0] });
        }}>{filterState.sortOrder === SORT_ORDER[0] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</IconButton>;
    }

}

function filterLeadsAccordingSortBy(allLeads, sortBy, sortOrder) {
    let filteredLeads = allLeads;
    if (sortBy === SORT_BY[0]) { //createdBy sort
        filteredLeads = filteredLeads.sort((firstLead, secondLead) => {
            return sortOrder * compareLeadsCreatedOnDate(firstLead, secondLead);
        });
    } else if (sortBy === SORT_BY[1]) { //followUpDate sort 
        filteredLeads = filteredLeads.sort((firstLead, secondLead) => {
            return sortOrder * compareLeadsFollowUpDates(firstLead, secondLead);
        });
    }
    return filteredLeads;
}

function compareLeadsCreatedOnDate(firstLead, secondLead) {
    return compareTwoRFCDateStringsAssendingOrder(firstLead.leadResponse.createdOn, secondLead.leadResponse.createdOn, firstLead.leadResponse.followUpDate, secondLead.leadResponse.followUpDate,)
}
function compareLeadsFollowUpDates(firstLead, secondLead) {
    return compareTwoRFCDateStringsAssendingOrder(firstLead.leadResponse.followUpDate, secondLead.leadResponse.followUpDate, firstLead.leadResponse.createdOn, secondLead.leadResponse.createdOn,)
}