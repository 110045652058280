import { styled } from "@mui/material/styles";
import { TextField } from "@mui/material";

const CustomTextField = styled(TextField)(({ theme }) => ({
    "& label.Mui-focused": {
        color: "#00a79d",
        fontWeight: "500",
    },
    "& label": {
        fontSize: "15px",
        fontWeight: "300",
        color: theme.palette.fieldLabelColor,
        "&.Mui-required::after": {
            content: '"*"',
            color: "red",
            paddingLeft: "5px",
        },
        "& .MuiFormLabel-asterisk": {
            display: "none"
        }
    },
    "& .MuiOutlinedInput-root": {
        "&:hover fieldset": {
            borderColor: "#00a79d",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#00a79d",
        },
    },
}));

export const CustomTextFieldForLocationAutoComplete = styled(TextField)(({ theme }) => ({
    "& label.Mui-focused": {
        color: "#00a79d",
        fontWeight: "500",
    },
    "& label": {
        fontSize: "15px",
        fontWeight: "600",
        color: theme.palette.autocompleteFieldLabel,
        "&.Mui-required::after": {
            content: '"*"',
            color: "red",
            paddingLeft: "5px",
        },
        "& .MuiFormLabel-asterisk": {
            display: "none"
        }
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: theme.palette.autocompleteBorder,
            borderWidth: '3px',
        },
        "&:hover fieldset": {
            borderColor: "#00a79d",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#00a79d",
        },
    },
}));

export default CustomTextField

