import { Button } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useMemo, useState } from "react";
import { Prompt } from "react-router-dom";
import DialogFooter from "../../../../../components/dialog/DialogFooter";
import { NOTIFICATION_TYPES } from "../../../../../constants/common";
import { handleAppointmentAssignment, handleAppointmentUnassignment, handleGetAppointmentAssignments, handleRescheduleAppointment } from "../../../../../state-services-dublicate/serviceTitan";
import { getPromptMessage } from "../../../../../utils/jobs";
import CreateAppointmentSection from "./CreateAppointmentSection";
import LoadingButton from "../../../../../components/LoadingButton";
import { handleGetArrivalWindowByBrandId } from "../../../../../state-services/brands";
import EmptyTechnicianConfirmationModal from "../emptyTechniciansConfirmModal";

export default function AppointmentReschedlingSection(props) {
    const { franchise, brand, jobToAddMultipleAppointments, onClose, showNotification, appointmentToReschedule } = props
    const [appointment, setAppointment] = useState()
    const [isRecheduleAppointmentLoading, setIsRecheduleAppointmentLoading] = useState(false)
    const [previouslyScheduledTechnicianIds, setPreviouslyScheduledTechnicianIds] = useState([])
    const [arrivalWindow, setArrivalWindow] = useState(null)
    const [isOpenProceedWithEmptyTechniciansConfirmationModal, setIsOpenProceedWithEmptyTechniciansConfirmationModal] = useState(false)

    const shouldPrompt = useMemo(() => {
        return ((appointment?.start && appointment?.start !== appointmentToReschedule?.start) || (appointment?.end && appointment?.end !== appointmentToReschedule?.end) || (appointment?.arrivalWindowStart && appointment?.arrivalWindowStart !== appointmentToReschedule?.arrivalWindowStart) || (appointment?.arrivalWindowEnd && appointment?.arrivalWindowEnd !== appointmentToReschedule?.arrivalWindowEnd))
    }, [appointment, appointmentToReschedule])

    useEffect(() => {
        if (brand) handleGetArrivalWindowByBrandId(brand?.id, setArrivalWindow)
        return () => {
            setArrivalWindow(null)
        }
    }, [brand])

    useEffect(() => {
        handleGetAppointmentAssignments(franchise, appointmentToReschedule, setPreviouslyScheduledTechnicianIds)
    }, [franchise, appointmentToReschedule])


    async function rescheduleAppointment(e) {
        try {
            e?.preventDefault();
            if (!(appointment?.arrivalWindowStart && appointment?.arrivalWindowEnd)) return showNotification({ message: "Important: Please select a timeslot and try again", type: NOTIFICATION_TYPES.ERROR })
            setIsRecheduleAppointmentLoading(true)
            await handleRescheduleAppointment(franchise, appointmentToReschedule?.id, appointment)
            if (previouslyScheduledTechnicianIds?.length > 0) {
                const appointmentUnssignmentRequest = {
                    jobAppointmentId: appointmentToReschedule.id,
                    technicianIds: previouslyScheduledTechnicianIds ?? []
                }
                await handleAppointmentUnassignment(franchise, appointmentUnssignmentRequest)
            }
            if (appointment?.technicianIds?.length > 0) {
                const appointmentAssignmentRequest = {
                    jobAppointmentId: appointmentToReschedule.id,
                    technicianIds: appointment.technicianIds
                }
                await handleAppointmentAssignment(franchise, appointmentAssignmentRequest)
            }
            setIsRecheduleAppointmentLoading(false)
            showNotification({ message: "The appointment has been rescheduled successfully", type: NOTIFICATION_TYPES.SUCCES })
            onClose(jobToAddMultipleAppointments, undefined)
        } catch {
            showNotification({ message: "Important: appointment couldn’t be rescheduled at this time. Please try again", type: NOTIFICATION_TYPES.ERROR })
        }
    }

    function handleClose() {
        onClose(jobToAddMultipleAppointments)
    }

    function checkIfBookingWithEmptyTechnicians(e) {
        e.preventDefault()
        if (appointment?.technicianIds?.length === 0) {
            setIsOpenProceedWithEmptyTechniciansConfirmationModal(true)
        } else {
            rescheduleAppointment()
        }
    }

    function rescheduleJobWithoutTechnicians () {
        setIsOpenProceedWithEmptyTechniciansConfirmationModal(false)
        rescheduleAppointment()
    }

    return <>
        <Box sx={{ display: "flex", flexDirection: "column", flex: "1 2 0", width: "100%", overflowX: "hidden", overflowY: "scroll", paddingRight: "1em" }} >
            <form onSubmit={checkIfBookingWithEmptyTechnicians}>
                <Prompt
                    when={Boolean(shouldPrompt) ?? false}
                    message={getPromptMessage}
                />
                <CreateAppointmentSection
                    franchise={franchise}
                    brand={brand}
                    job={jobToAddMultipleAppointments}
                    arrivalWindow={arrivalWindow}
                    appointment={appointment}
                    setAppointment={setAppointment}
                    isReschedule={appointmentToReschedule !== undefined}
                    appointmentToReschedule={appointmentToReschedule}
                    previouslyScheduledTechnicianIds={previouslyScheduledTechnicianIds}
                />
                <DialogFooter className={'dialog-footer'}>
                    <Button size={"small"} variant="contained" onClick={handleClose} className={"btn-secondary"}>Cancel</Button>
                    <LoadingButton type={"submit"} size={"small"} variant="contained" disabled={isRecheduleAppointmentLoading} loading={isRecheduleAppointmentLoading} className={isRecheduleAppointmentLoading ? "btn-disable" : "btn-primary"} >
                        {"Reschedule"}
                    </LoadingButton>
                </DialogFooter>
            </form>
        </Box >
        {isOpenProceedWithEmptyTechniciansConfirmationModal ? <EmptyTechnicianConfirmationModal isOpen={isOpenProceedWithEmptyTechniciansConfirmationModal} wantToBookJobWithEmptyTechnicians={rescheduleJobWithoutTechnicians} onCloseHandler={() => setIsOpenProceedWithEmptyTechniciansConfirmationModal(false)} /> : <></>}
    </>
}