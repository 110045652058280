import { Box, CircularProgress, Tooltip, Typography } from "@mui/material"
import iBSBrandLogo from "../../assets/img/brand.png"
import ImageBox from "../LogoBox/ImageBox";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export default function SmallCard(props) {
    const { heading, value, maxWidth, shouldAddColorTovalue = false, colorOfTheValue = '' } = props;
    let cardMaxWidth = maxWidth
    if (maxWidth) {
        cardMaxWidth = "150px"
    }
    return <div style={{ maxWidth: cardMaxWidth, display: "flex", flexDirection: "column", fontSize: "1rem", padding: "0.5em" }}>
        <Typography style={{ color: "gray", fontSize: "0.8rem", margin: "0.1em", marginTop: "auto" }}>{heading}</Typography>
        {shouldAddColorTovalue ? <Typography style={{ color: colorOfTheValue, margin: "0", fontSize: "0.9rem", }}>{value ? value : "-"}</Typography>
            : <Typography style={{ margin: "0", fontSize: "0.9rem", }}>{value ? value : "-"}</Typography>}
    </div>
}

export function SmallCardForEstimateSubTotal(props) {
    const { heading, value, maxWidth } = props;
    let cardMaxWidth = maxWidth
    if (maxWidth) {
        cardMaxWidth = "150px"
    }
    return <div style={{ maxWidth: cardMaxWidth, display: "flex", flexDirection: "column", fontSize: "1rem", padding: "0.5em" }}>
        <Box sx={{ display: "flex", flexDirection: "row", gap: "0.5em" }}>
            <Typography style={{ color: "gray", fontSize: "0.8rem", margin: "0.1em", marginTop: "auto" }}>{heading}</Typography>
            <Tooltip title="Taxes are not included in the subtotal amount within the middleware.">
                <InfoOutlinedIcon sx={{ cursor: "pointer" }} color="info" style={{ height: "20px", width: "20px" }} />
            </Tooltip>
        </Box>
        <Typography style={{ margin: "0", fontSize: "0.9rem", }}>{value ? value : "-"}</Typography>
    </div>
}

export function SmallCardForEstimateName(props) {
    const { heading, value } = props;
    return <div style={{ display: "flex", flexDirection: "column", fontSize: "1rem", padding: "0.5em" }}>
        <Typography style={{ color: "gray", fontSize: "0.8rem", margin: "0.1em", marginTop: "auto" }}>{heading}</Typography>
        <Typography
            style={{
                margin: "0",
                fontSize: "0.9rem",
                maxWidth: "100%",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap"
            }}>
            {value ? value : "-"}</Typography>
    </div>
}

export function SmallCardForCrmData(props) {
    const { heading, value, isLoading, isError, image } = props;
    return <div style={{ width: "30%", height: "100px", display: "flex", flexDirection: "row", fontSize: "1rem", padding: "0.5em" }} className={'card-crm'}>
        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <div>
                <Typography style={{ color: "gray", fontSize: "1rem", margin: "0.1em", marginTop: "auto", fontWeight: "400" }}>{heading}</Typography>
            </div>
            <div style={{ display: "flex", flexDirection: "row", marginTop: "auto" }}>
                {isLoading ? <CircularProgress color="inherit" /> : isError ? <h6>{"Something went wrong"}</h6>
                    : <>
                        <Typography style={{ margin: "0", marginTop: "auto", fontSize: "1.5rem", marginLeft: ".6rem" }}>{value ? value : "-"}</Typography>
                    </>}
                <img style={{ height: "30px", marginLeft: "auto" }} src={image}></img>
            </div>
        </div>
    </div>
}

export function SmallCardForBrand(props) {
    const { heading, brand } = props;
    return <div style={{ maxWidth: "150px", display: "flex", flexDirection: "column", fontSize: "1rem", padding: "0.5em" }}>
        <Typography style={{ color: "gray", fontSize: "0.8rem", margin: "0.1em", marginTop: "auto" }}>{heading}</Typography>
        <ImageBox alt="brand-logo" src={brand?.brand_img ?? iBSBrandLogo} size={'medium'} />
    </div>
}

export function SmallCardForSummary(props) {
    const { heading, value } = props;
    return <div style={{ maxWidth: "auto", display: "flex", flexDirection: "column", fontSize: "1rem", padding: "0.5em" }}>
        <Typography style={{ color: "gray", fontSize: "0.8rem", margin: "0.1em", marginTop: "auto" }}>{heading}</Typography>
        <Typography style={{ margin: "0", fontSize: "0.9rem", }}>{value ? value : "-"}</Typography>
    </div>
}

export function SmallCardForTechnicians(props) {
    const { heading, technicians } = props;
    return <div style={{ maxWidth: "auto", display: "flex", flexDirection: "column", fontSize: "1rem", padding: "0.5em" }}>
        <Typography style={{ color: "gray", fontSize: "0.8rem", margin: "0.1em", marginTop: "auto" }}>{heading}</Typography>
        <Box sx={{ display: "flex", flexWrap: 'wrap', flexDirection: "row", gap: "5px" }}>
            {technicians?.map((technician, i) =>
                <Box key={i} id={i}
                    sx={{
                        borderRadius: "1em",
                        maxWidth: "fit-content",
                        border: "1px solid #BDBDBD",
                        paddingInline: "0.5em",
                        paddingBlock: "0.2em",
                    }}
                >
                    <Typography variant="body2" >{technician?.technicianName}</Typography>
                </Box>
            )}
        </Box>
    </div>
}