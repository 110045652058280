import { Box, Button, Dialog, Typography } from "@mui/material";
import TagViewer from "../../../../../components/TagViewer";
import { handleAddKeywords } from "../../../../../state-services/faq/faq";
import { CommonContext } from "../../../../../contexts/CommonContextProvider";
import { useCallback, useContext, useEffect, useState } from "react";
import { NOTIFICATION_TYPES } from "../../../../../constants/common";

export default function AddKeywordDialog({ isDialogOpen, handleClose, onDone, allKeywords = [] }) {

    const { showNotification } = useContext(CommonContext)
    const [keywords, setKeywords] = useState([])

    const onError = useCallback(() => {
        showNotification({ message: "Important : Could not add. Please try again", type: NOTIFICATION_TYPES.ERROR })
    }, [showNotification])

    const onSuccess = useCallback(() => {
        showNotification({ message: "Success", type: NOTIFICATION_TYPES.SUCCESS })
        setKeywords([])
        onDone()
        handleClose()
    }, [handleClose, showNotification])

    useEffect(() => {
        setKeywords([])
    }, [isDialogOpen])

    return <Dialog open={isDialogOpen} onClose={handleClose}>
        <Box className={'dialog-color'}>
            <Box sx={{ margin: "1em", minHeight: "10em", minWidth: "30em", display: "flex", flexDirection: "column" }}>
                <Typography variant="subtitle1" component="div" sx={{ fontWeight: "bold" }}>Add Keywords</Typography>
                <TagViewer
                    width="15em"
                    title={"Keyword"}
                    displayLimit={8}
                    tagArray={keywords}
                    setTagArray={setKeywords}
                    getIsNewValueValid={(value) => getIsKeywordValid(value, allKeywords, keywords)}
                    disableTagTrimming={true}
                    allowSpaces={true}
                    clearable={true}
                />
                <Box sx={{ flexGrow: 2 }} />
                <Box sx={{ display: "flex", flexDirection: "row", gap: "0.5em" }} >
                    <Box sx={{ flexGrow: 2 }} />
                    <Button data-test={`faq_keyword_add_keyword_cancel`} variant="text" onClick={handleClose} >Cancel</Button>
                    <Button data-test={`faq_keyword_add_keyword_save`} variant="contained" onClick={() => handleAddKeywords(keywords, onError, onSuccess)}>Save</Button>
                </Box>
            </Box>
        </Box>
    </Dialog>
}

async function getIsKeywordValid(value, oldKeywords = [], newKeywords = []) {

    const newKeywordsObjects = newKeywords?.map?.((k) => ({ keyword: k?.trim?.() })) ?? []
    const allKeywords = [...oldKeywords, ...newKeywordsObjects]
    const separatedKeywords = value?.split?.(",") ?? []
    for (let keyword of separatedKeywords) {
        const trimmedValue = keyword?.trim?.()
        if (!Boolean(trimmedValue)) {
            return { isValid: false, message: `Keyword cannot be empty` }
        } else if (trimmedValue?.length > 50) {
            return { isValid: false, message: `Keyword (${trimmedValue}) cannot be longer than 50 characters` }
        } else if (allKeywords?.some?.((k) => k?.keyword?.toLowerCase?.() === trimmedValue?.toLowerCase?.())) {
            return { isValid: false, message: `Keyword (${trimmedValue}) already exists` }
        } else if (containsSpecialCharacters(trimmedValue)) {
            return { isValid: false, message: `Keyword (${trimmedValue}) cannot contain special characters` }
        }
    }
    return { isValid: true, message: `` }
}

function containsSpecialCharacters(str) {
    const specialCharRegex = /[^a-zA-Z0-9- ]/;
    return specialCharRegex.test(str);
}