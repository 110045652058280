const DESKTOP_APP_BASE_URL = "http://localhost:8546" //"http://192.168.1.3:8546"

export const checkDesktopAppWorking = async () => {
    let requestOptions = {
        headers: { 'Content-Type': 'application/json' },
        method: 'GET'
    };
    return fetch(`${DESKTOP_APP_BASE_URL}/`, requestOptions)
}

export const startRecordingInDesktopApp = async (data) => {
    let requestOptions = {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify(data)
    };
    return fetch(`${DESKTOP_APP_BASE_URL}/call`, requestOptions)
}