import { FetchClient } from "../../../utils/fetchClient"

export async function createFranchiseV3(body) {
    return FetchClient("/data/v3/franchise").POST({ payload: JSON.stringify(body) })
}

export async function updateFranchiseV3(body) {
    return FetchClient("/data/v3/franchise").PUT({ payload: JSON.stringify(body) })
}

export async function getFranchiseByIdV3(franchiseId = 0) {
    return FetchClient(`/data/v3/franchise?id=${franchiseId}`).GET({})
}

export async function getFranchisesV3(brandId = 0, isActive = 0, page = 1, pageSize = 10, search = "") {
    return FetchClient(`/data/v3/franchises?brand_id=${brandId}&is_active=${isActive}&page=${page}&page_size=${pageSize}&search=${search}`).GET({})
}

export async function getFranchisesStatisticsV3() {
    return FetchClient(`/data/v3/franchises-statistics`).GET({})
}

export async function enableOrDisableFranchiseV3(franchiseId = 0, isEnabled = 0) {
    return FetchClient(`/data/v3/franchise/change-status?id=${franchiseId}&is_enabled=${isEnabled}`).PATCH({})
}

export async function removeFranchiseV3(franchiseId = 0) {
    return FetchClient(`/data/v3/franchise?id=${franchiseId}`).DELETE({})
}

export async function getFranchiseIdValidationV3(franchiseFranchiseId = 0) {
    return FetchClient(`/data/v3/franchise/franchise-id/validate?franchise_id=${franchiseFranchiseId}`).GET({})
}


export async function getFranchiseByAngiesEntityIdV3(entityId = "") {
    return FetchClient(`/data/v3/franchise/from-angies-entity-id?entity_id=${entityId}`).GET({})
}

export async function getFranchiseFromChannelIdV3(channelId = "") {
    return FetchClient(`/data/v3/franchise/from-channel-id?channel_id=${channelId}`).GET({})
}

export async function getOtherFranchisesV3(brandId = 0, excludingFranchiseIds = [], zipCode = "", radiusInKm = 0) {
    let excludingIdsQuery = "";
    excludingFranchiseIds?.forEach?.((id) => {
        if (excludingIdsQuery !== "") excludingIdsQuery += "&"
        excludingIdsQuery += `excluding_franchise_ids=${id}`
    })
    return FetchClient(`/data/v3/franchises/with-distance/other?brand_id=${brandId}&${excludingIdsQuery}&zip_code=${zipCode}&radius_in_km=${radiusInKm}`).GET({})
}

export async function getFranchiseByZipCodeV3(brandId = 0, zipCode = "") {
    if (!zipCode) zipCode = ""
    return FetchClient(`/data/v3/franchises/with-distance/default?brand_id=${brandId}&zip_code=${zipCode}`).GET({})
}