import { useState, useEffect, useContext, useMemo } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import { Autocomplete, Pagination } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from '@mui/icons-material/Edit';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
import ReportIcon from "@mui/icons-material/Report";
import DisabledByDefaultOutlinedIcon from '@mui/icons-material/DisabledByDefaultOutlined';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Notification from "../../../components/Notification";
import { FRANCHISE_MIGRATION_STATUS, ERROR_COMPONENTS, FRANCHISE_STATUS, NOTIFICATION_TYPES, ROW_COUNT_PER_PAGE, STATUS_EMOJI, AVAILABLE_CRMS, userLevels, TOLL_FREE, CALL_CENTER_BOOKING } from "../../../constants/common";
import ErrorView from "../../../components/ErrorView";
import { CustomSelect, CustomTextField } from "./franchiseCustomStyle";
import FranchiseModal from "./model/FranchiseModel";
import DeleteDialog from "./DeleteDialog";
import { MISSING_CREDIENTIALS_WARNING } from "../../../constants/errorMessages";
import { createFranchiseSectionsMap } from "../../../constants/franchise";
import { renderBrandOption } from "../../../components/servicetitan/RenderInput";
import { UserContext } from "../../../contexts/User";
import { getFranchiseByIdV3 } from "../../../services/franchise/v3/franchise";
import useDebounce from "../../../hooks/useDebounce";
import { CommonContext } from "../../../contexts/CommonContextProvider";
import { handleGetFranchisesStatisticsV3, handleGetFranchisesV3 } from "../../../state-services/franchise/v3/franchise";
import StarIcon from '@mui/icons-material/Star';
import { withRouter } from "react-router-dom";

function Franchise(props) {

    const franchiseId = useMemo(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const franchiseId = urlParams.get('id');
        return franchiseId
    }, [])

    const { brands } = useContext(CommonContext)
    const [searchValue, setSearchValue] = useState("");
    const [dialogOpen, setDialogOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [franchise, setFranchise] = useState(null);
    const [franchises, setFranchises] = useState([]);
    const [franchiseCount, setFranchiseCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [status, setStatus] = useState(FRANCHISE_STATUS.active);
    const [brand, setBrand] = useState(null)
    const [notify, setNotify] = useState({ message: "", type: "", isOpen: false });
    const [refresh, setRefresh] = useState(false)
    const [franchiseStatistics, setFranchiseStatistics] = useState({ total: 0, active: 0, inactive: 0 })
    const [currentPage, setCurrentPage] = useState(1)
    const [franchiseDetails, setFranchiseDetails] = useState(null)
    const [displaySection, setDisplaySection] = useState(createFranchiseSectionsMap.main.index)
    const [action, setAction] = useState(null)
    const { me } = useContext(UserContext)

    const debouncedGetFranchisesV3 = useDebounce({ page: currentPage, isActive: status, brandId: brand?.brand_id, search: searchValue }, ({ page, isActive, brandId, search }) => {
        handleGetFranchisesV3(brandId, isActive, page, ROW_COUNT_PER_PAGE, search, setFranchises, setLoading, setFranchiseCount)
    }, 500);

    useEffect(() => {
        if (franchiseId) {
            handleDialogOpen({ id: franchiseId })
        }
    }, [franchiseId])

    useEffect(() => {
        handleGetFranchisesStatisticsV3(setFranchiseStatistics)
    }, [])

    useEffect(() => {
        debouncedGetFranchisesV3({ page: currentPage, isActive: status, brandId: brand?.brand_id, search: searchValue })
    }, [status, currentPage, brand, searchValue, refresh])

    const handleStatusChange = (e) => {
        setCurrentPage(1)
        setBrand()
        setStatus(e.target.value);
    };

    const onPageChangeHandler = (page) => setCurrentPage(page)
    const handleRefreshData = () => setRefresh((refresh) => !refresh)

    const handleClearSearchField = () => {
        setCurrentPage(1)
        setSearchValue('')
        handleRefreshData()
    }

    const handleDialogOpen = async (data) => {
        setFranchise(data);
        if (data?.id) await getFranchiseByIdHandler(data?.id)
        setDialogOpen(true);
    };

    const handleBrandChange = (brand) => {
        setCurrentPage(1)
        setBrand(brand)
        handleRefreshData()
    }

    const getFranchiseByIdHandler = async (id) => {
        try {
            let franchise = await getFranchiseByIdV3(id)
            setFranchiseDetails(franchise)
        } catch (error) {
            showNotification({ message: "Unable to get Franchise at this moment", type: NOTIFICATION_TYPES.ERROR })
        }
    }

    const handleDialogClose = () => {
        setDialogOpen(false);
        setFranchiseDetails(null)
        setFranchise(null);
        setDisplaySection(createFranchiseSectionsMap.main.index)
    };

    const handleSetSearchValue = async (e) => {
        setCurrentPage(1)
        let keyWord = e.target.value.replace(/^\s+/g, '')
        if (keyWord !== '') {
            setSearchValue(keyWord)
        } else {
            setSearchValue('')
            handleRefreshData()
        }
    };

    const handleFranchiseStatusChange = (franchise) => {
        setAction(status === FRANCHISE_STATUS.active ? FRANCHISE_STATUS.deleted : FRANCHISE_STATUS.active)
        setFranchise(franchise);
        setDeleteDialogOpen(true);
    };

    const handleFranchiseDelete = (franchise) => {
        setAction(FRANCHISE_STATUS.removed)
        setFranchise(franchise);
        setDeleteDialogOpen(true);
    };

    const handleDeleteDialogClose = () => {
        setDeleteDialogOpen(false);
        setFranchise(null);
    };

    function showNotification(notify = {}) {
        setNotify({ ...notify, isOpen: true })
    }

    const handleRowNumber = (i) => {
        if (currentPage === 1) {
            return i + 1
        } else {
            return (currentPage - 1) * ROW_COUNT_PER_PAGE + i + 1
        }
    }

    return (
        <Box className={"dashboard"} sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <div style={{ padding: "15px", borderRadius: "7px" }} className={"bg-shadow bg-white"}>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "15px" }}>
                            <div>
                                <h2 style={{ margin: "0" }} >Franchise</h2>
                                <div style={{ display: "flex", marginTop: "15px" }} className={"text-color"}>
                                    {status === FRANCHISE_STATUS.active ?
                                        <small><strong>{franchiseCount} Active</strong></small>
                                        :
                                        <small><strong>{franchiseStatistics.inactive} Inactive</strong></small>
                                    }
                                </div>
                            </div>
                            <div style={{ display: "flex", position: 'relative' }}>
                                {status === FRANCHISE_STATUS.active ? <Autocomplete
                                    sx={{ minWidth: "300px", maxWidth: "300px", marginRight: "20px", flexGrow: 2 }} size="small" noOptionsText="No brands" disabled={loading}
                                    disablePortal options={brands}
                                    getOptionLabel={(brand) => brand.brand_name}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    renderOption={renderBrandOption}
                                    value={(brands?.length > 0 && brand) ? brand : null}
                                    onChange={(_, brand) => {
                                        handleBrandChange(brand);
                                    }}
                                    renderInput={(params) => <CustomTextField {...params} label="Brand" />}
                                /> : <></>}
                                <CustomSelect style={{ minWidth: "170px", marginRight: "20px" }}>
                                    <Select
                                        size={"small"}
                                        displayEmpty
                                        inputProps={{ "aria-label": "Without label" }}
                                        className={"cus-input"}
                                        value={status}
                                        onChange={handleStatusChange}
                                    >
                                        <MenuItem value={FRANCHISE_STATUS.active}>{STATUS_EMOJI.active} Active</MenuItem>
                                        <MenuItem value={FRANCHISE_STATUS.deleted}>{STATUS_EMOJI.inactive} Inactive</MenuItem>
                                    </Select>
                                </CustomSelect>
                                <CustomTextField
                                    onChange={handleSetSearchValue}
                                    id="outlined-basic"
                                    label={"Search"}
                                    size={"small"}
                                    style={{ width: "200px", marginRight: "15px" }}
                                    variant="outlined"
                                    value={searchValue}
                                />
                                {(searchValue?.length > 0 && searchValue !== " ") &&
                                    <IconButton
                                        onClick={handleClearSearchField}
                                        style={{ position: "absolute", left: status === FRANCHISE_STATUS?.active ? "670px" : "350px", top: "5.5px" }}
                                        size={"small"}
                                        aria-label="delete"
                                    >
                                        <CloseIcon style={{ height: "20px", width: "20px" }} />
                                    </IconButton>
                                }
                                {status !== FRANCHISE_STATUS.deleted && me?.level > userLevels.AGENT &&
                                    <IconButton
                                        onClick={() => handleDialogOpen(franchise)}
                                        className={"icon-btn"}
                                        aria-label="delete"
                                    >
                                        <AddIcon />
                                    </IconButton>
                                }
                            </div>
                        </div>
                        <TableContainer style={{ height: "70vh" }}>
                            <Table aria-label="simple table" stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>#</TableCell>
                                        <TableCell>Franchise Name</TableCell>
                                        <TableCell>CRM</TableCell>
                                        <TableCell>Franchise Id</TableCell>
                                        <TableCell>Updated Date</TableCell>
                                        <TableCell align="center">Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {loading ? (
                                        <TableRow>
                                            <TableCell
                                                colSpan={10}
                                                align="center"
                                                style={{ border: "none", height: "300px", color: "grey" }}
                                            >
                                                <CircularProgress size={30} color="inherit" />
                                            </TableCell>
                                        </TableRow>
                                    ) : (
                                        franchises.length === 0 ?
                                            <TableRow>
                                                <TableCell colSpan={11} align="center" style={{ border: "none", height: "300px", color: "grey" }}>
                                                    <ErrorView type={ERROR_COMPONENTS.NOT_FOUND} title="No Franchises Found" />
                                                </TableCell>
                                            </TableRow>
                                            :
                                            franchises?.map((franchise, i) => (
                                                <TableRow key={i}>
                                                    <TableCell>{handleRowNumber(i)}</TableCell>
                                                    <TableCell>
                                                        <Box sx={{ display: "flex", gap: "0.5em", alignItems: "center" }}>
                                                            <Box>
                                                                {franchise.franchise_name}
                                                                {status === FRANCHISE_STATUS.active && (
                                                                    franchise.exact_zipcode === "" &&
                                                                    franchise.franchise_id !== TOLL_FREE.franchiseIdNumber && (
                                                                        <Tooltip title="Please provide the exact zip code to calculate the distances">
                                                                            <IconButton size="small" color="warning">
                                                                                <ReportIcon style={{ height: "16px", width: "16px" }} />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    ))
                                                                }
                                                            </Box>
                                                            <Box sx={{ paddingTop: "5px" }}>
                                                                {franchise?.is_call_center_booking === CALL_CENTER_BOOKING.booking &&
                                                                    <Box>
                                                                        <Tooltip title="CCC Booking">
                                                                            <StarIcon className="call-center-booking-icon" />
                                                                        </Tooltip>
                                                                    </Box>
                                                                }
                                                            </Box>
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell>
                                                        {franchise?.is_toll_free === 1 ? "TOLL FREE" : franchise.service_brand}
                                                        {franchise?.is_toll_free !== 1 && getShouldShowMissingCredentialsWarning(franchise, status) &&
                                                            <Tooltip title={franchise && !franchise?.is_encryption_failed ? MISSING_CREDIENTIALS_WARNING : ""}>
                                                                <IconButton size="small" color="error">
                                                                    <ReportIcon
                                                                        style={{ height: "16px", width: "16px" }}
                                                                    />
                                                                </IconButton>
                                                            </Tooltip>}
                                                    </TableCell>
                                                    <TableCell>{franchise.franchise_id === TOLL_FREE.franchiseIdNumber ? "TOLL FREE" : franchise.franchise_id}</TableCell>
                                                    <TableCell>{moment(franchise.last_updated_at).format("MM/ DD/ YYYY")}</TableCell>
                                                    <TableCell align="center">
                                                        <Tooltip title={status === FRANCHISE_STATUS.active && me?.level > userLevels.AGENT ? "Edit" : "View"}>
                                                            <span>
                                                                <IconButton onClick={() => handleDialogOpen(franchise)} color="info">
                                                                    {status === FRANCHISE_STATUS.active && me?.level > userLevels.AGENT ? <EditIcon style={{ height: "20px", width: "20px" }} /> : <RemoveRedEyeIcon style={{ height: "20px", width: "20px" }} />}
                                                                </IconButton>
                                                            </span>
                                                        </Tooltip>
                                                        {me?.level > userLevels.AGENT &&
                                                            <>
                                                                <Tooltip title={franchise?.is_toll_free === 1 ? "Toll free franchise cannot be deactivated" : (status === FRANCHISE_STATUS.deleted && franchise.is_migrated === FRANCHISE_MIGRATION_STATUS.Migrated) ? "This franchise successfully migrated to Servicetitan" : status === FRANCHISE_STATUS.active ? "Deactivate" : "Activate"}>
                                                                    <span>
                                                                        <IconButton onClick={() => handleFranchiseStatusChange(franchise)}
                                                                            color="inherit"
                                                                            disabled={(franchise?.is_toll_free === 1) || (status === FRANCHISE_STATUS.deleted && franchise.is_migrated === FRANCHISE_MIGRATION_STATUS.Migrated) && true}
                                                                        >
                                                                            {status === FRANCHISE_STATUS.active ?
                                                                                <DisabledByDefaultOutlinedIcon style={{ height: "20px", width: "20px" }} /> :
                                                                                <SettingsBackupRestoreIcon style={{ height: "20px", width: "20px" }} />
                                                                            }
                                                                        </IconButton>
                                                                    </span>
                                                                </Tooltip>
                                                                <Tooltip title={franchise?.is_toll_free === 1 ? "Toll free franchise cannot be deleted" : "Delete"}>
                                                                    <span>
                                                                        <IconButton disabled={franchise?.is_toll_free === 1} color="error" size={"small"} onClick={() => handleFranchiseDelete(franchise)}>
                                                                            <DeleteIcon style={{ height: "20px", width: "20px" }} />
                                                                        </IconButton>
                                                                    </span>
                                                                </Tooltip>
                                                            </>
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Pagination page={currentPage} onChange={(e, v) => onPageChangeHandler(v)} className="pagination-bar" color="standard"
                            count={Math.ceil(franchiseCount / ROW_COUNT_PER_PAGE)}
                        />
                        {dialogOpen &&
                            <FranchiseModal
                                status={status}
                                onClose={handleDialogClose}
                                open={dialogOpen}
                                franchiseDetails={franchiseDetails}
                                showNotification={showNotification}
                                handleRefreshData={handleRefreshData}
                                brands={brands}
                                displaySection={displaySection}
                            />
                        }
                        <DeleteDialog
                            onClose={handleDeleteDialogClose}
                            open={deleteDialogOpen}
                            franchise={franchise}
                            handleRefreshData={handleRefreshData}
                            action={action}
                        />
                    </div>
                </Grid>
            </Grid>
            {(notify?.isOpen) ? <Notification notify={notify} setNotify={setNotify} /> : ""}
        </Box>
    );
}


export default withRouter(Franchise);


function getShouldShowMissingCredentialsWarning(franchise, status) {
    return status === FRANCHISE_STATUS.active && (franchise?.service_brand?.trim()) === AVAILABLE_CRMS.servicetitan.value && franchise?.service_titan_client_id === "" && franchise?.service_titan_client_secret === "" && !franchise?.is_encryption_failed
}
