import { Button, Container, Dialog, IconButton, Tooltip, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import React from "react";

export default function RestoreJobCheckingModal(props) {
    const { isOpen, loadingMessage, onCloseHandler, handleDialogOpen } = props

    function onYesPressed() {
        onCloseHandler()
        handleDialogOpen()
    }

    function onNoPressed() {
        onCloseHandler()
    }

   
    return <Dialog maxWidth='md' open={isOpen} onClose={() => { }}>
        <Tooltip title={"Close"}>
            <IconButton onClick={() => { onCloseHandler() }} style={{ position: "absolute", right: "5px", top: "5px" }} size={"small"} aria-label="delete">
                < CloseIcon style={{ height: "15px", width: "15px" }} />
            </IconButton>
        </Tooltip>
        <Container sx={{ display: "flex", flexDirection: "column", paddingTop: "2em", paddingBottom: "2em", alignContent: "center", gap: "1em" }}>
            <Typography style={{ paddingTop: "0.5em" }} variant="p">
                {loadingMessage.split('.').map((item, index) => (
                    <React.Fragment key={index}>
                        {item}.
                        <br />
                    </React.Fragment>
                ))}
            </Typography>
            <Container sx={{ display: "flex", flexDirection: "row", justifyContent: "center", gap: "1em" }} >
                <Button data-test="no" size="small" onClick={() => { onNoPressed() }} className="btn-secondary btn-no" sx={{ marginLeft: "auto" }}>No</Button>
                <Button data-test="yes" variant="contained" size="small" onClick={() => { onYesPressed() }} className="btn-primary" >Yes</Button>
            </Container>
        </Container>
    </Dialog >;
}