import React, { useState, useEffect, useContext, useRef, useMemo } from "react";
import { Badge, Box, Button, Menu, Typography } from "@mui/material";
import NotificationsIcon from '@mui/icons-material/Notifications';
import { handleGetUserNotifications, handleMarkAllNotificationAsRead, handleMarkNotificationAsRead, handleSocketConnection } from "../../state-services/notification/notification";
import moment from "moment";
import { CommonContext } from "../../contexts/CommonContextProvider";
import { UserContext } from "../../contexts/User";
import { useHistory } from "react-router-dom";
import NoContentView from "../refreshView/NoContentView";
import NotificationIcon from "./components/NotificationIcon";
import notificationSound from '../../assets/audio/notification.mp3';

const NOTIFICATION_PAGE_SIZE = 10
const PRE_LOADING_BUFFER_PERCENTAGE = 5

export const NOTIFICATION_TITLES = {
    NEW_ANGIES_LEAD: "New Angie's Lead"
}

export default function Notification(props) {
    const notificationAudio = useMemo(() => {
        const notificationAudio = new Audio(notificationSound);
        notificationAudio.volume = 0.1;
        return notificationAudio
    }, [])
    const { setSocket, setAdminId, socketMessage, setSocketMessage, showNotification, setSessionId } = useContext(CommonContext);
    const [anchorEl, setAnchorEl] = useState(null);
    const [notificationsCount, setNotificationsCount] = useState(0);
    const [notifications, setNotifications] = useState([])
    const [newNotifications, setNewNotifications] = useState()
    const [isNotificationsLoading, setIsNotificationsLoading] = useState();
    const [isNotificationsLoadingError, setIsNotificationsLoadingError] = useState();
    const [page, setPage] = useState(1)
    const { me } = useContext(UserContext);

    const isOpen = Boolean(anchorEl);

    useEffect(() => {
        if (me?.user_id) {
            const broadcastType = getBroadcastTypeOfAUser(me?.level)
            const cancelFunc = handleSocketConnection(me?.user_id, new Date().getTime(), broadcastType, setSocketMessage, setSocket, setAdminId, setSessionId)
            return cancelFunc
        }
        return () => { }
    }, [me])

    useEffect(() => {
        if (me?.user_id) handleGetUserNotifications(me?.user_id, addDays(undefined, -30), (new Date()).toISOString(), page, NOTIFICATION_PAGE_SIZE, setNotificationsCount, setNewNotifications, setIsNotificationsLoading, setIsNotificationsLoadingError)
    }, [me?.user_id, page])

    useEffect(() => {
        if (socketMessage && socketMessage?.title) {
            setNotificationsCount((count) => { return { ...count, unread: count.unread + 1 } })
            setNotifications((notifications) => {
                if (notifications?.some((n) => n.id === socketMessage.id)) {
                    return notifications
                } else {
                    return [socketMessage, ...notifications]
                }
            })
            notificationAudio.play()
        }
    }, [socketMessage, notificationAudio])

    useEffect(() => {
        if (newNotifications?.length) {
            setNotifications((notifications) => { return [...notifications, ...newNotifications] })
        }
        setNewNotifications()
    }, [newNotifications])

    const handleClick = async (event) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <Box>
            <Badge badgeContent={notificationsCount?.unread ? notificationsCount.unread : undefined} color="warning" onClick={(e) => handleClick(e)}>
                <NotificationsIcon className="notification" fontSize="medium" color="primary" />
            </Badge>
            <Menu
                anchorEl={anchorEl}
                open={isOpen}
                onClose={() => setAnchorEl(null)}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        padding: "0.5em",
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <Box sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }} >
                    <Typography fontWeight={"bold"} >Notifications</Typography>
                    <Button size="small" variant="text" onClick={() => {
                        handleMarkAllNotificationAsRead(me?.user_id, showNotification)
                        setNotificationsCount({ ...notificationsCount, unread: 0 })
                        setNotifications([...notifications.map((n) => {
                            return { ...n, is_viewed: 1 }
                        })])
                    }} >Mark all as read</Button>
                </Box>
                {isNotificationsLoadingError ? <NoContentView message="Something went wrong" /> : <Notifications notifications={notifications} setNotifications={setNotifications} setNotificationsCount={setNotificationsCount} notificationsCount={notificationsCount} page={page} setPage={setPage} isNotificationsLoading={isNotificationsLoading} user={me} />}
            </Menu>
        </Box>
    )
}

function addDays(date = new Date().toISOString(), days = 1) {
    return moment(date).add(days, "d").toISOString()
}

function Notifications(props) {
    const { notifications, setNotifications, notificationsCount, setNotificationsCount, page, setPage, isNotificationsLoading, user } = props

    const [todayNotifications, yesterdayNotifications, lastWeekNotifications, lastMonthNotifications] = useMemo(() => splitNotifications(notifications), [notifications])
    const scrollRef = useRef(null);
    const handleScroll = () => {
        const scrollElement = scrollRef.current;
        const threshold = scrollElement.clientHeight * (PRE_LOADING_BUFFER_PERCENTAGE / 100);
        if (scrollElement.scrollTop + scrollElement.clientHeight >= scrollElement.scrollHeight - threshold) {
            if (notificationsCount.total > notifications.length && !isNotificationsLoading) {
                const newPage = page + 1
                setPage(newPage)
            }
        }
    };
    return <Box sx={{ marginTop: "0.5em", width: "20em", height: "30em", display: "flex", flexDirection: "column", gap: "0.2em", overflow: "auto" }} ref={scrollRef} onScroll={handleScroll}>
        {todayNotifications?.length ? <Typography fontWeight={"bold"} >Today</Typography> : <></>}
        {todayNotifications.map((n, i) => <NotificationCard key={i} notification={n} userId={user?.user_id} notifications={notifications} setNotifications={setNotifications} setNotificationsCount={setNotificationsCount} />)}
        {yesterdayNotifications?.length ? <Typography fontWeight={"bold"}  >Yesterday</Typography> : <></>}
        {yesterdayNotifications.map((n, i) => <NotificationCard key={i} notification={n} userId={user?.user_id} notifications={notifications} setNotifications={setNotifications} setNotificationsCount={setNotificationsCount} />)}
        {lastWeekNotifications?.length ? <Typography fontWeight={"bold"} >Last Week</Typography> : <></>}
        {lastWeekNotifications.map((n, i) => <NotificationCard key={i} notification={n} userId={user?.user_id} notifications={notifications} setNotifications={setNotifications} setNotificationsCount={setNotificationsCount} />)}
        {lastMonthNotifications?.length ? <Typography fontWeight={"bold"} >Last Month</Typography> : <></>}
        {lastMonthNotifications.map((n, i) => <NotificationCard key={i} notification={n} userId={user?.user_id} notifications={notifications} setNotifications={setNotifications} setNotificationsCount={setNotificationsCount} />)}
    </Box>
}

function splitNotifications(notifications = []) {
    const todayEndDate = moment(new Date()).endOf("d").toISOString()
    const todayCutOffDate = addDays(todayEndDate, -1)
    const yesterdayCutOffDate = addDays(todayEndDate, -2)
    const lastWeekCutOffDate = addDays(todayEndDate, -7)
    let todayNotifications = []
    let yesterdayNotifications = []
    let lastWeekNotifications = []
    let lastMonthNotifications = []

    notifications?.forEach((n) => {
        if (n.created_at > todayCutOffDate) {
            todayNotifications = [...todayNotifications, n]
        } else if (n.created_at > yesterdayCutOffDate) {
            yesterdayNotifications = [...yesterdayNotifications, n]
        } else if (n.created_at > lastWeekCutOffDate) {
            lastWeekNotifications = [...lastWeekNotifications, n]
        } else {
            lastMonthNotifications = [...lastMonthNotifications, n]
        }
    })
    return [todayNotifications, yesterdayNotifications, lastWeekNotifications, lastMonthNotifications]
}

function NotificationCard(props) {
    const { notification, userId, showNotification, notifications, setNotifications, setNotificationsCount } = props
    const history = useHistory()

    function handleOnClick() {
        handleMarkNotificationAsRead(notification.id, userId, showNotification)
        if (!notification?.is_viewed) setNotificationsCount((notificationsCount) => { return { ...notificationsCount, unread: notificationsCount.unread - 1 } })
        setNotifications([...notifications.map((n) => n.id === notification.id ? { ...n, is_viewed: 1 } : n)])
        if (notification?.self_link) history.push(notification.self_link)
    }

    return <Button onClick={handleOnClick}
        sx={{ userSelect: "text", textTransform: "none", padding: "0.5em", borderRadius: "0.5em", backgroundColor: notification.is_viewed ? undefined : 'notification.background', display: "flex", flexDirection: "column", alignItems: "start", justifyContent: "start" }} >
        <Box sx={{ display: "flex", flexDirection: "row", gap: "0.5em" }} ><NotificationIcon notificationType={notification?.notification_type} /><Typography sx={{ textAlign: "start" }} variant="body1" >{notification?.title}</Typography></Box>
        <Typography textAlign="start" variant="body2" sx={{color: 'text.primary'}}>{notification?.description}</Typography>
        <Box sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "end" }} ><Typography variant="caption"  >{moment(notification?.created_at)?.format('MM/DD/YYYY - h:mm A')}</Typography></Box>
    </Button>
}

function getBroadcastTypeOfAUser(userLevel = 0) {
    if (userLevel <= 40) {
        return "Agent"
    } else if (userLevel <= 80) {
        return "Admin"
    } else {
        return "Super Admin"
    }
}