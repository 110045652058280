import { useContext, useEffect, useMemo, useState } from "react"
import { DashboardRoutes } from "../router/index"
import { Link, useHistory } from "react-router-dom"
import { UserContext } from '../contexts/User';
import { IconButton } from "@mui/material";
import HomeIcon from '@mui/icons-material/Home';
import { redirectToHomePage } from "../pages/other/Dialer";

function TopMenu() {
    const pathname = window.location.pathname;
    const localStorageData = useMemo(() => {
        return {
            media: localStorage.getItem("media") ?? "",
            channelName: localStorage.getItem("channel_name") ?? "",
            channelId: localStorage.getItem("channel_id") ?? "",
            callerId: localStorage.getItem("caller_id") ?? "",
            zipCode: localStorage.getItem("zip_code") ?? "",
            brandId: localStorage.getItem("brand_id") ?? "",
            jobId: localStorage.getItem("job_id") ?? "",
            subject: localStorage.getItem("subject") ?? "",
            dialerFrom: localStorage.getItem("dialer_from") ?? "",
        }
    }, [])
    const sessionStorageData = useMemo(() => {
        return {
            interactionGuid: sessionStorage.getItem("interaction_guid"),
        }
    }, [])
    const [level, setLevel] = useState(localStorage.getItem("user_level"))
    const { me } = useContext(UserContext)
    const history = useHistory()

    useEffect(() => {
        setTimeout(() => {
            let lv = localStorage.getItem("user_level")
            setLevel(lv)
            if (lv < 50) {
                if (window.location.pathname !== "/call-logs" && window.location.pathname !== "/wallboard" && window.location.pathname !== "/leads" && window.location.pathname !== "/campaigns" && window.location.pathname !== "/estimates" && window.location.pathname !== "/franchise" && window.location.pathname !== "/jobs" && window.location.pathname !== "/faqs") {
                    history.replace("/call-logs")
                }
            }
        }, 800);
    }, [])

    return (
        <div className={"top-menu"}>
            <div className={"top-menu-left"}>
                {getShouldNavigateToHome(localStorageData?.zipCode, localStorageData?.brandId) ?
                    <IconButton sx={{ paddingBlock: 0, color: "white" }} onClick={() => redirectToHomePage(history, localStorageData?.media, localStorageData?.brandId, localStorageData?.zipCode, localStorageData?.callerId, localStorageData?.channelId, localStorageData?.channelName, localStorageData?.jobId, localStorageData?.subject, localStorageData?.dialerFrom, sessionStorageData?.interactionGuid)}>
                        <HomeIcon />
                    </IconButton>
                    : <></>}
                {
                    DashboardRoutes.map((route, i) => (
                        route.topMenu ?
                            route.level <= level ?
                                <Link key={i} className={`top-menu-item   ${pathname.includes(route.path) ? "active" : ""}`} to={route.path} >{route.name}</Link> : "" : ""
                    ))
                }
                {(level >= 50) || (level < 50 && me?.eight_by_eight_agent_id !== "") ?
                    <Link style={{ marginRight: "0px" }} className={`top-menu-item  ${pathname.includes("wallboard") ? "active" : ""} `} to={"/wallboard"} >Wallboard</Link>
                    : ""}
            </div>
            <div className={"top-menu-right"}>
                {level >= 50 ?
                    <Link style={{ marginRight: "0px" }} className={`top-menu-item  ${pathname.includes("report") ? "active" : ""} `} to={"/reports"} >Reporting Dashboard</Link>
                    : ""}
            </div>
        </div>
    )
}


export default TopMenu

function getShouldNavigateToHome(zipCode, brandId) {
    return zipCode && brandId
}