
import { useState, useEffect, useContext } from "react";
import { withRouter, } from "react-router-dom"
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';

import moment from "moment";
import { Autocomplete, Dialog, Pagination } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

// table
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';

// service
import { createBrand, updateBrandById, getBrandEmailsById } from "../../../services/brandService"
import { confirmEmails } from "../../../services/emailsService"
import { ERROR_COMPONENTS, NOTIFICATION_TYPES, ROW_COUNT_PER_PAGE, SERVICE_TYPES } from "../../../constants/common";
import { isValidHttpUrl } from "../../../utils/validators";
import ErrorView from "../../../components/ErrorView";
import { useDebounce } from "../../../utils/UseDebounce";
import { getAllHandler, handleSearch } from "../../../state-services/common";
import LoadingButton from "../../../components/LoadingButton";
import { handleGetAllCampaigns } from "../../../state-services/eightByEight/campaigns";
import { handleGetQueues } from "../../../state-services/eightByEight/queues";
import EmailViewer from "./components/emailViewer";
import { CommonContext } from "../../../contexts/CommonContextProvider";
import BrandOwnerViewer from "./components/BrandOwnerViewer";
import { handleGetArrivalWindowByBrandId, handleLoadOwners, handleValidateBrandCodes } from "../../../state-services/brands";
import CustomTextField from "../../../components/CustomTextField";
import ImageBox from "../../../components/LogoBox/ImageBox";
import BrandJobTypes from "./components/BrandJobTypes";

function BrandDModal(props) {
    const { onClose, open, data, showNotification, handleRefreshData } = props;
    const handleClose = () => onClose()
    const [loading, setLoading] = useState(false)
    const [err, setErr] = useState("")
    const [urlErr1, setUrlErr1] = useState("")
    const [urlErr2, setUrlErr2] = useState("")
    const [urlErr3, setUrlErr3] = useState("")
    const [brandShortCodeErr, setBrandShortCodeErr] = useState("")
    const [arrivalWindowErr, setArrivalWindowErr] = useState("")
    const [emailValidationError, setEmailValidationError] = useState("")
    const [allCampaigns, setAllCampaigns] = useState([])
    const [isAllCampaignsLoading, setIsAllCampaignsLoading] = useState(false)
    const [isAllCampaignsLoadingError, setIsAllCampaignsLoadingError] = useState(false)
    const [selectedCampaign, setSelectedCampaign] = useState()
    const [allQueues, setAllQueues] = useState([])
    const [isAllQueuesLoading, setIsAllQueuesLoading] = useState(false)
    const [isAllQueuesLoadingError, setIsAllQueuesLoadingError] = useState(false)
    const [selectedQueue, setSelectedQueue] = useState()
    const [emails, setEmails] = useState([])
    const [brandOwners, setBrandOwners] = useState([])
    const [arrivalWindow, setArrivalWindow] = useState(60)
    const [brandShortCode, setBrandShortCode] = useState("")
    const [updateBrandJobTypes, setUpdateBrandJobTypes] = useState([])
    const MaximumDurationOfArrivalWindow = 1440

    useEffect(() => {
        if (data) setBrandShortCode(data?.brand_short_code)
        if (data) handleGetArrivalWindowByBrandId(data?.id, setArrivalWindow)
        setSelectedCampaign()
        setSelectedQueue()
    }, [open, data])

    useEffect(() => {
        if (data?.id && open) {
            handleLoadEmails(data.id, setEmails)
            handleLoadOwners(data.id, setBrandOwners)
        } else {
            setEmails([])
            setBrandOwners([])
        }
    }, [data?.id, open])

    useEffect(() => {
        if (open) {
            handleGetAllCampaigns(data?.eight_by_eight_lead_campaign_id, setAllCampaigns, setIsAllCampaignsLoading, setIsAllCampaignsLoadingError, setSelectedCampaign)
            handleGetQueues(data?.eight_by_eight_lead_follow_up_queue_id, setAllQueues, setIsAllQueuesLoading, setIsAllQueuesLoadingError, setSelectedQueue)
        }
    }, [open, data?.eight_by_eight_lead_campaign_id, data?.eight_by_eight_lead_follow_up_queue_id])

    const onSubmit = async (e) => {
        e.preventDefault()
        const emailInputFieldValue = document.getElementById("email-input-field")?.value
        if (emailInputFieldValue) {
            showNotification({ message: "Important : Please click on the + button to add the email or clear the email field", type: NOTIFICATION_TYPES.ERROR })
            return
        }
        let brand = {
            brand_id: parseInt(document.getElementById("brandId").value),
            brand_name: document.getElementById("brandName").value,
            brand_short_code: brandShortCode,
            brand_img: document.getElementById("brandImg").value,
            emails: prepareEmails(),
            brand_owners: prepareBrandOwners(),
            arrival_window: parseInt(arrivalWindow),
            redirect_url: document.getElementById("redirectUrl").value,
            redirect_url_service_titan: document.getElementById("redirectUrlSt").value,
            eight_by_eight_lead_campaign_id: selectedCampaign?.campaign_id,
            eight_by_eight_lead_follow_up_queue_id: selectedQueue?.queue_id,
            brand_job_types: updateBrandJobTypes
        }
        let isvalidUrls = false
        let isValidEmails = true
        isvalidUrls = urlValidation(brand)
        let isBrandCodeDuplicate = await handleValidateBrandCodes(brandShortCode, data?.id, setBrandShortCodeErr)
        if (isBrandCodeDuplicate) {
            return
        }
        if (arrivalWindow > MaximumDurationOfArrivalWindow) {
            setArrivalWindowErr("Arrival window should be less than or equal to 1440 minutes (24 hours)")
            return
        }
        if (emails?.length > 0) {
            isValidEmails = await emailValidation(emails)
        }
        if (isvalidUrls && isValidEmails) {
            if (!data) {
                setLoading(true)
                createBrand(brand).then(res => {
                    showNotification({ message: "Brand has been added successfully", type: NOTIFICATION_TYPES.SUCCESS })
                    handleRefreshData()
                    handleModelClose()
                }).catch(err => {
                    showNotification({ message: "Unable to add brand at this moment", type: NOTIFICATION_TYPES.ERROR })
                    setErr("Brand ID is already exist")
                }).finally(() => {
                    setLoading(false)
                    setUpdateBrandJobTypes([])
                })
            } else {
                setLoading(true)
                updateBrandById(data.id, brand).then(() => {
                    showNotification({ message: "Brand has been updated successfully", type: NOTIFICATION_TYPES.SUCCESS })
                    handleRefreshData()
                    handleModelClose()
                }).catch(err => {
                    showNotification({ message: "Unable to update brand at this moment", type: NOTIFICATION_TYPES.ERROR })
                }).finally(() => {
                    setLoading(false)
                    setUpdateBrandJobTypes([])
                })
            }
        }

    }

    async function emailValidation(currentEmils) {
        let isValidEmails = false
        let emailArr = []
        let statusArr = []
        currentEmils.forEach((item) => {
            emailArr.push(item.email)
        })
        let body = {
            emails: emailArr
        }
        let data = await confirmEmails(body)
        emails.forEach((item) => {
            let state = data.validation_results[item.email]
            statusArr.push(state)
            item.status = state
        })
        setEmails([...emails])
        if (statusArr.includes(false)) {
            showNotification({ message: "Please validate all email addresses", type: NOTIFICATION_TYPES.ERROR })
            isValidEmails = false
        } else {
            isValidEmails = true
        }
        return isValidEmails
    }

    const urlValidation = (brand) => {
        let urlInvalidMessage = 'Invalid URL'

        let checkVonigoUrl = isValidHttpUrl(brand.redirect_url)
        if (!checkVonigoUrl) { setUrlErr1(urlInvalidMessage) } else { setUrlErr1('') }

        let checkStUrl = isValidHttpUrl(brand.redirect_url_service_titan)
        if (!checkStUrl) { setUrlErr2(urlInvalidMessage) } else { setUrlErr2('') }

        let checkImageUrl = isValidHttpUrl(brand.brand_img)
        if (!checkImageUrl) { setUrlErr3(urlInvalidMessage) } else { setUrlErr3('') }

        if (checkVonigoUrl && checkStUrl && checkImageUrl) {
            return true

        } else {
            return false
        }
    }

    const prepareEmails = () => {
        let emailsArr = []
        emails?.forEach(item => {
            emailsArr.push({ "email": item.email })
        });
        return emailsArr
    }

    const prepareBrandOwners = () => {
        let brandOwnersArr = []
        brandOwners?.forEach(item => {
            brandOwnersArr.push({ "id": item.id, "owner_name": item.owner_name, "owner_email": item.owner_email })
        });
        return brandOwnersArr
    }

    const handleModelClose = () => {
        setErr('')
        setUrlErr1('')
        setUrlErr2('')
        setUrlErr3('')
        setArrivalWindowErr('')
        setEmailValidationError('')
        setBrandOwners([])
        setEmails([])
        setArrivalWindow(60)
        setBrandShortCode('')
        setBrandShortCodeErr('')
        setUpdateBrandJobTypes([])
        handleClose()
    }

    const handleArrvalWindowChange = (e) => {
        if (/^\d*$/.test(e.target.value)) {
            setArrivalWindow(e.target.value);
            setArrivalWindowErr('')
        }
    }

    const handleBrandShortCodeChange = (value) => {
        setBrandShortCodeErr('')
        setBrandShortCode(value);
    }

    return (
        <Dialog onChange={() => setErr('')} open={open} fullWidth maxWidth="md" className="dialog-zindex" >
            <div className={"dialog"} style={{ width: "suto" }} >
                <IconButton onClick={handleModelClose} style={{ position: "absolute", right: "10px", top: "10px" }} size={"small"} aria-label="delete">
                    < CloseIcon style={{ height: "15px", width: "15px" }} />
                </IconButton>
                <h3 className={"dialog-title"}>{data ? "Update Brand" : "Create a New Brand"} </h3>
                <form onSubmit={onSubmit} >
                    <CustomTextField error={err.length > 0 ? true : false} helperText={err} disabled={data ? true : false} required defaultValue={data?.brand_id} type={"text"} fullWidth id="brandId" label={"Brand Id"}
                        size={"small"}
                        margin="normal"
                        variant="outlined"
                        inputProps={{
                            pattern: "^[1-9][0-9]*$", // This pattern allows only numbers where the first digit is not zero
                            onChange: (e) => {
                                let value = e.target.value;
                                // Remove any non-numeric characters
                                value = value.replace(/[^0-9]/g, '');
                                // If the first character is 0, remove it
                                if (value.length > 0 && value[0] === '0') {
                                    value = value.slice(1);
                                }
                                e.target.value = value;
                            }
                        }}
                    />
                    <CustomTextField required defaultValue={data?.brand_name} type={"text"} fullWidth id="brandName" label={"Brand Name"} size={"small"} margin="normal" variant="outlined" />
                    <CustomTextField required defaultValue={brandShortCode} error={brandShortCodeErr.length > 0 ? true : false} helperText={brandShortCodeErr}
                        inputProps={{
                            onChange: (e) => {
                                e.target.value = e.target.value.replace(/[^0-9a-zA-Z]/g, '').toLowerCase();
                            }
                        }}
                        onChange={(e) => {
                            handleBrandShortCodeChange(e.target.value)
                        }}
                        type={"text"} fullWidth id="brandShortCode" label={"Brand Code"} size={"small"} margin="normal" variant="outlined" />
                    <EmailViewer emails={emails} setEmails={setEmails} emailErr={emailValidationError} setEmailValidationError={setEmailValidationError} />
                    <CustomTextField required error={urlErr1.length > 0 ? true : false} helperText={urlErr1} defaultValue={data?.redirect_url} type={"text"} fullWidth id="redirectUrl" label={"Redirect Url Vonigo"} size={"small"} margin="normal" variant="outlined" />
                    <CustomTextField required error={urlErr2.length > 0 ? true : false} helperText={urlErr2} defaultValue={data?.redirect_url_service_titan} type={"text"} fullWidth id="redirectUrlSt" label={"Redirect Url ServiceTitan"} size={"small"} margin="normal" variant="outlined" />
                    <CustomTextField required error={urlErr3.length > 0 ? true : false} helperText={urlErr3} defaultValue={data?.brand_img} type={"text"} fullWidth id="brandImg" label={"Brand Image URL"} size={"small"} margin="normal" variant="outlined" />
                    <CustomTextField required error={arrivalWindowErr.length > 0 ? true : false} helperText={arrivalWindowErr} value={arrivalWindow} onChange={handleArrvalWindowChange} type={"text"} fullWidth id="arrivalWindow" label={"Arrival Window(mins)"} size={"small"} margin="normal" variant="outlined" />
                    <Autocomplete
                        sx={{ marginBlock: "1em" }}
                        size="small"
                        options={allCampaigns}
                        loading={isAllCampaignsLoading}
                        noOptionsText={isAllCampaignsLoadingError ? "Temporary failure" : "No Campaigns Found"}
                        value={allCampaigns?.length > 0 && selectedCampaign ? selectedCampaign : null}
                        onChange={(e, c) => {
                            setSelectedCampaign(c)
                        }}
                        getOptionLabel={(c) => `${c?.campaign_name}`}
                        getOptionDisabled={(c) => c?.dynamic_campaign !== "Y"}
                        renderInput={(props) => <CustomTextField {...props} label={"8x8 Lead Follow-Up Campaign"} />}
                    />
                    <Autocomplete
                        sx={{ marginBlock: "1em" }}
                        size="small"
                        options={allQueues}
                        loading={isAllQueuesLoading}
                        noOptionsText={isAllQueuesLoadingError ? "Temporary failure" : "No Queues Found"}
                        value={allQueues?.length > 0 && selectedQueue ? selectedQueue : null}
                        onChange={(e, q) => {
                            setSelectedQueue(q)
                        }}
                        getOptionLabel={(q) => `${q?.queue_name}`}
                        renderInput={(props) => <CustomTextField {...props} label={"8x8 Lead Follow-Up Queue"} />}
                    />

                    <BrandJobTypes brandId={data?.id} setUpdateBrandJobTypes={setUpdateBrandJobTypes} updateBrandJobTypes={updateBrandJobTypes} />
                    <BrandOwnerViewer brandOwners={brandOwners} setBrandOwners={setBrandOwners}></BrandOwnerViewer>
                    <div style={{ display: "flex", justifyContent: "end", marginTop: "20px" }}>
                        <Button size={"small"} variant="contained" onClick={handleModelClose} className={"btn-secondary"} style={{ marginRight: "10px" }} >Cancel</Button>
                        <LoadingButton data-test="brand_save_button" type={"submit"} size={"small"} variant="contained" loading={loading} className={"btn-primary"} >
                            {data ? "Update" : "Save"}
                        </LoadingButton>
                    </div>
                </form>
            </div>
        </Dialog>
    );
}

async function handleLoadEmails(id, setEmails) {
    try {
        const emailData = await getBrandEmailsById(id);
        let emailsArr = [];
        let formattedEmailsArr = []
        if (Array.isArray(emailData)) {
            emailsArr = emailData.map(item => item.email)
            const body = { emails: emailsArr };
            const data = await confirmEmails(body);
            formattedEmailsArr = emailsArr.map(item => ({
                email: item,
                status: data.validation_results[item]
            }));
        }
        setEmails(formattedEmailsArr);
    } catch (error) {
        setEmails([]);
    }
};

function Brands(props) {
    const [searchValue, setSearchValue] = useState("")
    const [dialogOpen, setDialogOpen] = useState(false)
    const [brand, setBrand] = useState(null)
    const [records, setRecords] = useState([]);
    const [loading, setLoading] = useState(true)
    const { showNotification } = useContext(CommonContext)
    const [refresh, setRefresh] = useState(false)
    const [brandCount, setbrandCount] = useState({ all: 0, active: 0, deleted: 0 })
    const [currentpage, setCurrentPage] = useState(1)
    const debouncedSearchTerm = useDebounce(searchValue, 1000);
    const countPerPage = ROW_COUNT_PER_PAGE

    useEffect(() => {
        if (searchValue?.length > 0) {
            handleSearch(SERVICE_TYPES.Brands, debouncedSearchTerm, setLoading, setRecords, showNotification, setbrandCount, currentpage, countPerPage)
        }
    }, [debouncedSearchTerm, currentpage, refresh, countPerPage]); // eslint-disable-line

    useEffect(() => {
        if (searchValue === "") {
            getAllHandler(SERVICE_TYPES.Brands, currentpage, countPerPage, setLoading, setbrandCount, setRecords)
        }
    }, [refresh, currentpage, countPerPage, searchValue]);

    const onPageChangeHandler = (page) => {
        setCurrentPage(page)
        setSearchValue('')
    }

    const handleRefreshData = () => {
        setRefresh(!refresh)
    }

    const handleClearSearchField = () => {
        setCurrentPage(1)
        setSearchValue('')
        handleRefreshData()
    }

    const handleDialogOpen = (data) => {
        setBrand(data)
        setDialogOpen(true)
    }

    const handleDialogClose = () => {
        setDialogOpen(false)
        setBrand(null)
    }

    const handleSetSearchValue = (e) => {
        setCurrentPage(1)
        let keyWord = e.target.value.replace(/^\s+/g, '')
        if (keyWord !== '') {
            setSearchValue(keyWord)
        } else {
            setSearchValue('')
            handleRefreshData()
        }
    }

    const handleRowNumber = (i) => {
        if (currentpage === 1) {
            return i + 1
        } else {
            return (currentpage - 1) * countPerPage + i + 1
        }
    }

    return (
        <Box className={"dashboard"} sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <div style={{ padding: "15px", borderRadius: "7px" }} className={"bg-shadow bg-white"}>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "15px" }}>
                            <div >
                                <h2 style={{ margin: "0" }}>Brands</h2>
                                <div style={{ display: "flex", marginTop: "15px" }}>
                                    <small className={"text-color"}><strong>{brandCount.all} Brands</strong>  </small>
                                </div>
                            </div>
                            <div style={{ display: "flex", position: 'relative' }}>
                                <CustomTextField value={searchValue} onChange={handleSetSearchValue} id="outlined-basic" label={"Search"} size={"small"} style={{ width: "200px", marginRight: "15px" }} variant="outlined" />
                                {(searchValue?.length > 0 && searchValue !== " ") &&
                                    <IconButton
                                        onClick={handleClearSearchField}
                                        style={{ position: "absolute", left: "170px", top: "7.5px" }}
                                        size={"small"}
                                        aria-label="delete"
                                    >
                                        <CloseIcon style={{ height: "15px", width: "15px" }} />
                                    </IconButton>
                                }
                                <IconButton onClick={() => handleDialogOpen(null)} className={"icon-btn"} aria-label="delete">
                                    <AddIcon />
                                </IconButton>
                            </div>
                        </div>
                        <TableContainer style={{ height: "70vh" }}>
                            <Table aria-label="simple table" stickyHeader>
                                <TableHead >
                                    <TableRow>
                                        <TableCell>#</TableCell>
                                        <TableCell>Brand Name</TableCell>
                                        <TableCell>Brand Id</TableCell>
                                        <TableCell>Brand Image</TableCell>
                                        <TableCell align="center">Updated Date</TableCell>
                                        <TableCell align="center">Edit</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {loading ?
                                        <TableRow>
                                            <TableCell colSpan={6} align="center" style={{ border: "none", height: "300px", color: "grey" }} >
                                                <CircularProgress size={30} color="inherit" />
                                            </TableCell>
                                        </TableRow>
                                        :
                                        <>
                                            {records?.length === 0 ?
                                                <TableRow>
                                                    <TableCell colSpan={11} align="center" style={{ border: "none", height: "300px", color: "grey" }}>
                                                        <ErrorView type={ERROR_COMPONENTS.NOT_FOUND} title="No Brands Found" />
                                                    </TableCell>
                                                </TableRow>
                                                :
                                                <>
                                                    {records?.map((brand, i) => (
                                                        <TableRow key={i} >
                                                            <TableCell> {handleRowNumber(i)}</TableCell>
                                                            <TableCell>{brand.brand_name}</TableCell>
                                                            <TableCell>{brand.brand_id}</TableCell>
                                                            <TableCell><ImageBox alt={brand.brand_name} src={brand.brand_img} /></TableCell>
                                                            <TableCell align={"center"}>{moment(brand.last_updated_at).format("MM/ DD/ YYYY")}</TableCell>
                                                            <TableCell align={"center"}><Button onClick={() => handleDialogOpen(brand)} size={"small"} variant="contained" className={"btn-secondary"} >Edit</Button> </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </>
                                            }
                                        </>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Pagination page={currentpage} onChange={(e, v) => onPageChangeHandler(v)} className="pagination-bar" count={Math.ceil(brandCount.all / countPerPage)} color="standard" />
                        <BrandDModal onClose={handleDialogClose} open={dialogOpen} data={brand} showNotification={showNotification} handleRefreshData={handleRefreshData} />
                    </div>
                </Grid>
            </Grid>
        </Box>
    )
}

export default withRouter(Brands)