import { ALL_FRANCHISE, INITIAL_LEAD_STATISTICS } from "../constants/serviceTitan";
import { addAppointmentToJob, getServiceTitanTechnicians, getServiceTitanAppointments, rescheduleAppointment, createServiceTitanAppointmentAssignments, getServiceTitanTechnicianAvailabiliity, getACustomerContacts, getLocationsOfACustomer, getTagTypes, getJobTypes, getBusinessUnits, getCampaigns, getFranchiseCustomers, getCustomerById, getLocationById, getServiceTitanFranchisesByBrandId, getCallReasons, getJobTypeById, getBusinessUnitById, getEmployeeById, getCampaignById, getLeads, getServiceTitanJobs, updateServiceTitanJob, getServiceTitanJobDraftById, getServiceTItanDraftJobs, deleteServiceTitanJobDraft, getServiceTitanAppointmentAssignments, getServiceTitanAvailability, getServiceTitaLeadById, getServiceTitanLeadFollowUpHistory, getNonDeletedFranchisesByBrandId, serviceTitanAppointmentUnassignment, getServiceTitanJobById, getZones, getServiceTitanJobDetails, getServiceTitaLeadByServiceTitanLeadId } from "../services/serviceTitan";
import { isFranchiseCredentialsExist, getMatchingFranchiseByFranchiseIdOrTenantId, getMatchingCallReasonByCallReasonId, getIsSTCredentialsAvailable } from "../utils/miscellaneous"
import { isValidPhoneNumber } from "../utils/validators";
import { CRMS, NOTIFICATION_TYPES } from "../constants/common";
import { getServiceTitanEstimatesByJobId } from "../services/serviceTitanEstimates/serviceTitanEstimates";

const ERROR_MISSING_CREDIENTIALS = "Missing Credentials"
const CALL_CENTER_JOB_TYPES_PATTERN = /^(?:(?:[0-9]+\.)|\*)?.*?\((CCC)\)?$/
const NEW_JOB_TYPES_PATTERN = /^\d+\..*$/


export function handleGetCustomerContacts(franchiseId, selectedCustomerId, setSelectedCustomerContacts, setIsLoading = (isLoading) => { }, setIsError = (isError) => { }, backoffConfig = { backoffDelay: 250, backoffGrowth: 2, maxTries: 5, loopCondition: true, currentTries: 0 }) {
    setSelectedCustomerContacts([]);
    setIsError(false)
    setIsLoading(true)
    getACustomerContacts(franchiseId, selectedCustomerId).then((customerContactResponse) => {
        if (Array.isArray(customerContactResponse.data)) {
            setSelectedCustomerContacts(customerContactResponse.data);
        }
    }).catch(() => {
        setIsError(true)
        retry(() => handleGetCustomerContacts(franchiseId, selectedCustomerId, setSelectedCustomerContacts, setIsLoading, setIsError, backoffConfig), backoffConfig)
    }).finally(() => {
        setIsLoading(false)
    })
    return getLoopCancelCallback(backoffConfig)
}

export function handleGetSelectedCustomerLocations(franchiseId, selectedCustomerId, setSelectedCustomerLocations, setSelectedCustomerSelectedLocation = (location) => { }, setIsSelectedCustomerLocationsLoading, setIsError = () => { }, backoffConfig = { backoffDelay: 250, backoffGrowth: 2, maxTries: 5, loopCondition: true, currentTries: 0 }) {
    setIsSelectedCustomerLocationsLoading(true);
    setIsError(false)
    setSelectedCustomerLocations([]);
    setSelectedCustomerSelectedLocation(undefined)
    getLocationsOfACustomer(franchiseId, selectedCustomerId).then((customerLocationsResponse) => {
        if (Array.isArray(customerLocationsResponse.data)) {
            setSelectedCustomerLocations(customerLocationsResponse.data);
        }
    }).catch(() => {
        retry(() => handleGetSelectedCustomerLocations(franchiseId, selectedCustomerId, setSelectedCustomerLocations, setSelectedCustomerSelectedLocation, setIsSelectedCustomerLocationsLoading, setIsError, backoffConfig), backoffConfig)
        setIsError(true)
    }).finally(() => {
        setIsSelectedCustomerLocationsLoading(false);
    });
    return getLoopCancelCallback(backoffConfig)
}

export function handleGetTagTypes(franchise, selectedTagTypeIds, setTagTypes, setSelectedTagTypes, setIsTagTypesLoading, backoffConfig = { backoffDelay: 250, backoffGrowth: 2, maxTries: 5, loopCondition: true, currentTries: 0 }) {
    setIsTagTypesLoading(true);
    getTagTypes(franchise.service_titan_tenant_id, franchise.service_titan_client_id, franchise.service_titan_client_secret).then((tagTypeResponse) => {
        if (Array.isArray(tagTypeResponse.data)) {
            setTagTypes(tagTypeResponse.data.sort((a, b) => a.name.localeCompare(b.name)));
            if (Array.isArray(selectedTagTypeIds)) {
                setSelectedTagTypes(getSelectedTagTypesArray(selectedTagTypeIds, tagTypeResponse.data));
            }
        }
    }).catch(() => {
        retry(() => handleGetTagTypes(franchise, selectedTagTypeIds, setTagTypes, setSelectedTagTypes, setIsTagTypesLoading, backoffConfig), backoffConfig);
    }).finally(() => {
        setIsTagTypesLoading(false)
    });
    return getLoopCancelCallback(backoffConfig)
}

export function handleGetZones(franchiseId, setZones, backoffConfig = { backoffDelay: 250, backoffGrowth: 2, maxTries: 5, loopCondition: true, currentTries: 0 }) {
    getZones(franchiseId).then((zoneResponse) => {
        if (Array.isArray(zoneResponse.data)) {
            setZones(zoneResponse.data?.filter((zone) => zone?.active));
        }
    }).catch(() => {
        retry(() => handleGetZones(franchiseId, setZones, backoffConfig), backoffConfig);
    }).finally(() => {
    });
    return getLoopCancelCallback(backoffConfig)
}

function filterJobTypes(jobTypes, shouldFilterFor360painting) {
    if (shouldFilterFor360painting) {
        return jobTypes?.filter(jobType => NEW_JOB_TYPES_PATTERN.test(jobType?.name))
    } else {
        return jobTypes?.filter(jobType => CALL_CENTER_JOB_TYPES_PATTERN.test(jobType?.name))
    }
}

export function handleGetJobTypes(franchiseId, selectedJobTypeId, isContactCenterJobTypesOnly, shouldFilterFor360painting, setJobTypes, setJobType, setIsJobTypesLoading, setIsJobTypesLoadingError, backoffConfig = { backoffDelay: 250, backoffGrowth: 2, maxTries: 5, loopCondition: true, currentTries: 0 }) {
    setIsJobTypesLoading(true);
    setIsJobTypesLoadingError?.(false)
    getJobTypes(franchiseId).then((jobTypesResponse) => {
        if (Array.isArray(jobTypesResponse.data)) {
            let filterdJobTypes = jobTypesResponse.data
            if (isContactCenterJobTypesOnly) {
                filterdJobTypes = filterJobTypes(jobTypesResponse.data, shouldFilterFor360painting)
            }
            setJobTypes(filterdJobTypes?.sort((a, b) => a.name.localeCompare(b.name)));
            if (selectedJobTypeId) {
                setJobType(getMatchingJobTypeByJobTypeId(selectedJobTypeId, jobTypesResponse.data));
            }
        }
    }).catch(() => {
        if (shouldRetry(backoffConfig)) {
            retry(() => handleGetJobTypes(franchiseId, selectedJobTypeId, isContactCenterJobTypesOnly, shouldFilterFor360painting, setJobTypes, setJobType, setIsJobTypesLoading, setIsJobTypesLoadingError, backoffConfig), backoffConfig)
        } else {
            setIsJobTypesLoadingError?.(true)
        }
    }).finally(() => {
        setIsJobTypesLoading(false)
    });
    return getLoopCancelCallback(backoffConfig)
}

export async function handleGetAllJobTypes(franchiseId, setJobTypes) {
    try {
        let jobTypesResponse = []
        jobTypesResponse = await getJobTypes(franchiseId)
        if (Array.isArray(jobTypesResponse.data)) {
            setJobTypes(jobTypesResponse.data)
        } else {
            setJobTypes([])
        }
    } catch (error) {
        setJobTypes([])
    }
}

export function handleGetBusinessUnits(franchiseId, selectedBusinessUnitId, setBusinessUnits, setBusinessUnit, setIsBusinessUnitsLoading, setIsBusinessUnitsLoadingError, backoffConfig = { backoffDelay: 250, backoffGrowth: 2, maxTries: 5, loopCondition: true, currentTries: 0 }) {
    setIsBusinessUnitsLoading?.(true);
    setIsBusinessUnitsLoadingError?.(false)
    getBusinessUnits(franchiseId).then((businessUnitsResponse) => {
        if (Array.isArray(businessUnitsResponse.data)) {
            let res = businessUnitsResponse.data
            setBusinessUnits(res?.sort((a, b) => a.name.localeCompare(b.name) ?? 0));
            if (selectedBusinessUnitId) {
                setBusinessUnit(getMatchingBusinessUnitByBusinessUnitId(selectedBusinessUnitId, businessUnitsResponse.data));
            }
        }
    }).catch(() => {
        if (shouldRetry(backoffConfig)) {
            retry(() => handleGetBusinessUnits(franchiseId, selectedBusinessUnitId, setBusinessUnits, setBusinessUnit, setIsBusinessUnitsLoading, setIsBusinessUnitsLoadingError, backoffConfig), backoffConfig)
        } else {
            setIsBusinessUnitsLoadingError?.(true)
        }
    }).finally(() => {
        setIsBusinessUnitsLoading(false)
    });
    return () => {
        backoffConfig.loopCondition = false;
    }
}

export function handleGetCampaigns(franchiseId, selectedCampaignId, setCampaigns, setCampaign, setIsCampaignsLoading, backoffConfig = { backoffDelay: 250, backoffGrowth: 2, maxTries: 5, loopCondition: true, currentTries: 0 }) {
    setIsCampaignsLoading(true);
    getCampaigns(franchiseId).then((campaignsResponse) => {
        if (Array.isArray(campaignsResponse.data)) {
            setCampaigns(campaignsResponse.data.sort((a, b) => a.name.localeCompare(b.name)));
            if (selectedCampaignId) {
                setCampaign(getMatchingCampaignByCampaignId(selectedCampaignId, campaignsResponse.data));
            }
        }
    }).catch(() => {
        retry(() => handleGetCampaigns(franchiseId, selectedCampaignId, setCampaigns, setCampaign, setIsCampaignsLoading, backoffConfig), backoffConfig)
    }).finally(() => {
        setIsCampaignsLoading(false)
    });
    return getLoopCancelCallback(backoffConfig)
}

export function handleGetCallReasons(franchise, selectedCallReasonId, setCallReasons, setCallReason, setIsCallReasonsLoading, backoffConfig = { backoffDelay: 250, backoffGrowth: 2, maxTries: 5, loopCondition: true, currentTries: 0 }) {
    if (franchise) {
        setIsCallReasonsLoading(true);
        getCallReasons(franchise.service_titan_tenant_id, franchise.service_titan_client_id, franchise.service_titan_client_secret).then((callReasonsResponse) => {
            if (Array.isArray(callReasonsResponse.data)) {
                setCallReasons(callReasonsResponse.data.filter((callReason) => callReason.isLead));
                if (selectedCallReasonId) {
                    setCallReason(getMatchingCallReasonByCallReasonId(selectedCallReasonId, callReasonsResponse.data));
                }
            }
        }).catch(() => {
            retry(() => handleGetCallReasons(franchise, selectedCallReasonId, setCallReasons, setCallReason, setIsCallReasonsLoading, backoffConfig), backoffConfig)
        }).finally(() => {
            setIsCallReasonsLoading(false)
        });
        return getLoopCancelCallback(backoffConfig)
    }
}

export async function handleAddAppointment(franchise, appointment, setIsCreateAppointmentLoading, backoffConfig = { backoffDelay: 250, backoffGrowth: 2, maxTries: 5, loopCondition: true, currentTries: 0 }) {
    try {
        if (!(isFranchiseCredentialsExist(franchise))) return
        setIsCreateAppointmentLoading(true);
        const response = await addAppointmentToJob(franchise.service_titan_tenant_id, franchise.service_titan_client_id, franchise.service_titan_client_secret, appointment)
        return response
    } catch (err) {
        if (shouldRetry(backoffConfig)) {
            retry(() => handleAddAppointment(franchise, appointment, setIsCreateAppointmentLoading, backoffConfig), backoffConfig)
        }
        throw err
    } finally {
        setIsCreateAppointmentLoading(false);
    }
}

export function handleGetTechnicians(franchise = {}, ids = [], setTechnicians, setTechniciansLoading, setIsTechniciansError = (isError = false) => { }, setTechniciansError = (error = "") => { }, backoffConfig = { backoffDelay: 250, backoffGrowth: 2, maxTries: 5, loopCondition: true, currentTries: 0 }) {
    if (!(isFranchiseCredentialsExist(franchise))) {
        return setFranchiseCredentialsError(setIsTechniciansError, setTechniciansError)
    }
    setTechnicians([])
    setTechniciansLoading(true);
    setIsTechniciansError(false);
    setTechniciansError?.("")
    getServiceTitanTechnicians(franchise?.service_titan_tenant_id, franchise?.service_titan_client_id, franchise?.service_titan_client_secret, ids).then((techniciansResponse) => {
        if (Array.isArray(techniciansResponse?.data)) {
            setTechnicians(techniciansResponse?.data)
        }
    }).catch((err) => {
        if (shouldRetry(backoffConfig)) {
            retry(() => handleGetTechnicians(franchise, ids, setTechnicians, setTechniciansLoading, setIsTechniciansError, setTechniciansError, backoffConfig), backoffConfig)
        } else {
            setIsTechniciansError(true)
            setTechniciansError("Something went wrong")
        }
    }).finally(() => {
        setTechniciansLoading(false);
    })
    return getLoopCancelCallback(backoffConfig)
}

export async function handleGetappointments(franchise = {}, jobId, customerId, setAppointments = () => { }, setIsAppointmentsLoading = (isLoading = false) => { }, setIsAppointmentLoadingError = (isError = false) => { }, setAppointmentCount) {
    try {
        if (!isFranchiseCredentialsExist(franchise)) {
            return setFranchiseCredentialsError(setIsAppointmentLoadingError)
        }
        setIsAppointmentsLoading(true)
        setIsAppointmentLoadingError(false)
        const appointmentsResponse = await getServiceTitanAppointments(franchise?.id, jobId, customerId)
        if (Array.isArray(appointmentsResponse.data)) {
            appointmentsResponse.data.sort((a, b) => {
                if (a.start < b.start) return -1
                if (a.start > b.start) return 1
                return 0
            })
            setAppointments(appointmentsResponse.data)
            // setAppointmentCount?.(appointmentsResponse?.data?.length ?? 0)
        }
    } catch (err) {
        setIsAppointmentLoadingError(true)
    } finally {
        setIsAppointmentsLoading(false)
    }
}

export async function handleGetEstimatesByJobId(franchise = {}, jobId, setEstimates = () => { }, setIsEstimatesLoading = (isLoading = false) => { }, setIsEstimatesLoadingError = (isError = false) => { }) {
    try {
        if (!isFranchiseCredentialsExist(franchise)) {
            return setFranchiseCredentialsError(setIsEstimatesLoadingError)
        }
        setIsEstimatesLoading(true)
        setIsEstimatesLoadingError(false)
        const estimatesResponse = await getServiceTitanEstimatesByJobId(franchise?.id, jobId)
        if (Array.isArray(estimatesResponse?.data)) {
            setEstimates(estimatesResponse?.data)
        }
    } catch (err) {
        setIsEstimatesLoadingError(true)
    } finally {
        setIsEstimatesLoading(false)
    }
}

export async function handleRescheduleAppointment(franchise = {}, appointmentId, appointmentRecheduleRequest, backoffConfig = { backoffDelay: 250, backoffGrowth: 2, maxTries: 5, loopCondition: true, currentTries: 0 }) {
    try {
        if (!isFranchiseCredentialsExist(franchise)) throw ERROR_MISSING_CREDIENTIALS
        const rescheduleResponse = await rescheduleAppointment(franchise?.service_titan_tenant_id, franchise?.service_titan_client_id, franchise?.service_titan_client_secret, appointmentId, appointmentRecheduleRequest)
        return rescheduleResponse
    } catch (err) {
        if (shouldRetry(backoffConfig) && err !== ERROR_MISSING_CREDIENTIALS) {
            retry(() => handleRescheduleAppointment(franchise, appointmentId, appointmentRecheduleRequest, backoffConfig), backoffConfig)
        }
    }
}

export async function handleAppointmentAssignment(franchise = {}, appointmentAssignementRequest, backoffConfig = { backoffDelay: 250, backoffGrowth: 2, maxTries: 5, loopCondition: true, currentTries: 0 }) {
    try {
        if (!isFranchiseCredentialsExist(franchise)) throw ERROR_MISSING_CREDIENTIALS
        const response = await createServiceTitanAppointmentAssignments(franchise?.service_titan_tenant_id, franchise?.service_titan_client_id, franchise?.service_titan_client_secret, appointmentAssignementRequest)
        return response
    } catch (err) {
        if (shouldRetry(backoffConfig) && err !== ERROR_MISSING_CREDIENTIALS) {
            retry(() => handleAppointmentAssignment(franchise, appointmentAssignementRequest, backoffConfig), backoffConfig)
        }
    }
}

export async function handleAppointmentUnassignment(franchise = {}, appointmentUnassignementRequest, backoffConfig = { backoffDelay: 250, backoffGrowth: 2, maxTries: 5, loopCondition: true, currentTries: 0 }) {
    try {
        if (!isFranchiseCredentialsExist(franchise)) throw ERROR_MISSING_CREDIENTIALS
        const response = await serviceTitanAppointmentUnassignment(franchise?.service_titan_tenant_id, franchise?.service_titan_client_id, franchise?.service_titan_client_secret, appointmentUnassignementRequest)
        return response
    } catch (err) {
        if (shouldRetry(backoffConfig) && err !== ERROR_MISSING_CREDIENTIALS) {
            retry(() => handleAppointmentUnassignment(franchise, appointmentUnassignementRequest, backoffConfig), backoffConfig)
        }
    }
}

export function handleGetTechnicianAvailability(franchise, appointmentStart, appointmentEnd, setTechnicians = (technicians = []) => { }, setIsLoading = (isLoading = false) => { }, setIsError = (isError = false) => { }, setError = (error = "") => { }, shouldClearTechnicians = true, backoffConfig = { backoffDelay: 250, backoffGrowth: 2, maxTries: 5, loopCondition: true, currentTries: 0 }) {
    if (franchise && appointmentStart && appointmentEnd) {
        if (!isFranchiseCredentialsExist(franchise)) {
            return setFranchiseCredentialsError(setIsError, setError)
        }
        setIsError(false);
        setIsLoading(true)
        if (shouldClearTechnicians) setTechnicians([]);
        getServiceTitanTechnicianAvailabiliity(franchise?.service_titan_tenant_id, franchise?.service_titan_client_id, franchise?.service_titan_client_secret, appointmentStart, appointmentEnd).then((availabilityResponse) => {
            if (Array.isArray(availabilityResponse?.availableTechnicians)) {
                setTechnicians(availabilityResponse?.availableTechnicians)
            }
        }).catch(() => {
            if (shouldRetry(backoffConfig)) {
                retry(() => handleGetTechnicianAvailability(franchise, appointmentStart, appointmentEnd, setTechnicians, setIsLoading, setIsError, setError, shouldClearTechnicians, backoffConfig), backoffConfig)
            } else {
                setIsError(true);
                setError("Something went wrong")
            }
        }).finally(() => {
            setIsLoading(false)
        })
    }
    return getLoopCancelCallback(backoffConfig)
}


export function handleSearchCustomer(franchise, setSelectedCustomer, setFranchiseSearchCustomers, setSelectedCustomerSelectedLocation, setSelectedCustomerLocations, setSelectedCustomerContacts, setIsSearchResultCutomersLoading, customerPhoneNumber = "", setCustomerPhoneNumber, customerZipCode = "", customerName = "", backoffConfig = { backoffDelay: 250, backoffGrowth: 2, maxTries: 5, loopCondition: true, currentTries: 0 }) {
    if (customerPhoneNumber || customerName) {
        let temporyFranchiseSearchCustomers = []
        if (franchise) {
            setSelectedCustomer(undefined);
            setFranchiseSearchCustomers([]);
            setSelectedCustomerSelectedLocation(undefined);
            setSelectedCustomerLocations([]);
            setSelectedCustomerContacts([]);
            let customerSearchPhoneNumber = customerPhoneNumber
            if (!isValidPhoneNumber(customerPhoneNumber)) {
                customerSearchPhoneNumber = ""
                setCustomerPhoneNumber("")
            }
            setIsSearchResultCutomersLoading(true)
            getCustomersOfAFranchise(franchise?.id, customerSearchPhoneNumber, customerZipCode, undefined, undefined, temporyFranchiseSearchCustomers, customerName).then(() => {
                setFranchiseSearchCustomers(temporyFranchiseSearchCustomers)
            }).catch(() => {
                retry(() => handleSearchCustomer(franchise, setSelectedCustomer, setFranchiseSearchCustomers, setSelectedCustomerSelectedLocation, setSelectedCustomerLocations, setSelectedCustomerContacts, setIsSearchResultCutomersLoading, customerPhoneNumber, setCustomerPhoneNumber, customerZipCode, backoffConfig), backoffConfig)
            }).finally(() => {
                setIsSearchResultCutomersLoading(false)
            })
        }
    }
    return getLoopCancelCallback(backoffConfig)
}

export function handleGetLocationById(franchise, locationId, setSelectedCustomerSelectedLocation, backoffConfig = { backoffDelay: 250, backoffGrowth: 2, maxTries: 5, loopCondition: true, currentTries: 0 }) {
    if (franchise) {
        getLocationById(franchise.service_titan_tenant_id, franchise.service_titan_client_id, franchise.service_titan_client_secret, locationId).then((locationResponse) => {
            setSelectedCustomerSelectedLocation(locationResponse);
        }).catch(() => {
            retry(() => handleGetLocationById(franchise, locationId, setSelectedCustomerSelectedLocation, backoffConfig), backoffConfig)
        });
    }
    return getLoopCancelCallback(backoffConfig)
}

export function handleGetCustomerById(franchise, customerId, setSelectedCustomer, setCustomers, backoffConfig = { backoffDelay: 250, backoffGrowth: 2, maxTries: 5, loopCondition: true, currentTries: 0 }) {
    if (franchise) {
        getCustomerById(franchise.service_titan_tenant_id, franchise.service_titan_client_id, franchise.service_titan_client_secret, customerId).then((customerResponse) => {
            setCustomers?.([customerResponse])
            setSelectedCustomer(customerResponse);
        }).catch(() => {
            retry(() => handleGetCustomerById(franchise, customerId, setSelectedCustomer, setCustomers, backoffConfig), backoffConfig)
        });
    }
    return getLoopCancelCallback(backoffConfig)
}

export function handleGetServiceTitanFranchisesByBrandId(brand, selectedFranchiseId, selectedTenantId, setIsFranchisesLoading, setFranchises, setFranchise, setFranchiseSearchCustomers, setSelectedCustomer, setSelectedCustomerLocations, setSelectedCustomerSelectedLocation, setSelectedCustomerContacts, setIsEncyptionFailed = () => { }, backoffConfig = { backoffDelay: 250, backoffGrowth: 2, maxTries: 5, loopCondition: true, currentTries: 0 }) {
    if (brand) {
        resetBrandDependantData(setIsEncyptionFailed, setFranchises, setFranchise, setFranchiseSearchCustomers, setSelectedCustomer, setSelectedCustomerLocations, setSelectedCustomerSelectedLocation, setSelectedCustomerContacts);
        setIsFranchisesLoading(true);
        getServiceTitanFranchisesByBrandId(brand.brand_id).then((franchises) => {
            if (Array.isArray(franchises)) {
                const [uniqueFranchises, isEncryptionFailed] = getUniqueTenantFranchises(franchises)
                setFranchises(uniqueFranchises)
                setIsEncyptionFailed(isEncryptionFailed)
                setFranchise((getMatchingFranchiseByFranchiseIdOrTenantId(selectedFranchiseId, selectedTenantId, uniqueFranchises)))
            }
        }).catch(() => {
            retry(() => handleGetServiceTitanFranchisesByBrandId(brand, selectedFranchiseId, selectedTenantId, setIsFranchisesLoading, setFranchises, setFranchise, setFranchiseSearchCustomers, setSelectedCustomer, setSelectedCustomerLocations, setSelectedCustomerSelectedLocation, setSelectedCustomerContacts, setIsEncyptionFailed, backoffConfig), backoffConfig)
        }).finally(() => {
            setIsFranchisesLoading(false);
        });
    }
    return getLoopCancelCallback(backoffConfig)
}

export function handleGetJobTypeById(franchise, jobTypeId, setJobType, backoffConfig = { backoffDelay: 250, backoffGrowth: 2, maxTries: 5, loopCondition: true, currentTries: 0 }) {
    if (franchise) {
        getJobTypeById(franchise.service_titan_tenant_id, franchise.service_titan_client_id, franchise.service_titan_client_secret, jobTypeId).then((jobTypeResponse) => {
            setJobType(jobTypeResponse);
        }).catch(() => {
            retry(() => handleGetJobTypeById(franchise, jobTypeId, setJobType, backoffConfig), backoffConfig)
        });
    }
    return getLoopCancelCallback(backoffConfig)
}

export function resetBrandDependantData(setIsEncyptionFailed, setFranchises, setFranchise, setFranchiseSearchCustomers, setSelectedCustomer, setSelectedCustomerLocations, setSelectedCustomerSelectedLocation, setSelectedCustomerContacts, setCampaigns, setCampaign, setBusinessUnits, setBusinessUnit, setJobTypes, setJobType, setSelectedTagTypes, setTagTypes) {
    setFranchises([]);
    setIsEncyptionFailed(false)
    setFranchise(undefined);
    resetFranchiseDependantData(setFranchiseSearchCustomers, setSelectedCustomer, setSelectedCustomerLocations, setSelectedCustomerSelectedLocation, setSelectedCustomerContacts, setCampaigns, setCampaign, setBusinessUnits, setBusinessUnit, setJobTypes, setJobType, setSelectedTagTypes, setTagTypes);
}

export function resetJobDetailsData(setCampaigns, setSummary, setCampaign, setBusinessUnits, setBusinessUnit, setJobTypes, setJobType, setSelectedTagTypes, setTagTypes) {
    setSummary?.('');
    setCampaigns?.([]);
    setCampaign?.(undefined);
    setBusinessUnits?.([]);
    setBusinessUnit?.(undefined);
    setJobTypes?.([]);
    setJobType?.(undefined);
    setSelectedTagTypes?.([]);
    setTagTypes?.([]);
}

export function resetCustomerData(setFranchiseSearchCustomers, setSelectedCustomer) {
    setFranchiseSearchCustomers?.([]);
    setSelectedCustomer?.(undefined);
}

export function resetCustomerDependantData(setSelectedCustomerLocations, setSelectedCustomerSelectedLocation, setSelectedCustomerContacts) {
    setSelectedCustomerLocations?.([]);
    setSelectedCustomerSelectedLocation?.(undefined);
    setSelectedCustomerContacts?.([]);
}

export function handleGetBusinessUnit(franchise, businessUnitId, setBusinessUnit, backoffConfig = { backoffDelay: 250, backoffGrowth: 2, maxTries: 5, loopCondition: true, currentTries: 0 }) {
    if (franchise && businessUnitId) {
        getBusinessUnitById(franchise.service_titan_tenant_id, franchise.service_titan_client_id, franchise.service_titan_client_secret, businessUnitId).then((businessUnitResponse) => {
            setBusinessUnit(businessUnitResponse);
        }).catch(() => {
            retry(() => handleGetBusinessUnit(franchise, businessUnitId, setBusinessUnit, backoffConfig), backoffConfig)
        });
    }
    return getLoopCancelCallback(backoffConfig)
}

export function handleGetEmployeeById(franchise, employeeId, setEmployee, backoffConfig = { backoffDelay: 250, backoffGrowth: 2, maxTries: 5, loopCondition: true, currentTries: 0 }) {
    if (franchise && employeeId) {
        getEmployeeById(franchise.service_titan_tenant_id, franchise.service_titan_client_id, franchise.service_titan_client_secret, employeeId).then((employeeResponse) => {
            setEmployee(employeeResponse);
        }).catch(() => {
            retry(() => handleGetEmployeeById(franchise, employeeId, setEmployee, backoffConfig), backoffConfig)
        });
    }
    return getLoopCancelCallback(backoffConfig)
}

export function handleGetCampaignById(franchise, campaignId, setCampaign, backoffConfig = { backoffDelay: 250, backoffGrowth: 2, maxTries: 5, loopCondition: true, currentTries: 0 }) {
    if (franchise && campaignId) {
        getCampaignById(franchise.service_titan_tenant_id, franchise.service_titan_client_id, franchise.service_titan_client_secret, campaignId).then((campaignResponse) => {
            setCampaign(campaignResponse);
        }).catch(() => {
            retry(() => handleGetCampaignById(franchise, campaignId, setCampaign, backoffConfig), backoffConfig)
        });
    }
    return getLoopCancelCallback(backoffConfig)
}


export function handleGetServiceTitanUniqueFranchises(brandId, setUniqueFranchises, setIsFranchisesLoading, setIsEncyptionFailed, backoffConfig = { backoffDelay: 250, backoffGrowth: 2, maxTries: 5, loopCondition: true, currentTries: 0 }) {
    setUniqueFranchises([]);
    setIsFranchisesLoading(true);
    setIsEncyptionFailed(false)
    getNonDeletedFranchisesByBrandId(brandId, CRMS[0]).then((franchises) => {
        if (Array.isArray(franchises)) {
            const [uFranchises, isEncryptionFailed] = getUniqueTenantFranchises(franchises)
            setIsEncyptionFailed(isEncryptionFailed)
            setUniqueFranchises([ALL_FRANCHISE, ...uFranchises]);
        }
    }).catch(() => {
        retry(() => handleGetServiceTitanUniqueFranchises(brandId, setUniqueFranchises, setIsFranchisesLoading, setIsEncyptionFailed, backoffConfig), backoffConfig)
    }).finally(() => {
        setIsFranchisesLoading(false);
    });
    return getLoopCancelCallback(backoffConfig)
}

let lastParams = ""
export async function handleGetLeads(ids = [], brandId, franchiseId, serviceTitanTenantId, status, page = 1, pageSize = 50, startFollowUpDate = "", endFollowUpDate = "", priority = "", middlewareAssignedToId, setAllLeads, setLeadStatistics, setIsLeadsLoading, setIsLeadsLoadingError,) {
    try {
        lastParams = JSON.stringify({ ids, brandId, franchiseId, serviceTitanTenantId, status, page, pageSize, startFollowUpDate, endFollowUpDate, priority, middlewareAssignedToId })
        const currentDate = new Date();
        const dateTreeMonthBeforeToday = new Date(currentDate.setMonth(currentDate.getMonth() - 3));
        setIsLeadsLoading(true)
        setIsLeadsLoadingError(false)
        const fullLeadResponsesForFrontEnd = await getLeads(ids, brandId, franchiseId, serviceTitanTenantId, status, page, pageSize, dateTreeMonthBeforeToday.toISOString(), "", startFollowUpDate, endFollowUpDate, middlewareAssignedToId, priority)
        if (Array.isArray(fullLeadResponsesForFrontEnd.leadResponseForFrontEnd) && lastParams === JSON.stringify({ ids, brandId, franchiseId, serviceTitanTenantId, status, page, pageSize, startFollowUpDate, endFollowUpDate, priority, middlewareAssignedToId })) {
            setAllLeads(fullLeadResponsesForFrontEnd.leadResponseForFrontEnd)
        }
        if (lastParams === JSON.stringify({ ids, brandId, franchiseId, serviceTitanTenantId, status, page, pageSize, startFollowUpDate, endFollowUpDate, priority, middlewareAssignedToId })) setLeadStatistics(fullLeadResponsesForFrontEnd.leadStatistics ?? INITIAL_LEAD_STATISTICS)
        setIsLeadsLoading(false)
    } catch {
        setIsLeadsLoading(false)
        setIsLeadsLoadingError(true)
    }
}

export function handleGetJobs(franchiseId, page, pageSize, createdStart, createdEnd, jobStatus, setJobs, setIsJobsLoading, setIsJobsLoadingError, setTotalPageCount, setJobStatisticsComponent = () => { }) {
    setIsJobsLoading(true);
    setIsJobsLoadingError(false);
    getServiceTitanJobs(franchiseId, page, pageSize, createdStart, createdEnd, jobStatus).then((jobsResponse) => {
        if (Array.isArray(jobsResponse.data)) {
            setJobs(jobsResponse.data);
            setJobStatisticsComponent(<small><strong>{jobsResponse?.totalCount} {jobsResponse?.totalCount === 1 ? "Job" : "Jobs"}</strong></small>);
        }
        setTotalPageCount(jobsResponse?.totalCount)
    }).catch(() => {
        setIsJobsLoadingError(true);
    }).finally(() => {
        setIsJobsLoading(false);
    });
}

export async function handleGetJobById(franchiseId = 0, jobId = 0, setJobToView) {
    try {
        let res = await getServiceTitanJobById(franchiseId, jobId)
        setJobToView(res)
    } catch (error) {
    }
}

export async function handleUpdateServiceTitanJob(franchise, jobId, updateJobRequest) {
    try {
        const updatedResponse = updateServiceTitanJob(franchise?.service_titan_tenant_id, franchise?.service_titan_client_id, franchise?.service_titan_client_secret, jobId, updateJobRequest)
        return updatedResponse
    }
    catch (err) {
        throw err
    }
}

export function resetFranchiseDependantData(setFranchiseSearchCustomers, setSelectedCustomer, setSelectedCustomerLocations, setSelectedCustomerSelectedLocation, setSelectedCustomerContacts, setCampaigns, setCampaign, setBusinessUnits, setBusinessUnit, setJobTypes, setJobType, setSelectedTagTypes, setTagTypes) {
    resetJobDetailsData(setCampaigns, () => { }, setCampaign, setBusinessUnits, setBusinessUnit, setJobTypes, setJobType, setSelectedTagTypes, setTagTypes)
    resetCustomerData(setFranchiseSearchCustomers, setSelectedCustomer)
    resetCustomerDependantData(setSelectedCustomerLocations, setSelectedCustomerSelectedLocation, setSelectedCustomerContacts)
}

export async function handleGetServiceTitanJobDraftById(jobDraftId, onSuccess) {
    try {
        const jobDraft = await getServiceTitanJobDraftById(jobDraftId);
        onSuccess(jobDraft)
    } catch { }
}

export async function handleGetServiceTItanjobDrafts(brandId, franchiseId, updatedOnOrAfter, updatedOnOrBefore, page, pageSize, setDraftJobs, setTotalDraftJobsCount, setIsDraftJobsLoading, setIsDraftJobsLoadingError) {
    try {
        setIsDraftJobsLoadingError?.(false)
        setIsDraftJobsLoading?.(true)
        const jobDraftResponse = await getServiceTItanDraftJobs(brandId, franchiseId, updatedOnOrAfter, updatedOnOrBefore, page, pageSize)
        if (Array.isArray(jobDraftResponse.serviceTitanJobDrafts)) {
            setDraftJobs?.(jobDraftResponse.serviceTitanJobDrafts)
        } else {
            setDraftJobs([])
        }
        setTotalDraftJobsCount?.(jobDraftResponse.count ?? 0)
    } catch {
        setIsDraftJobsLoadingError?.(true)
    } finally {
        setIsDraftJobsLoading?.(false)
    }
}

function setFranchiseCredentialsError(setIsError, setError) {
    setIsError?.(true)
    setError?.("Missing Credentials");
    return;
}

function getMatchingBusinessUnitByBusinessUnitId(businessUnitId, businessUnits) {
    const businessUnit = businessUnits.find(businessUnit => businessUnit.id === businessUnitId);
    return businessUnit;
}

function getMatchingJobTypeByJobTypeId(jobTypeId, jobTypes) {
    const jobType = jobTypes.find(jobType => jobType.id === jobTypeId);
    return jobType;
}

function getSelectedTagTypesArray(tagTypeIdsArray = [], tagTypesArray = []) {
    return tagTypesArray.filter((tagType) => tagTypeIdsArray.includes(tagType.id));
}

function getMatchingCampaignByCampaignId(campaignId, campaigns) {
    const campaign = campaigns.find(campaign => campaign.id === campaignId);
    return campaign;
}

async function getCustomersOfAFranchise(franchiseId, customerPhoneNumber, customerZipCode, page = 1, pageSize = 50, temporyFranchiseSearchCustomers = [], customerName = "") {
    try {
        const customerResponse = await getFranchiseCustomers(franchiseId, customerPhoneNumber, customerZipCode, page, pageSize, customerName, "", "")
        if (Array.isArray(customerResponse.data)) {
            temporyFranchiseSearchCustomers.push(...customerResponse.data)
        }
        if (customerResponse.hasMore) {
            await getCustomersOfAFranchise(franchiseId, customerPhoneNumber, customerZipCode, page + 1, pageSize, temporyFranchiseSearchCustomers, customerName)
        }
    } catch { }
}

export async function handleOnDeleteIconClicked(jobDraft, draftJobs = [], setDraftJobs, setIsDraftJobsLoadingError, showNotification) {
    try {
        await deleteServiceTitanJobDraft(jobDraft?.id);
        const jobsWithoutThisJob = draftJobs?.filter((draft) => draft?.id !== jobDraft?.id);
        setDraftJobs?.(jobsWithoutThisJob)
        showNotification({ message: "Draft Deleted", type: NOTIFICATION_TYPES.SUCCESS })
    } catch (err) {
        setIsDraftJobsLoadingError?.(true)
        showNotification({ message: "Important: Couldn’t delete the draft", type: NOTIFICATION_TYPES.ERROR })
    }
}

export function shouldRetry(backoffConfig) {
    return (backoffConfig && backoffConfig.loopCondition && backoffConfig.backoffDelay && backoffConfig.backoffGrowth && backoffConfig.maxTries && backoffConfig.maxTries > backoffConfig.currentTries)
}

export function retry(callback, backoffConfig) {
    if (shouldRetry(backoffConfig)) {
        backoffConfig.currentTries++;
        backoffConfig.backoffDelay *= backoffConfig.backoffGrowth;
        setTimeout(callback, backoffConfig.backoffDelay)
    } else {
        backoffConfig.loopCondition = false
    }
}

export function getLoopCancelCallback(backoffConfig) {
    return () => {
        backoffConfig.loopCondition = false;
    }
}

export async function handleGetAppointmentAssignments(franchise, appointment, setTechnicianIds) {
    try {
        if (appointment) {
            const response = await getServiceTitanAppointmentAssignments(franchise.service_titan_tenant_id, franchise.service_titan_client_id, franchise.service_titan_client_secret, "", [appointment?.id])
            const assignments = response?.data?.filter((appointmentAssignemt) => appointment?.id === appointmentAssignemt?.appointmentId);
            const technicianIds = assignments?.map((a) => a?.technicianId);
            setTechnicianIds(technicianIds);
        }
    } catch { }
}

let lastAvailabilityParams
export async function handleGetAvailabilities(setIsAvailabilitiesLoading, setIsAvailabilitiesError, franchiseId,brandId ,brandShortCode, startDate, endDate, duration, technicianShiftStartDate, setAvailabilities) {
    let params;
    try {
        setIsAvailabilitiesLoading(true);
        setIsAvailabilitiesError(false);
        params = JSON.stringify(getAvailabilityParam(franchiseId, startDate, endDate, duration))
        lastAvailabilityParams = params
        const response = await getServiceTitanAvailability(franchiseId,brandId ,brandShortCode, startDate, endDate, duration, technicianShiftStartDate);
        if (lastAvailabilityParams === params) setAvailabilities(response);
    } catch (err) {
        if (lastAvailabilityParams === params) setIsAvailabilitiesError(true);
    } finally {
        if (lastAvailabilityParams === params) setIsAvailabilitiesLoading(false);
    }
}

function getAvailabilityParam(franchiseId, startDate, endDate, duration) {
    return {
        franchiseId,
        startDate,
        endDate,
        duration,
    };
}

function getUniqueTenantFranchises(franchises = []) {
    let uniqueFranchiseMap = {};
    let isEncryptionFailed = false
    const otherFranchises = []
    franchises.forEach((franchise) => {
        isEncryptionFailed = franchise?.is_encryption_failed
        if (franchise.service_titan_tenant_id) {
            const previousFranchise = uniqueFranchiseMap[franchise.service_titan_tenant_id]
            if (!getIsSTCredentialsAvailable(previousFranchise)) {
                uniqueFranchiseMap[franchise.service_titan_tenant_id] = franchise
            }
        } else {
            otherFranchises.push(franchise)
        }
    });
    const serviceTitanFranchisesWithTenantId = Object.values(uniqueFranchiseMap) ?? []
    const allServiceTitanFranchises = [...serviceTitanFranchisesWithTenantId, ...otherFranchises]
    return [allServiceTitanFranchises, isEncryptionFailed];
}

export async function handleGetLeadById(selectedLeadId, setSelectedLeadToView, setIsExpandedLeadModalOpen, showNotification) {
    if (selectedLeadId) {
        try {
            const lead = await getServiceTitaLeadById(selectedLeadId)
            setSelectedLeadToView(lead)
            setIsExpandedLeadModalOpen(true)
        } catch (err) {
            if (err.status === 404) {
                showNotification({ message: "Lead does not exist", type: NOTIFICATION_TYPES.ERROR })
            } else {
                showNotification({ message: "Something went wrong", type: NOTIFICATION_TYPES.ERROR })
            }
        }
    }
}

export async function handleGetLeadByServiecTitanLeadId(selectedLeadId, setSelectedLeadToView, setIsExpandedLeadModalOpen, showNotification) {
    if (selectedLeadId) {
        try {
            const lead = await getServiceTitaLeadByServiceTitanLeadId(selectedLeadId)
            setSelectedLeadToView(lead)
            setIsExpandedLeadModalOpen(true)
        } catch (err) {
            if (err.status === 404) {
                showNotification({ message: "Lead does not exist", type: NOTIFICATION_TYPES.ERROR })
            } else {
                showNotification({ message: "Something went wrong", type: NOTIFICATION_TYPES.ERROR })
            }
        }
    }
}

export async function handleGetLeadFollowUpHistory(leadId, setHistoryFollowUps, setIsFollowUpHistoryLoading, setIsFollowUpHistoryError) {
    setIsFollowUpHistoryError(false);
    setIsFollowUpHistoryLoading(true);
    try {
        const historyFollowUps = await getServiceTitanLeadFollowUpHistory(leadId)
        if (Array.isArray(historyFollowUps)) setHistoryFollowUps(historyFollowUps)
    } catch {
        setIsFollowUpHistoryError(true);
    } finally {
        setIsFollowUpHistoryLoading(false);
    }
}

export async function handleGetAppointmentAssignmentsForEditJob(franchise, appointmentId) {
    try {
        if (appointmentId) {
            const response = await getServiceTitanAppointmentAssignments(franchise.service_titan_tenant_id, franchise.service_titan_client_id, franchise.service_titan_client_secret, "", [appointmentId])
            const assignments = response?.data?.filter((appointmentAssignemt) => appointmentId === appointmentAssignemt?.appointmentId);
            const technicianIds = assignments?.map((a) => a?.technicianId);
            return technicianIds;
        }
    } catch { }
}

export async function handleGetJob(franchiseId, jobId, onViewJobIconClicked) {
    try {
        const jobData = await getServiceTitanJobDetails(jobId, franchiseId)
        onViewJobIconClicked(jobData)
    } catch (error) {
    }
}