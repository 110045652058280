import { Box, Button, Dialog, Typography } from "@mui/material";

export default function DeleteConfirmationDialog({ handleDeleteDialogClose, handleDelete, isDeleteDialogOpen, keyword }) {
    return <Dialog open={isDeleteDialogOpen} onClose={handleDeleteDialogClose}>
        <Box sx={{ margin: "1em" }}>
            <Typography variant="p" component="div">{`⚠️  Please note that any information related to this keyword (${keyword}) will be permanently removed from the following areas.`}</Typography>
            <ul>
                <li>Faqs</li>
            </ul>
            <Box sx={{ display: "flex", flexDirection: "row", gap: "0.5em" }} >
                <Box sx={{ flexGrow: 2 }} />
                <Button data-test={`faq_keyword_${keyword?.keyword}_delete_cancel`} variant="text" onClick={handleDeleteDialogClose}>Cancel</Button>
                <Button data-test={`faq_keyword_${keyword?.keyword}_delete_confirmation`} variant="contained" onClick={handleDelete}>Delete</Button>
            </Box>
        </Box>
    </Dialog>
}