import { Box, CircularProgress, IconButton } from '@mui/material'
import React, { useContext, useState } from 'react'
import AddIcon from "@mui/icons-material/Add";
import { CustomTextField } from '../../franchise/franchiseCustomStyle'
import { authEmail } from '../../../../services/emailsService';
import { getBrandEmailsByEmail } from '../../../../services/brandService';
import CustomChipForEmails from './customChipForEmails';
import { isValidEmail } from '../../../../utils/validators';
import { CommonContext } from '../../../../contexts/CommonContextProvider';
import { NOTIFICATION_TYPES } from '../../../../constants/common';


function tagOnDeleteHandler(index, tagArray = [], setTagArray) {
    let arr = [...tagArray]
    arr.splice(index, 1)
    setTagArray([...arr])
}

function Tags(props) {
    const { tagArray, handleDelete, validateEmails } = props

    return <Box style={{ display: "flex", flexDirection: "column", gap: "0.5em" }}>
        {tagArray?.map((tag, i) =>
            <CustomChipForEmails key={i} id={`${i}-tag`}
                label={tag}
                handleValidateEmail={() => validateEmails(tag.email)}
                onDelete={() => handleDelete(i)}
            />
        )}
    </Box>
}

export default function EmailViewer({ emails, setEmails, emailErr, setEmailValidationError }) {

    const [addValue, setAddValue] = useState("")
    const [isInsertEmailLoading, setIsInsertEmailLoading] = useState(false)
    const [err, setErr] = useState("")
    const { showNotification } = useContext(CommonContext)

    const tagInputFieldChangeHandler = (e) => {
        const email = e?.target?.value?.trim?.()
        if (isValidEmail(email) || !email) {
            setEmailValidationError("")
        } else {
            setEmailValidationError("Invalid Email")
        }
        setAddValue(email)
    }

    const handleDelete = (i) => {
        tagOnDeleteHandler(i, emails, setEmails)
    }

    const HandleValidateEmails = async (newEmail) => {
        try {
            let body = {
                email: newEmail
            }
            const res = await authEmail(body)
            window.open(res.RedirectUrl)
        } catch { }
    }

    return (
        <Box>
            <Box style={{ display: 'flex' }}>
                <CustomTextField
                    id='email-input-field'
                    placeholder={"Email addresses of the brands"}
                    sx={{ my: 1, mr: 1, width: '100%' }}
                    size="small"
                    error={emailErr.length > 0 ? true : false}
                    helperText={emailErr}
                    value={addValue}
                    onChange={tagInputFieldChangeHandler}
                />
                <IconButton size='small' title={`Add Email`} disabled={isValidEmail(addValue) ? false : true} className={isValidEmail(addValue) ? "icon-btn" : "icon-btn-disabled"} style={{ width: "40px", height: "40px", marginTop: "8px" }} onClick={() => handleInsertEmail(addValue, emails, setEmails, setIsInsertEmailLoading, setEmailValidationError, setAddValue, showNotification)}>
                    {isInsertEmailLoading ? <CircularProgress size={30} color="inherit" /> : <AddIcon />}
                </IconButton>
            </Box>
            <Tags
                disabled={false}
                tagArray={emails}
                err={err}
                setErr={setErr}
                handleDelete={handleDelete}
                validateEmails={HandleValidateEmails}
            />
        </Box>
    )
}


async function handleInsertEmail(newEmail, emails, setEmails, setIsInsertEmailLoading, setEmailValidationError, setAddValue, showNotification) {
    try {
        setIsInsertEmailLoading(true)
        const res = await handleValidateEmail(newEmail)
        if (res.isValid) {
            let obj = {
                email: newEmail
            }
            setEmails([...emails, obj])
            setEmailValidationError("")
            setAddValue("")
        } else {
            setEmailValidationError("Already exists")
        }
    } catch (err) {
        showNotification({ message: "Important : Could not validate email. Please try again", type: NOTIFICATION_TYPES.ERROR })
    } finally {
        setIsInsertEmailLoading(false)

    }
}

async function handleValidateEmail(email) {
    try {
        const res = await getBrandEmailsByEmail(email)
        if (Array.isArray(res) && res.length > 0) {
            return { isValid: false, message: "Email already exists" }
        } else {
            return { isValid: true, message: "" }
        }
    } catch (err) {
        throw err
    }
}