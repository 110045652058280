import { Box, Button, IconButton } from '@mui/material'
import React, { useContext, useState } from 'react'
import AddIcon from "@mui/icons-material/Add";
import { CustomTextField } from '../pages/dashboard/franchise/franchiseCustomStyle';
import { alphabeticallySortArray, removeDuplicateOfArray } from '../utils/array';
import CustomChip from './CustomChip';
import { UserContext } from "../contexts/User";
import { userLevels } from '../constants/common';
import useDebounce from '../hooks/useDebounce';
import ClearIcon from '@mui/icons-material/Clear';

const ALREADY_EXISTS = ' Already Exists'

function tagOnChangeHandler(index, newValue, tagArray, setTagArray) {
    let arr = tagArray
    let commaSeparatedArr = newValue.split(",")
    if (commaSeparatedArr.length <= 1) {
        arr[index] = newValue
        setTagArray([...arr])
    } else {
        tagOnDeleteHandler(index, tagArray, setTagArray)
        addNewTagHandler(newValue, tagArray, setTagArray)
    }
}

function tagOnDeleteHandler(index, tagArray, setTagArray) {
    let arr = tagArray
    arr.splice(index, 1)
    setTagArray([...arr])
}

function addNewTagHandler(newValue, tagArray, setTagArray) {
    let arr = removeDuplicateOfArray(tagArray)
    let commaSeparatedArr = newValue.split(",")
    setTagArray([...arr, ...commaSeparatedArr])
}

function Tags(props) {
    const { disabled, tagArray, currentId, setCurrentId, title, err, setErr, handleDelete, onTagUpdateHandler } = props
    const tagInputFieldChangeHandler = (e) => {
        setErr("")
    }

    return (
        <div style={{ display: "flex", flexWrap: "wrap", gap: "0.1em", marginLeft: 5, marginRight: 5 }} onBlur={() => setCurrentId(-1)}>
            {removeDuplicateOfArray(tagArray?.sort?.(alphabeticallySortArray))?.map((tag, i) =>
                <span key={i}>
                    {i > 0 && "  "}
                    {currentId !== i ?
                        <CustomChip id={`${i}-tag`} label={tag} disabled={disabled}
                            onDelete={() => handleDelete(i)}
                        // onClick={() => setCurrentId(i)} Edit option disbled
                        />
                        :
                        <CustomTextField id={`${title}-${i}-input`}
                            disabled={disabled}
                            inputRef={input => input && input.focus()}
                            sx={{ width: 150 }}
                            error={err.length > 0 ? true : false}
                            size="small"
                            defaultValue={tag}
                            onKeyDown={(e) => onTagUpdateHandler(tag, e)}
                            onChange={tagInputFieldChangeHandler}
                        />
                    }
                </span>
            )}
        </div>
    )
}

export default function TagViewer(props) {
    const { disabled, title, displayLimit, tagArray, setTagArray, width, getIsNewValueValid = async (value = "") => { return { isValid: true, message: "" } }, disableHint, disableTagTrimming = false, allowSpaces = false, clearable = false } = props
    const [currentId, setCurrentId] = useState(-1)
    const [err, setErr] = useState('')
    const [isValidationLoading, setIsValidationLoading] = useState(false)
    const [displayAlltags, setDisplayAlltags] = useState(false)
    const [addValue, setAddValue] = useState("")
    const [showTip, setShowTip] = useState(false)
    const { me } = useContext(UserContext)
    const debouncedValidationFunction = useDebounce(addValue, (value) => {
        if (value) {
            getIsNewValueValid(value).then(({ isValid, message }) => {
                if (isValid) {
                    setErr("")
                    setShowTip(value?.length > 0 ? true : false)
                } else if (!value) {
                    setErr("")
                } else {
                    setErr(message ? message : "Invalid")
                }
            }).finally(() => setIsValidationLoading(false))
        } else {
            setErr("")
        }
    }, 500)

    const onTagUpdateHandler = (oldTag, event) => {
        setAddValue("")
        let newValue = event?.target.value.trim()
        if (event?.key === 'Enter' && newValue !== "" && newValue !== ",") {
            event?.preventDefault?.()
            if (!checkDuplicateExists(newValue) || newValue === oldTag) {
                let arr = tagArray
                let x = arr.find(a => a === oldTag)
                let index = arr.indexOf(x)
                if (index !== -1) {
                    tagOnChangeHandler(index, newValue, tagArray, setTagArray)
                }
                setCurrentId(-1)
            } else {
                setErr(newValue + ALREADY_EXISTS)
            }
        }
    }

    async function tagInputFieldChangeHandler(e) {
        let value = ""
        if (!allowSpaces) {
            value = e?.target?.value?.replace?.(/\s/g, '')
        } else {
            value = e?.target?.value ?? ""
        }
        value?.trim?.()
        setIsValidationLoading(true)
        debouncedValidationFunction(value)
        setAddValue(value)
    }

    const checkDuplicateExists = (value) => {
        return tagArray?.some(t => t === value)
    }

    const onInsertHandler = (event) => {
        let newValue = ""
        if (!allowSpaces) {
            newValue = event?.target?.value?.replace?.(/\s/g, '') ?? ""
        } else {
            newValue = event?.target?.value ?? ""
        }
        setAddValue(newValue)
        if (event?.key === 'Enter' && newValue !== "" && newValue !== ",") {
            event?.preventDefault?.()
            if (!checkDuplicateExists(newValue)) {
                addNewTagHandler(newValue, tagArray, setTagArray)
            } else {
                setErr(newValue + ALREADY_EXISTS)
            }
            setAddValue("")
        }
        setShowTip(false)
    }

    const handleDelete = (i) => {
        tagOnDeleteHandler(i, tagArray, setTagArray)
    }

    const onAddButtonClickHandler = () => {
        let e = {
            key: 'Enter',
            target: { value: addValue }
        }
        setAddValue(e.target.value)
        onInsertHandler(e)
    }

    const preparetagArray = (tagArray = []) => {
        tagArray.forEach(tag => {
            if (tag?.trim?.() === "") {
                tagArray.splice(tagArray.indexOf(tag), 1)
            }
        });
        return tagArray
    }

    return (
        <>
            <div style={{ display: 'flex', alignItems: 'start' }}>
                <CustomTextField
                    disabled={disabled}
                    sx={{ my: 1, mr: 1, width: width ? width : '32%' }}
                    error={err.length > 0 ? true : false}
                    helperText={err}
                    size="small"
                    id='tag-input-field'
                    value={addValue}
                    onKeyDown={onInsertHandler}
                    label={title}
                    onChange={tagInputFieldChangeHandler}
                    inputProps={{ "data-test": "tag_viewer_input" }}
                    InputProps={clearable ? {
                        endAdornment: addValue ? (
                            <IconButton data-test="tag_viewer_clear" size="small" onClick={() => tagInputFieldChangeHandler()}>
                                <ClearIcon />
                            </IconButton>
                        ) : undefined
                    } : undefined}
                />
                {me?.level > userLevels.AGENT &&
                    <Box sx={{ marginTop: "0.7em" }}>
                        <IconButton data-test="tag_viewer_add" size='small' title={`Add ${title}`} className={!(disabled || err || isValidationLoading) ? "icon-btn" : "icon-btn-disabled"} disabled={disabled || err || isValidationLoading} onClick={onAddButtonClickHandler}>
                            <AddIcon />
                        </IconButton>
                    </Box>
                }
                &nbsp;&nbsp;
                {showTip && !disableHint &&
                    <Box sx={{ marginTop: "1.1em" }}>
                        Click&nbsp;<IconButton style={{ cursor: 'text' }} size='small' className={!disabled ? "icon-btn" : "icon-btn-disabled"} disabled={disabled}>
                            <AddIcon style={{ height: "10px", width: "10px" }} />
                        </IconButton>&nbsp;to insert
                    </Box>
                }
            </div>
            {tagArray?.length > displayLimit && !displayAlltags && !disableTagTrimming ?
                <div style={{ display: 'flex' }}>
                    <Tags
                        disabled={disabled}
                        tagArray={preparetagArray(tagArray.slice(0, displayLimit))}
                        currentId={currentId}
                        setCurrentId={setCurrentId}
                        title={title}
                        err={err}
                        setErr={setErr}
                        handleDelete={handleDelete}
                        onTagUpdateHandler={onTagUpdateHandler}
                        tagInputFieldChangeHandler={tagInputFieldChangeHandler}
                    />
                    . . .&nbsp;
                    <Button data-test="tag_viewer_show_all" size='small' color="primary"
                        onClick={() => setDisplayAlltags(!displayAlltags)}
                    >Show all
                    </Button>
                </div>
                :
                <Tags
                    disabled={disabled}
                    tagArray={preparetagArray(tagArray)}
                    currentId={currentId}
                    setCurrentId={setCurrentId}
                    title={title}
                    err={err}
                    setErr={setErr}
                    handleDelete={handleDelete}
                    onTagUpdateHandler={onTagUpdateHandler}
                    tagInputFieldChangeHandler={tagInputFieldChangeHandler}
                />
            }
            {displayAlltags && !disableTagTrimming &&
                <Button size='small' color="primary"
                    onClick={() => setDisplayAlltags(!displayAlltags)}
                >Show Less
                </Button>
            }
        </>
    )
}