import { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Pagination } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress"
import moment from "moment";
import { ROW_COUNT_PER_PAGE, ERROR_COMPONENTS } from "../../../constants/common";
import { getOutboundCallsWithJobLeadDetails } from "../../../state-services/callLogs";
import ErrorView from "../../../components/ErrorView";
import { CommonContext } from "../../../contexts/CommonContextProvider";
import ImageBox from "../../../components/LogoBox/ImageBox";
import { CustomSelect } from "../franchise/franchiseCustomStyle";
import CustomDatePicker from "../../../components/CustomDatePicker";

const OUTBOUND_CALL_TABS = [
    {
        index: 0,
        title: "Follow-Up Calls",
        status: 1
    },
    {
        index: 1,
        title: "Other Calls",
        status: 0
    }
]

const CALL_FOR_TYPES = [
    {
        index: 0,
        type: "All"
    },
    {
        index: 1,
        type: "Jobs"
    },
    {
        index: 2,
        type: "Leads"
    },
    {
        index: 3,
        type: "Estimates"
    }
]

export default function OutboundCallPage() {

    const { brands, franchises, allUsersIncludeRemovedOnes } = useContext(CommonContext);
    const [startDate, setStartDate] = useState(Date.now());
    const [filterState, setFilterState] = useState()
    const [callCount, setCallCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [outboundCalls, setOutboundCalls] = useState([])
    const [tabStatus, setTabStatus] = useState(OUTBOUND_CALL_TABS[0].status)
    const [loading, setLoading] = useState(true)
    const [callFor, setCallFor] = useState(0)
    const [startDateValue, setStartDateValue] = useState(moment(Date.now()).startOf('day').format('YYYY-MM-DD HH:mm:ss Z'));

    const history = useHistory()

    useEffect(() => {
        setTabStatus(OUTBOUND_CALL_TABS[0].status)
    }, [])

    useEffect(() => {
        if (startDateValue && (tabStatus === 0 || tabStatus === 1)) {
            setFilterState({ page: 1, size: 10, createdOn: startDateValue, status: tabStatus, callFor: 0 })
        }

    }, [startDateValue, tabStatus])

    useEffect(() => {
        if (filterState) {
            setLoading(true)
            getOutboundCallsWithJobLeadDetails(filterState.page, filterState.size, filterState.createdOn, filterState.status, filterState.callFor).then((response) => {
                setOutboundCalls(response?.outbound_calls)
                setCallCount(response?.count)
            }).catch((error) => {
            }).finally(() => {
                setLoading(false)
            })
        }
    }, [filterState])

    const handleCallForChange = (e) => {
        setCallFor(e.target.value)
        setCurrentPage(1)
        setFilterState({ ...filterState, callFor: e.target.value })
    }

    const onPageChangeHandler = (page) => {
        setCurrentPage(page)
        setFilterState({ ...filterState, page: page })
    }

    const handleRowNumber = (i) => {
        if (currentPage === 1) {
            return i + 1
        } else {
            return (currentPage - 1) * ROW_COUNT_PER_PAGE + i + 1
        }
    }

    const handleTabChange = (status) => {
        setCurrentPage(1)
        setTabStatus(status)
    }

    const getMatchingBrandByBrandId = (brandId) => {
        const brand = brands.find((brand) => brand.id === brandId)
        return brand

    }

    const getMatchingAdminByAdminId = (adminId) => {
        const admin = allUsersIncludeRemovedOnes.find((user) => user.user_id === adminId)
        return admin?.first_name + " " + admin?.last_name
    }

    const getMatchingFranchiseByFranchiseId = (franchiseId) => {
        const franchise = franchises.find((franchise) => franchise.id === franchiseId)
        return franchise?.franchise_name

    }

    const handleViewLeadDetails = async (leadId) => {
        history.replace(`/leads?serviceTitanLeadId=${leadId}`)
    }

    const handleViewServiceTitanJobDetails = async (serviceTitanJobId, franchiseId, brand_id) => {
        const brandId = getMatchingBrandByBrandId(brand_id)?.brand_id
        history.replace(`/jobs?serviceTitanJobid=${serviceTitanJobId}&franchiseId=${franchiseId}&isFromCallLog=true&brandId=${brandId}`)
    }

    const handleViewVonigoJobDetails = async (vonigoJobId, franchiseId, brand_id) => {
        const redirectUrl = await getMatchingBrandByBrandId(brand_id)?.redirect_url
        history.replace(`/jobs?vonigoJobid=${vonigoJobId}&franchiseId=${franchiseId}&baseUrl=${redirectUrl}&isFromCallLog=true&brandId=${brand_id}`)
    }
    const handleViewEstimateDetails = (estimateId, franchise_id, brand_id) => {
        const brandId = getMatchingBrandByBrandId(brand_id)?.brand_id
        history.replace(`/estimates?estimateId=${estimateId}&brandId=${brandId}&franchiseId=${franchise_id}`)
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <div
                    style={{ padding: "15px", paddingTop: '0px', borderRadius: "7px" }}
                    className={"bg-shadow bg-white"}
                >
                    <div style={{ display: "flex", flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div >
                            <div style={{ display: "flex", marginTop: "15px", marginBottom: "15px" }}>
                                <small>
                                    <strong>
                                        &nbsp; {callCount} Call Records &nbsp;
                                    </strong>
                                </small>
                            </div>
                        </div>
                        {tabStatus === 1 && (
                            <CustomSelect style={{ minWidth: "170px", marginRight: "20px", marginLeft: 'auto' }}>
                                <Select
                                    size={"small"}
                                    displayEmpty
                                    inputProps={{ "aria-label": "Without label" }}
                                    className={"cus-input"}
                                    value={callFor}
                                    onChange={(e) => { handleCallForChange(e) }}
                                >
                                    {CALL_FOR_TYPES.map((singleCallType, index) => (
                                        <MenuItem key={index} value={singleCallType.index}>{singleCallType.type}</MenuItem>
                                    ))}
                                </Select>
                            </CustomSelect>
                        )}
                        <div style={{ width: "200px", float: 'right' }}>
                            <CustomDatePicker
                                showTimeSelect={false}
                                maxTime={new Date()}
                                disabled={false}
                                label={`Start Date`}
                                value={new Date(startDate)}
                                onChange={(date) => {
                                    setStartDateValue(moment(date).startOf('day').format('YYYY-MM-DD HH:mm:ss Z'))
                                    setStartDate(date)
                                }}
                                clearable={false}
                                required={true}
                            />
                        </div>
                    </div>
                    <div className={"tabs"}>
                        {OUTBOUND_CALL_TABS.map((tab) => (
                            <Button
                                key={tab.index}
                                onClick={() => handleTabChange(tab.status)}
                                size={"small"}
                                variant="contained"
                                disabled={loading}
                                className={tab.status == tabStatus ? "btn-primary tab" : "btn-secondary tab"}
                            >
                                {tab.title}
                            </Button>
                        ))}
                    </div>
                    <div>
                        <TableContainer style={{ height: "70vh" }}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>#</TableCell>
                                        <TableCell align="left">Call By</TableCell>
                                        <TableCell align="left">Customer No.</TableCell>
                                        {tabStatus === 1 && (<TableCell align="left">{callFor === 0 ? "Job ID/ Lead ID/ Estimates ID" : callFor === 1 ? "Job ID" : callFor === 2 ? "Lead ID" : "Estimate ID"} </TableCell>)}
                                        <TableCell align="left">Brand</TableCell>
                                        <TableCell align="left">Created Time</TableCell>
                                        <TableCell align="left">Franchise</TableCell>
                                        <TableCell align="left">Agent</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {loading ? (<TableRow>
                                        <TableCell colSpan={tabStatus === 0 ? 8 : 7} align="center" style={{ border: "none", height: "300px", color: "grey" }} >
                                            <CircularProgress size={30} color="inherit" />
                                        </TableCell>
                                    </TableRow>) : outboundCalls && outboundCalls.length !== 0 ? (
                                        outboundCalls?.map((row, i) => (
                                            <TableRow>
                                                <TableCell>{handleRowNumber(i)}</TableCell>
                                                <TableCell align="left">{row.from_phone}</TableCell>
                                                <TableCell align="left">{row.to_phone}</TableCell>
                                                {tabStatus === 1 && (<TableCell align="left">{row?.service_titan_job_id !== 0 ? (<Button onClick={() => handleViewServiceTitanJobDetails(row?.service_titan_job_id, row?.from_franchise_id, row?.from_brand_id)}>{row?.service_titan_job_id}</Button>) : row?.lead_id !== 0 ? (<Button onClick={() => handleViewLeadDetails(row?.lead_id)}>{row?.lead_id}</Button>) : row?.vonigo_job_id !== 0 ? (<Button onClick={() => handleViewVonigoJobDetails(row?.vonigo_job_id, row?.from_franchise_id, row?.from_brand_id)}>{row?.vonigo_job_id}</Button>) : row?.estimate_id !== 0 ? (<Button onClick={() => handleViewEstimateDetails(row?.estimate_id, row?.from_franchise_id, row?.from_brand_id)}>{row?.estimate_id}</Button>) : "NA"}</TableCell>)}
                                                <TableCell align="left"><ImageBox alt={row.from_brand_id} src={getMatchingBrandByBrandId(row.from_brand_id)?.brand_img} /></TableCell>
                                                <TableCell align="left">{moment(row?.created_at).format("hh:mm A")}</TableCell>
                                                <TableCell align="left">{getMatchingFranchiseByFranchiseId(row.from_franchise_id)}</TableCell>
                                                <TableCell align="left">{getMatchingAdminByAdminId(row.created_by)}</TableCell>
                                            </TableRow>
                                        ))) : (
                                        <TableRow>
                                            <TableCell style={{ border: "none", height: "200px" }} align={"center"} colSpan={tabStatus === 0 ? 8 : 7}>
                                                <ErrorView type={ERROR_COMPONENTS.NOT_FOUND} title='No Outbound Calls Found On Selected Day' />
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Pagination
                            page={currentPage}
                            onChange={(e, v) => onPageChangeHandler(v)} className="pagination-bar"
                            count={Math.ceil(callCount / ROW_COUNT_PER_PAGE)}
                            color="standard" />
                    </div>
                </div>
            </Grid>
        </Grid>
    );
}