import { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import ReportIcon from "@mui/icons-material/Report";
import AddIcon from "@mui/icons-material/Add";
import moment from "moment";
import { Checkbox, InputLabel, ListItemText, MenuItem, Pagination, Select, Tooltip } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress"
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from "@mui/icons-material/Delete";
import { ERROR_COMPONENTS, ROW_COUNT_PER_PAGE, USER_STATUS } from "../../../constants/common";
import ErrorView from "../../../components/ErrorView";
import { getMeHandler } from "../../../state-services/users";
import { UserModal } from "./UserModel";
import { CustomSelect, CustomSwitch } from "./userCustomStyle";
import { UserActiveModal } from "./ActiveUserModel";
import DeleteDialog from "./components/DeleteDialog";
import { UserContext } from "../../../contexts/User";
import { getMeActionTypes, USER_TYPES } from "../../../constants/users";
import { CommonContext } from "../../../contexts/CommonContextProvider";
import { handleGetUsers } from "../../../state-services/users/users";
import GlobalSearch from "../../../components/globalSearch/GlobalSearch";
import useDebounce from "../../../hooks/useDebounce";
import ImageBox from "../../../components/LogoBox/ImageBox";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function Users(props) {
    const { me, setMe } = useContext(UserContext)
    const [dialogOpen, setDialogOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [records, setRecords] = useState([]);
    const [user, setUser] = useState(null)
    const [active, setActive] = useState(0)
    const [loading, setLoading] = useState(true)
    const [userActiveDialog, setUserActiveDialog] = useState(false)
    const [isMyId, setIsMyId] = useState(null)
    const { showNotification, brands } = useContext(CommonContext);
    const [refresh, setRefresh] = useState(false)
    const [userCount, setUserCount] = useState({ all: 0, active: 0, inactive: 0 })
    const [searchObject, setSearchObject] = useState({ searchValue: "", ids: [] })
    const [currentPage, setCurrentPage] = useState(1)
    const [loginUserLevel, setLoginUserLevel] = useState(parseInt(me?.level))
    const [loginUserId, setLoginUserId] = useState(parseInt(me?.user_id))
    const [updatedUser, setUpdatedUser] = useState(null)
    const [isUserUpdated, setUserUpdated] = useState(false)
    const [editClickCount, setEditClickCount] = useState(0)
    const [userLevel, setUserLevel] = useState(0)
    const [disableBrand, setDisableBrand] = useState(false)
    const [selectedBrands, setSelectedBrands] = useState([]);

    const debounceHandleGetUsers = useDebounce(
        { searchValue: searchObject?.searchValue, ids: searchObject?.ids, brandId: searchObject?.brandId, userLevel: searchObject?.userLevel, currentPage: currentPage, pageSize: ROW_COUNT_PER_PAGE, setRecords: setRecords, setUserCount: setUserCount, setLoading: setLoading, setIsError: (isError) => { } }, ({ searchValue, ids, brandId, userLevel, currentPage, pageSize, setRecords, setUserCount, setLoading, setIsError }) => {
            if (searchValue && ids?.length === 0) {
                setRecords([])
                setUserCount({ all: 0, active: 0, inactive: 0 })
            } else {
                handleGetUsers(ids, brandId, userLevel, currentPage, pageSize, setRecords, setUserCount, setLoading, setIsError)
            }
        }, 500)

    useEffect(() => {
        setLoading(true)
        debounceHandleGetUsers({ searchValue: searchObject?.searchValue, ids: searchObject?.ids, brandId: searchObject?.brandId, userLevel: searchObject?.userLevel, currentPage: currentPage, pageSize: ROW_COUNT_PER_PAGE, setRecords: setRecords, setUserCount: setUserCount, setLoading: setLoading, setIsError: (isError) => { } })
        getMeHandler(setIsMyId, setUpdatedUser, getMeActionTypes.id)
    }, [refresh, searchObject?.ids, searchObject?.searchValue, currentPage, searchObject?.brandId, searchObject?.userLevel, debounceHandleGetUsers]);

    useEffect(() => {
        if (updatedUser) setMe(updatedUser)
        if (updatedUser) setLoginUserLevel(updatedUser?.level)
        if (updatedUser) setLoginUserId(updatedUser?.user_id)
    }, [updatedUser, setMe]);

    useEffect(() => {
        getMeHandler(setIsMyId, setUpdatedUser, getMeActionTypes.all)
    }, [isUserUpdated]);

    useEffect(() => {
        if (userLevel !== 2) {
            if (!Boolean(brands?.length) || loading) {
                setDisableBrand(true)
            } else {
                setDisableBrand(false)
            }
        } else {
            setDisableBrand(true)
        }
    }, [userLevel, brands, loading])

    const onPageChangeHandler = (page) => {
        setCurrentPage(page)
    }

    const handleRefreshData = () => {
        setRefresh(!refresh)
    }

    const openDialog = (user, active) => { //open dialog to active or diactive user
        setUser(user)
        setActive(active)
        setUserActiveDialog(true)
    }

    const closeDialog = () => {  //close dialog 
        setUserActiveDialog(false)
    }

    const handleDialogOpen = (user) => {
        setUser(user)
        setEditClickCount(editClickCount + 1)
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleUserStatusChange = (e, user) => {
        let status = e.target.checked ? USER_STATUS.enabled : USER_STATUS.disabled;
        openDialog(user, status)
    };

    const handleRowNumber = (i) => {
        if (currentPage === 1) {
            return i + 1
        } else {
            return (currentPage - 1) * ROW_COUNT_PER_PAGE + i + 1
        }
    }

    const handleDeleteUser = (user) => {
        setUser(user);
        setDeleteDialogOpen(true);
    };

    const handleDeleteDialogClose = () => {
        setDeleteDialogOpen(false);
        setUser(null);
    };

    const checkPermanentDeleteAccess = (user) => {
        let access = false
        if ((loginUserLevel === USER_TYPES.superAdmin.level && loginUserId !== user.user_id) ||
            (loginUserLevel === USER_TYPES.admin.level && (user.level === USER_TYPES.agent.level || user.level !== USER_TYPES.superAdmin.level
                && loginUserId !== user.user_id))) {
            access = true
        }
        return access
    }

    const checkEditAccess = (user) => {
        let access = false
        if ((loginUserLevel === USER_TYPES.superAdmin.level && loginUserId !== user.user_id) ||
            (loginUserLevel === USER_TYPES.admin.level && user.level !== USER_TYPES.superAdmin.level) && loginUserId !== user.user_id) {
            access = true
        }
        return access
    }

    const handleBrandChange = (e) => {
        const {
            target: { value },
        } = e;
        setSelectedBrands(typeof value === 'string' ? value.split(',') : value,)
        setSearchObject((searchObject) => { return { ...searchObject, brandId: value?.map((item) => item.id) } })
        setCurrentPage(1)
    }

    const handleUserLevelChange = (e) => {
        setUserLevel(e.target.value)
        if (e.target.value === 1) {
            setSearchObject((searchObject) => { return { ...searchObject, userLevel: [USER_TYPES.agent.level] } })
        } else if (e.target.value === 2) {
            setSelectedBrands([])
            setSearchObject((searchObject) => { return { ...searchObject, userLevel: [USER_TYPES.admin.level, USER_TYPES.superAdmin.level], brandId: undefined } })
        } else {
            setSearchObject((searchObject) => { return { ...searchObject, userLevel: undefined } })
        }
        setCurrentPage(1)
    }

    return (
        <Box className={"dashboard"} sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <div
                        style={{ padding: "15px", borderRadius: "7px" }}
                        className={"bg-shadow bg-white"}
                    >
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginBottom: "10px",
                            }}
                        >
                            <div >
                                <h2 style={{ margin: "0" }} >Users</h2>
                                <div style={{ display: "flex", marginTop: "15px" }}>
                                    <small className={"text-color"}><strong>{userCount.all} Users</strong> | {userCount.active} Active | {userCount.inactive} Inactive</small>
                                </div>
                            </div>
                            <div style={{ display: "flex", gap: "0.5em", position: 'relative' }}>
                                {userLevel === 1 ?
                                    <CustomSelect id="brand" size={"small"} margin="small" sx={{ minWidth: "300px", maxWidth: "300px", marginRight: "10px", flexGrow: 2 }} >
                                        <InputLabel id="brand-label">Assigned Brands</InputLabel>
                                        <Select
                                            id={"brandId"}
                                            labelId="brand-label"
                                            label="Assigned Brands"
                                            noOptionsText="No brands"
                                            disabled={disableBrand}
                                            disablePortal
                                            value={selectedBrands}
                                            defaultValue={selectedBrands}
                                            onChange={(e) => handleBrandChange(e)}
                                            renderValue={(selected) => selected.map((item) => item.brand_name).join(', ')}
                                            MenuProps={MenuProps}
                                            multiple
                                        >
                                            {brands.map((brand) => (
                                                <MenuItem
                                                    key={brand.id}
                                                    value={brand}
                                                    style={{
                                                        fontSize: "14px",
                                                        height: "50px",
                                                    }}
                                                >
                                                    <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                        <span style={{ display: 'flex', alignItems: 'center' }}>
                                                            <Checkbox checked={selectedBrands.some((selectedBrands) => selectedBrands.id === brand.id)} />
                                                            <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                                                                <ListItemText primary={brand.brand_name} />
                                                            </div>
                                                        </span>
                                                    </span>
                                                    <span style={{ marginLeft: "auto" }}>
                                                        <ImageBox alt={"brand-img"} src={brand.brand_img} size={'xsmall'} />
                                                    </span>
                                                </MenuItem>
                                            ))
                                            }
                                        </Select>
                                    </CustomSelect>
                                    : <></>
                                }
                                <CustomSelect style={{ minWidth: "170px", marginRight: "10px" }}>
                                    <Select
                                        size={"small"}
                                        displayEmpty
                                        inputProps={{ "aria-label": "Without label" }}
                                        className={"cus-input"}
                                        onChange={handleUserLevelChange}
                                        value={userLevel}
                                    >
                                        <MenuItem value={0}>All Users</MenuItem>
                                        <MenuItem value={1}>User</MenuItem>
                                        <MenuItem value={2}>Admin</MenuItem>
                                    </Select>
                                </CustomSelect>
                                <GlobalSearch
                                    indexName="USER"
                                    setIsLoading={setLoading}
                                    onSuggestions={(records, searchValue) => {
                                        const ids = records?.map?.((r) => r?.properties?.id)
                                        setSearchObject((searchObject) => { return { ...searchObject, ids: ids } })
                                        setCurrentPage(1)
                                    }}
                                    onSearch={(searchValue) => {
                                        setSearchObject((searchObject) => { return { ...searchObject, searchValue: searchValue } })
                                    }}
                                />
                                <IconButton
                                    onClick={() => handleDialogOpen(null)}
                                    className={"icon-btn"}
                                    aria-label="add"
                                >
                                    <AddIcon />
                                </IconButton>
                            </div>
                        </div>
                        <TableContainer style={{ height: "70vh" }}>
                            <Table aria-label="simple table" stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>#</TableCell>
                                        <TableCell>Full Name</TableCell>
                                        <TableCell>Email</TableCell>
                                        <TableCell>Level</TableCell>
                                        <TableCell align="center">Date</TableCell>
                                        <TableCell align="center">Active</TableCell>
                                        <TableCell align="center">Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {loading ?
                                        <TableRow>
                                            <TableCell colSpan={7} align="center" style={{ border: "none", height: "300px", color: "grey" }} >
                                                <CircularProgress size={30} color="inherit" />
                                            </TableCell>
                                        </TableRow>
                                        :
                                        <>
                                            {records.length === 0 ?
                                                <TableRow>
                                                    <TableCell colSpan={11} align="center" style={{ border: "none", height: "300px", color: "grey" }}>
                                                        <ErrorView type={ERROR_COMPONENTS.NOT_FOUND} title='No Users Found' />
                                                    </TableCell>
                                                </TableRow>
                                                :
                                                records?.map((user, i) => (
                                                    <TableRow key={i}>
                                                        <TableCell component="th" scope="row">
                                                            {handleRowNumber(i)}
                                                        </TableCell>
                                                        <TableCell style={{ textTransform: "capitalize" }}>
                                                            {`${user.first_name} ${user.last_name}`} &nbsp;
                                                            {user?.eight_by_eight_agent_id === "" && user?.level <= USER_TYPES?.agent?.level &&
                                                                <Tooltip title="8x8 Agent ID is required to proceed with the score calculation">
                                                                    <IconButton size="small" color="warning">
                                                                        <ReportIcon style={{ height: "16px", width: "16px" }} />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            }
                                                        </TableCell>
                                                        <TableCell>{user.email}</TableCell>
                                                        <TableCell>{user.level > 50 ? "Admin" : "User"}</TableCell>
                                                        <TableCell align="center">
                                                            {moment(user.last_updated_at).format("MM/ DD/ YYYY")}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <CustomSwitch
                                                                inputProps={{ "data-test": `user_enabled_${user.is_enabled === USER_STATUS.enabled}` }}
                                                                disabled={user.user_id === isMyId || !checkEditAccess(user) ? true : false}
                                                                onChange={(e) =>
                                                                    handleUserStatusChange(e, user)
                                                                }
                                                                aria-label="Switch demo"
                                                                checked={user.is_enabled === USER_STATUS.enabled ? true : false}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <Tooltip title="Edit">
                                                                <span>
                                                                    <IconButton onClick={() => handleDialogOpen(user)}
                                                                        disabled={!checkEditAccess(user) || user.is_enabled === 0}
                                                                        color="info"
                                                                    >
                                                                        <EditIcon style={{ height: "20px", width: "20px" }} />
                                                                    </IconButton>
                                                                </span>
                                                            </Tooltip>
                                                            <Tooltip title={checkPermanentDeleteAccess(user) ? "Delete" : ""}>
                                                                <span>
                                                                    <IconButton onClick={() => handleDeleteUser(user)}
                                                                        disabled={!checkPermanentDeleteAccess(user)}>
                                                                        <DeleteIcon style={{ height: "20px", width: "20px" }} color={checkPermanentDeleteAccess(user) ? "error" : "disabled"} />
                                                                    </IconButton>
                                                                </span>
                                                            </Tooltip>
                                                        </TableCell>
                                                    </TableRow>
                                                ))
                                            }
                                        </>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Pagination page={currentPage} onChange={(e, v) => onPageChangeHandler(v)} className="pagination-bar" count={Math.ceil(userCount?.all / ROW_COUNT_PER_PAGE)} color="standard" />
                        <UserModal onClose={handleDialogClose} data={user} open={dialogOpen} showNotification={showNotification} handleRefreshData={handleRefreshData} setUserUpdated={setUserUpdated} editClickCount={editClickCount} />
                        <UserActiveModal onClose={closeDialog} data={user} open={userActiveDialog} status={active} showNotification={showNotification} handleRefreshData={handleRefreshData} />
                        <DeleteDialog
                            onClose={handleDeleteDialogClose}
                            open={deleteDialogOpen}
                            data={user}
                            showNotification={showNotification}
                            handleRefreshData={handleRefreshData}
                        />
                    </div>
                </Grid>
            </Grid>
        </Box>
    );
}

export default withRouter(Users);
