export const HTTP_STATUS_CODES = {
    NotFound: 404,
    InternalServerError: 500,
    BadRequest: 400,
    Conflict: 409,
    AlreadyExist: 403
}

export const COUNTRIES = ["USA", "Canada"]

export const CRMS = ["servicetitan", "vonigo"]

export const AVAILABLE_CRMS = {
    servicetitan: {
        value: "ServiceTitan",
        label: "ServiceTitan"
    },
    vonigo: {
        value: "Vonigo",
        label: "Vonigo"
    },
}

export const ZIP_CODE_PATTERN = {
    us: /\d\d\d\d\d/,
    canedian: /\w\d\w\d\w\d/
}

export const EMPTY_CHECK_REGEX = /^\s+/g

export const JOB_TABS = {
    JOBS: "jobs",
    DRAFTS: "drafts"
}

export const NOTIFICATION_TYPES = {
    ERROR: 'error',
    INFO: 'info',
    SUCCESS: 'success',
    WARNING: 'warning'
}

export const JOB_UPDATE_TYPES = {
    JOB: 'job_details',
    CHURN: 'churn_details',
}

export const TOLL_FREE = {
    label: "TOLL FREE",
    franchiseId: "-1",
    franchiseIdNumber: -1
}

export const CALL_CENTER_BOOKING = {
    booking: 1,
    not_booking: 0,
}

export const ERROR_COMPONENTS = {
    SomthingWentWrong: {
        type: 1,
        title: 'Somthing went wrong',
        description: 'please try again later'
    },
    NoLeadsFound: {
        type: 2,
        title: 'No leads to show',
        description: ''
    },
    RouteNotFound: {
        type: 3,
        title: '404',
        description: 'The page you looking for does not exist'
    },
    NoJobsFound: {
        type: 4,
        title: 'No jobs to show',
        description: ''
    },
    NoDraftsFound: {
        type: 5,
        title: 'No drafts to show',
        description: ''
    },
    NoDataFound: {
        type: 6,
        title: 'No data to show',
        description: ''
    },
    NoEstimatesFound: {
        type: 7,
        title: 'No estimates to show',
        description: ''
    },
    NoFollowUpHistoryFound: {
        type: 8,
        title: 'No Follow-Up history to show',
        description: ''
    },
    NOT_FOUND: 404,
}

export const USER_STATUS = {
    enabled: 1,
    disabled: 0,
}

export const FRANCHISE_STATUS = {
    active: 1,
    deleted: 0, // deactivated
    removed: 2 // only used for delete model open
}

export const JOB_STATUS = ['All', 'Scheduled', 'In Progress', 'Hold', 'Completed', 'Canceled']

export const CHURN_STATUS = {
    churn: 1,
    notChurn: 0,
    all: 2
}

export const STATUS_EMOJI = {
    active: "🟢",
    inactive: "🔴"
}

export const ALL_FRANCHISES_STATUS = 2

export const CHANNEL_STATUS = {
    active: 1,
    deleted: 0
}

export const OWNER_STATUS = {
    active: 1,
    deleted: 0
}

export const FRANCHISE_MIGRATION_STATUS = {
    NotMigtared: 0,
    Migrated: 1,
    CanNotMigrate: 2, // Servicetitan -> vonigo
}

export const FAQ_STATUS = {
    enabled: 1,
    disabled: 0
}

export const CALL_LOG_STATUS = {
    jobsAbandoned: 3,
    jobsOnHold: 1,
    jobsSendToCrm: 0,
    others: 2,
}

export const ROW_COUNT_PER_PAGE = 10

export const SERVICE_TYPES = {
    User: 1,
    Brands: 2
}

export const CLASS_NAMES = {
    ZIP: "zip-code"
}

export const TIME_ZONES = [
    {
        zone: "Hawaii-Aleutian Time (GMT-9:00) ",
        zone_id: "America/Adak"
    },
    {
        zone: "Alaska Time (GMT-8:00) ",
        zone_id: "America/Anchorage"
    },
    {
        zone: "Pacific Time (GMT-7:00) ",
        zone_id: "America/Los_Angeles"
    },
    {
        zone: "Mountain Time (GMT-6:00) ",
        zone_id: "America/Denver"
    },
    {
        zone: "Central Time (GMT-5:00) ",
        zone_id: "America/Chicago"
    },
    {
        zone: "Eastern Time (GMT-4:00) ",
        zone_id: "America/Montreal"
    },
    {
        zone: "Atlantic Time (GMT-3:00) ",
        zone_id: "America/Halifax"
    },
    {
        zone: "Newfoundland Time (GMT-2:30)",
        zone_id: "America/St_Johns"
    }
]
export const KM_PER_MILE = 1.60934

export const callerRequestTypes = {
    CHAT: "CHAT",
    SMS: "SMS",
    EMAIL: "EMAIL",
    CALL: "CALL",
}

export const directCallLogStatus = {
    ON: 1,
    OFF: 0,
}

export const NO_CALLER_ID = "NOCALLERID"

export const userLevels = {
    SUPER_ADMIN: 120,
    ADMIN: 80,
    AGENT: 40,
}