import { Autocomplete, Box, Chip, CircularProgress, IconButton, Popper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import moment from "moment";
import { useEffect, useRef, useState } from "react"
import { CONFIG_MESSAGES, CREATE_JOB_SECTION_IDS } from "../../../../../constants/jobs";
import { getTimeZoneName } from "../../../../../utils/vonigo";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { handleGetAvailabilities, handleGetJobTypeById, handleGetTechnicians } from "../../../../../state-services-dublicate/serviceTitan";
import CustomDatePicker from "../../../../../components/CustomDatePicker";
import CustomTextField from "../../../../../components/CustomTextField";
import RenderInput from "../../../../../components/servicetitan/RenderInput";
import RefreshErrorView from "../../../../../components/refreshView/RefreshErrorView";
import ConfigMessage from "../../../../../components/configMessage/configMessage";
import TechnitianPickingConfirmationModal from "./TechnitianPickingConfirmationModal";

const ESTIMATE_TECHNITIAN_FIELD_NAME = "estimatetechnician"
const BRAND_CODE_FIELD_NAME = "brandcode"
const IS_ESTIMATE_TECHNITIAN = "yes"

export default function CreateAppointmentSection(props) {
    const { franchise, brand, arrivalWindow, job, appointment, setAppointment, isReschedule, appointmentToReschedule, previouslyScheduledTechnicianIds } = props

    return <Box id={CREATE_JOB_SECTION_IDS.APPOINTMENT} style={{ marginBlock: "1em" }} >
        <h4>Appointment Details</h4>
        {<ConfigMessage sectionId={CONFIG_MESSAGES.SERVICETITAN.APPOINTMENT.id} />}
        <CreateAppointmentCard franchise={franchise} brand={brand} arrivalWindow={arrivalWindow} job={job} appointment={appointment} setAppointment={setAppointment} isReschedule={isReschedule} appointmentToReschedule={appointmentToReschedule} previouslyScheduledTechnicianIds={previouslyScheduledTechnicianIds} />
    </Box>
}

function getInitialAvailabilityTimeRange(timeZone = "UTC") {
    if (!timeZone) timeZone = "UTC"
    return { start: (moment(new Date()).tz(timeZone))?.toISOString(), end: moment(new Date()).tz(timeZone).add(6, "d").endOf("d").toISOString() }
}

function CreateAppointmentCard(props) {
    const { franchise, brand, arrivalWindow, job, appointment, setAppointment, appointmentToReschedule, isReschedule, previouslyScheduledTechnicianIds } = props
    const [availabilities, setAvailabilities] = useState()
    const [isAvailabilitiesLoading, setIsAvailabilitiesLoading] = useState()
    const [availabilityTimeRange, setAvailabilityDateRange] = useState(getInitialAvailabilityTimeRange(franchise?.time_zone))
    const [isAvailabilitiesError, setIsAvailabilitiesError] = useState()
    const [selectedTimeSlot, setSelectedTimeSlot] = useState()
    const [availabilityToggle, setAvailabilityToggle] = useState()
    const [technicians, setTechnicians] = useState([])
    const [isTechnicianLoading, setIsTechniciansLoading] = useState(false)
    const [isTechniciansLoadingError, setIsTechniciansLoadingError] = useState(false)
    const [isOpenTechnitianConfirmationModal, setIsOpenTechnitianConfirmationModal] = useState(false)
    const [jobType, setJobType] = useState()

    useEffect(() => {
        handleGetTechnicians(franchise, [], setTechnicians, setIsTechniciansLoading, setIsTechniciansLoadingError, () => { })
    }, [franchise?.id])

    useEffect(() => {
        if (job?.jobTypeId) {
            return handleGetJobTypeById(franchise, job?.jobTypeId, setJobType)
        }
    }, [franchise?.id, job?.jobTypeId])

    useEffect(() => {
        setSelectedTimeSlot()
        setAppointment()
        const technicianShiftsStartTime = moment(availabilityTimeRange?.start)?.format('YYYY-MM-DD') + "T00:00:00Z";
        const durationString = jobType?.duration ? getDurationStringFromSeconds(jobType?.duration) : "30m"
        handleGetAvailabilities(setIsAvailabilitiesLoading, setIsAvailabilitiesError, franchise?.id, brand?.brand_id, brand?.brand_short_code, availabilityTimeRange?.start, availabilityTimeRange?.end, durationString, technicianShiftsStartTime, setAvailabilities); //TODO replace 30m with job duration
    }, [availabilityToggle, brand?.brand_id, franchise?.id, franchise?.time_zone, availabilityTimeRange?.start, availabilityTimeRange?.end, setAppointment, jobType?.duration])

    function processString(str) {
        let lowerCaseString = str.toLowerCase();
        let finalString = lowerCaseString.replace(/[\s\d\W_]/g, '');
        return finalString;
    }

    function getEstimateTechnitian(availableTechnitianIdsInTimeSlot = []) {
        let labeledTechnitians = []
        technicians?.forEach(technitian => {
            technitian?.customFields?.forEach(customField => {
                let formattedCustomField = processString(customField?.name)
                if (formattedCustomField === BRAND_CODE_FIELD_NAME || formattedCustomField === ESTIMATE_TECHNITIAN_FIELD_NAME) {
                    labeledTechnitians.push(technitian)
                    return;
                }
            })
        })
        if (labeledTechnitians?.length === 0) {
            return []
        } else {
            let estimateTechnitians = []
            labeledTechnitians.forEach(technitian => {
                technitian?.customFields?.forEach(customField => {
                    let formattedCustomField = processString(customField?.name)
                    if (formattedCustomField === ESTIMATE_TECHNITIAN_FIELD_NAME && customField?.value?.toLowerCase() === IS_ESTIMATE_TECHNITIAN) {
                        estimateTechnitians.push(technitian)
                    }
                })
            })
            if (estimateTechnitians?.length === 0) {
                return []
            } else {
                let estimateTechnitianIds = []
                estimateTechnitians?.sort((a, b) => a?.name?.localeCompare(b?.name))
                estimateTechnitians.forEach(technitian => {
                    estimateTechnitianIds.push(technitian?.id)
                })
                let filteredTechnitianIds = estimateTechnitianIds.filter(id => availableTechnitianIdsInTimeSlot.includes(id))
                if (filteredTechnitianIds?.length > 1) {
                    return [filteredTechnitianIds[0]]
                } else {
                    return filteredTechnitianIds
                }
            }
        }
    }

    return isAvailabilitiesError ? <RefreshErrorView onRefresh={() => setAvailabilityToggle(!availabilityToggle)} />
        : <AvailabilityTable
            franchise={franchise}
            brand={brand}
            arrivalWindow={arrivalWindow}
            processString={processString}
            availabilityTimeRange={availabilityTimeRange}
            setAvailabilityDateRange={setAvailabilityDateRange}
            isAvailabilitiesLoading={isAvailabilitiesLoading}
            availabilities={availabilities}
            appointment={appointment}
            getEstimateTechnitian={getEstimateTechnitian}
            setAppointment={setAppointment}
            selectedTimeSlot={selectedTimeSlot}
            setSelectedTimeSlot={setSelectedTimeSlot}
            appointmentToReschedule={appointmentToReschedule}
            technicians={technicians}
            isTechnicianLoading={isTechnicianLoading}
            isTechniciansLoadingError={isTechniciansLoadingError}
            isReschedule={isReschedule}
            previouslyScheduledTechnicianIds={previouslyScheduledTechnicianIds}
            isOpenTechnitianConfirmationModal={isOpenTechnitianConfirmationModal}
            setIsOpenTechnitianConfirmationModal={setIsOpenTechnitianConfirmationModal}
        />
}

function AvailabilityTable(props) {
    const { franchise, brand, processString, arrivalWindow, getEstimateTechnitian, availabilityTimeRange, setAvailabilityDateRange, isAvailabilitiesLoading, availabilities, appointment,
        setAppointment, selectedTimeSlot, setSelectedTimeSlot, appointmentToReschedule, technicians, isTechnicianLoading, isReschedule, previouslyScheduledTechnicianIds, isOpenTechnitianConfirmationModal, setIsOpenTechnitianConfirmationModal } = props

    const tempTechnitianIds = useRef([])

    function addWeekFormDateRange() {
        const newDateRange = { start: addWeekForDate(availabilityTimeRange?.start), end: addWeekForDate(availabilityTimeRange?.end) }
        onDateRangeChanged(newDateRange)
    }

    function substractWeekFormDateRange() {
        const newDateRange = { start: substractWeekForDate(availabilityTimeRange?.start), end: substractWeekForDate(availabilityTimeRange?.end) }
        onDateRangeChanged(newDateRange)
    }

    function onDateRangeChanged(newRange) {
        setSelectedTimeSlot()
        setAppointment()
        setAvailabilityDateRange(newRange)
    }

    function onSelectedTimeslotChanged(slot, arrivalWindow) {
        let dat = new Date(slot?.start)
        dat.setMinutes(dat.getMinutes() + arrivalWindow);
        let autoArrivalWindowEnd = dat.toISOString()
        setSelectedTimeSlot(slot)
        let technitianIds = getEstimateTechnitian(slot?.available_technician_ids)
        setAppointment({ ...appointment, start: slot?.start, end: slot?.end, arrivalWindowStart: slot?.start, arrivalWindowEnd: autoArrivalWindowEnd, technicianIds: technitianIds })
    }

    function onArrivalWindowStartChanged(date) {
        const startDate = date.toISOString()
        const newAppointment = { ...appointment, arrivalWindowStart: startDate }
        setAppointment(newAppointment)
    }

    function onArrivalWindowEndChanged(date) {
        const endDate = date.toISOString()
        setAppointment({ ...appointment, arrivalWindowEnd: endDate });
    }

    function getIsPreviousButtonDisabled() {
        let disablebutton
        if (franchise?.time_zone !== "") {
            disablebutton = moment().tz(franchise?.time_zone)?.format("YYYY-MM-DD") === moment(availabilityTimeRange?.start)?.tz(franchise?.time_zone)?.format("YYYY-MM-DD")
        }
        else {
            disablebutton = moment()?.utc()?.format("YYYY-MM-DD") === moment(availabilityTimeRange?.start)?.utc()?.format("YYYY-MM-DD")
        }
        return disablebutton
    }

    function filterEstimateTechnitiansForBrand(brandShortCode) {
        let labeledTechnitians = []
        technicians?.forEach(technitian => {
            for (let customField of technitian?.customFields) {
                let formattedCustomField = processString(customField?.name)
                if (formattedCustomField === BRAND_CODE_FIELD_NAME || formattedCustomField === ESTIMATE_TECHNITIAN_FIELD_NAME) {
                    labeledTechnitians.push(technitian)
                    return;
                }
            }
        })
        if (labeledTechnitians?.length === 0) {
            let availableTechnitianIds = technicians?.filter((t) => selectedTimeSlot?.available_technician_ids?.includes(t?.id))?.map?.((t) => t?.id)
            return technicians?.length > 0 ? availableTechnitianIds : []
        } else {
            let brandTechnitians = []
            labeledTechnitians.forEach(technitian => {
                technitian?.customFields?.forEach(customField => {
                    let formattedCustomField = processString(customField?.name)
                    if (formattedCustomField === BRAND_CODE_FIELD_NAME && customField?.value?.replace(/[^a-zA-Z0-9]/g, "")?.toLowerCase() === brandShortCode) {
                        brandTechnitians.push(technitian)
                    }
                })
            })
            if (brandTechnitians?.length === 0) {
                labeledTechnitians.forEach(technitian => {
                    technitian?.customFields?.forEach(customField => {
                        let formattedCustomField = processString(customField?.name)
                        if (formattedCustomField === BRAND_CODE_FIELD_NAME && !customField?.value) {
                            brandTechnitians.push(technitian)
                        }
                    })
                })
            }
            let estimateTechnitians = []
            labeledTechnitians.forEach(technitian => {
                technitian?.customFields?.forEach(customField => {
                    let formattedCustomField = processString(customField?.name)
                    if (formattedCustomField === ESTIMATE_TECHNITIAN_FIELD_NAME && customField?.value?.toLowerCase() === IS_ESTIMATE_TECHNITIAN) {
                        estimateTechnitians.push(technitian)
                    }
                })
            })
            let estimateTechnitiansOfBrand = []
            brandTechnitians.forEach(technitian => {
                technitian?.customFields?.forEach(customField => {
                    let formattedCustomField = processString(customField?.name)
                    if (formattedCustomField === ESTIMATE_TECHNITIAN_FIELD_NAME && customField?.value?.toLowerCase() === IS_ESTIMATE_TECHNITIAN) {
                        estimateTechnitiansOfBrand.push(technitian)
                    }
                })
            })
            if (estimateTechnitiansOfBrand?.length !== 0) {
                let brandTechnicansButNotEstimateTechnitians = brandTechnitians?.filter((t) => !estimateTechnitiansOfBrand?.includes(t))
                let brandTechniciansButNotEstimateTechnitiansIds = brandTechnicansButNotEstimateTechnitians?.filter((t) => selectedTimeSlot?.available_technician_ids?.includes(t?.id))?.map?.((t) => t?.id)
                let availableTechnitianIds = estimateTechnitiansOfBrand?.filter((t) => selectedTimeSlot?.available_technician_ids?.includes(t?.id))?.map?.((t) => t?.id)
                return availableTechnitianIds.concat(brandTechniciansButNotEstimateTechnitiansIds)
            }
            else if (estimateTechnitiansOfBrand?.length === 0 && brandTechnitians?.length > 0) {
                let availableTechnitianIds = brandTechnitians?.filter((t) => selectedTimeSlot?.available_technician_ids?.includes(t?.id))?.map?.((t) => t?.id)
                return availableTechnitianIds;
            } else if (estimateTechnitians?.length !== 0) {
                let availableTechnitianIds = estimateTechnitians?.filter((t) => selectedTimeSlot?.available_technician_ids?.includes(t?.id))?.map?.((t) => t?.id)
                return availableTechnitianIds
            } else {
                let availableTechnitianIds = technicians?.filter((t) => selectedTimeSlot?.available_technician_ids?.includes(t?.id))?.map?.((t) => t?.id)
                return availableTechnitianIds

            }
        }
    }

    function checkForNonEstimateTechnitians(technitianIds) {
        let newlyAddingTechnitianId = technitianIds.filter(id => !appointment?.technicianIds.includes(id))
        let newlyAddingTechnitian = technicians.find(t => t.id === newlyAddingTechnitianId[0])
        if (newlyAddingTechnitian?.customFields?.length > 0) {
            let isEstimateTechnitian = false
            newlyAddingTechnitian?.customFields.forEach(customField => {
                let formattedCustomField = processString(customField?.name)
                if (formattedCustomField === ESTIMATE_TECHNITIAN_FIELD_NAME && customField?.value?.toLowerCase() === IS_ESTIMATE_TECHNITIAN) {
                    isEstimateTechnitian = true
                }
            })
            if (!isEstimateTechnitian) {
                tempTechnitianIds.current = technitianIds
                setIsOpenTechnitianConfirmationModal(true)
            } else {
                setAppointment({ ...appointment, technicianIds: technitianIds });
            }
        } else {
            setAppointment({ ...appointment, technicianIds: technitianIds });
        }
    }

    function wantToPickNonEstimateTechnitian() {
        setAppointment({ ...appointment, technicianIds: tempTechnitianIds.current });
        setIsOpenTechnitianConfirmationModal(false)
        tempTechnitianIds.current = []
    }

    return <>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "1em", marginBottom: "0.5em" }}>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <IconButton data-test="appointment_back_button" disabled={getIsPreviousButtonDisabled()} className={getIsPreviousButtonDisabled() ? "btn-disable" : "btn-primary"} style={{ height: "30px", width: "30px", marginTop: "5px" }} onClick={substractWeekFormDateRange}><ArrowLeftIcon /></IconButton>
                    <div style={{ marginLeft: "40px", marginRight: "40px", paddingTop: "4%" }}><strong>{getFranchiseFormattedTime(availabilityTimeRange.start, franchise?.time_zone)}</strong> to <strong>{getFranchiseFormattedTime(availabilityTimeRange.end, franchise?.time_zone)}</strong></div>
                    <IconButton data-test="appointment_forward_button" className={"btn-primary"} style={{ height: "30px", width: "30px", marginTop: "5px" }} onClick={addWeekFormDateRange}><ArrowRightIcon /></IconButton>
                </div>
                <Box flexGrow={2} />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
                <div>
                    {franchise?.time_zone ? <p>{`*All time slots are based on the franchise time zone (${getTimeZoneName(franchise?.time_zone)})`}</p> : <p style={{ color: "red" }}>*Franchise time zone not provided. All time slots are based on UTC time</p>}
                    {appointmentToReschedule?.start ? <box style={{ backgroundColor: '#34C759', padding: '5px', fontWeight: 'bold' }}>*Previously Scheduled Date: ({franchise?.time_zone ? moment(appointmentToReschedule?.arrivalWindowStart)?.tz(franchise?.time_zone)?.format("MM/DD/yyyy h:mm A") : moment(appointmentToReschedule?.arrivalWindowStart)?.utc()?.format("MM/DD/yyyy h:mm A")})</box> : <></>}
                </div>
                <Box sx={{ display: 'flex', flexDirection: "row" }}>
                    <p style={{ color: "red" }}>*</p><p >Select Available Job Start and End Timeslot</p>
                </Box>
            </Box>
            <Box style={{ minHeight: "80px" }}>
                {isAvailabilitiesLoading ?
                    <div style={{ paddingLeft: "45%", marginTop: "25px" }}> <CircularProgress size={30} color="inherit" /></div>
                    : <TableContainer>
                        <TimeSlotTable franchise={franchise} arrivalWindow={arrivalWindow} availabilityTimeRange={availabilityTimeRange} availabilities={availabilities} selectedTimeSlot={selectedTimeSlot} setSelectedTimeSlot={onSelectedTimeslotChanged} />
                    </TableContainer>}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: "column", flexWrap: "wrap", width: "100%", gap: "0.5em", zIndex: "1000" }}>
                <Box width="100%" display="flex" flexDirection="row" gap="1em" >
                    <CustomDatePicker
                        label={"Arrival Window Start"}
                        maxTime={appointment?.arrivalWindowStart ? new Date(appointment?.arrivalWindowStart) : null}
                        minTime={appointment?.start ? new Date(appointment?.start) : null}
                        disabled={true}
                        value={appointment?.arrivalWindowStart ? franchise?.time_zone ? new Date(appointment?.arrivalWindowStart) : new Date(appointment?.arrivalWindowStart) : null}
                        onChange={onArrivalWindowStartChanged}
                        renderInput={(params) => <CustomTextField {...params} sx={{ width: "30%", flexGrow: "1" }} size="small" required={true} label="Job Start" />}
                        timeZone={franchise?.time_zone ? franchise?.time_zone : "UTC"}
                    />
                    <CustomDatePicker
                        label={"Arrival Window End"}
                        minTime={appointment?.arrivalWindowStart ? new Date(appointment?.arrivalWindowStart) : null}
                        maxTime={appointment?.arrivalWindowEnd ? new Date(new Date(appointment?.arrivalWindowStart).getTime() + 24 * 60 * 60 * 1000) : null}
                        disabled={appointment?.start ? false : true}
                        value={appointment?.arrivalWindowEnd ? new Date(appointment?.arrivalWindowEnd) : null}
                        onChange={onArrivalWindowEndChanged}
                        renderInput={(params) => <CustomTextField {...params} sx={{ width: "30%", flexGrow: "1" }} size="small" required={true} label="Job End" />}
                        timeZone={franchise?.time_zone ? franchise?.time_zone : "UTC"}
                    />
                </Box>
                <Box sx={{ zIndex: "2000" }}>
                    <Autocomplete
                        sx={{ width: "100%" }}
                        size="small"
                        disablePortal={true}
                        PopperComponent={
                            (props) => {
                                return <Popper {...props} placement="top" />
                            }
                        }
                        disableClearable={(isReschedule && !selectedTimeSlot)}
                        multiple={true}
                        noOptionsText={selectedTimeSlot || isReschedule ? isReschedule ? "Please select a timeslot" : "No technicians available" : "Please select a timeslot"}
                        loading={isTechnicianLoading || isAvailabilitiesLoading}
                        options={isReschedule && !selectedTimeSlot ? appointment?.technicianIds ?? [] : !selectedTimeSlot ? [] : filterEstimateTechnitiansForBrand(brand?.brand_short_code) ?? []}
                        getOptionLabel={(technicianId) => getSelectedTechnicianFromTechnicianArray(technicianId, technicians)?.name}
                        renderOption={(props, technicianId) => {
                            const technician = getSelectedTechnicianFromTechnicianArray(technicianId, technicians)
                            return <RenderInput {...props} key={technician?.id} content={technician?.name} />
                        }}
                        renderTags={(isReschedule && !selectedTimeSlot) ? (techIds) => {
                            return techIds?.map?.((tId) => {
                                const technician = getSelectedTechnicianFromTechnicianArray(tId, technicians)
                                return <Chip size="small" label={technician?.name} />
                            })
                        } : undefined}
                        value={isReschedule && !selectedTimeSlot ? previouslyScheduledTechnicianIds : appointment?.technicianIds ?? []}
                        onChange={(_, techniciansIds) => {
                            if (appointment?.technicianIds?.length === 0) {
                                checkForNonEstimateTechnitians(techniciansIds)
                            } else if (appointment?.technicianIds?.length < techniciansIds?.length) {
                                checkForNonEstimateTechnitians(techniciansIds)
                            } else {
                                setAppointment({ ...appointment, technicianIds: techniciansIds });
                            }
                        }}
                        renderInput={(params) => <CustomTextField {...params} label="Technicians" />} />
                </Box>
            </Box>
        </Box>
        {isOpenTechnitianConfirmationModal ? <TechnitianPickingConfirmationModal isOpen={isOpenTechnitianConfirmationModal} onCloseHandler={() => setIsOpenTechnitianConfirmationModal(false)} wantToPickNonEstimateTechnitian={wantToPickNonEstimateTechnitian} /> : <></>}
    </>
}

function TimeSlotTable(props) {
    const { franchise, arrivalWindow, availabilityTimeRange, availabilities, selectedTimeSlot, setSelectedTimeSlot } = props
    return <Table border={"1px"} stickyHeader>
        <TableHead>
            {getTimeSlotsHead(franchise?.time_zone, availabilityTimeRange?.start, availabilityTimeRange?.end)}
        </TableHead>
        <TableBody style={{ overflowY: "scroll" }}>
            {getTimeSlotsRows(availabilityTimeRange, arrivalWindow, franchise?.time_zone, availabilities, selectedTimeSlot, setSelectedTimeSlot)}
        </TableBody>
    </Table>
}

function addDays(date = new Date(), numberOfDaysToAdd = 1) {
    return (new Date(date).setDate(new Date(date).getDate() + numberOfDaysToAdd))
}

function getDates(startDate = (new Date()), stopDate = addDays(startDate, 7), timeZone = 'UTC') {
    if (!timeZone) timeZone = "UTC"
    const dateArray = [];
    let currentDateMoment = moment(startDate).tz(timeZone).startOf("d");
    const stopDateMoment = moment(stopDate).tz(timeZone).endOf("d")
    while (currentDateMoment <= stopDateMoment) {
        dateArray.push(new Date(currentDateMoment));
        currentDateMoment = addDays(currentDateMoment, 1);
    }
    return dateArray;
}

function getTimeSlotsHead(timeZone = "UTC", startDate, endDate) {
    if (!timeZone) timeZone = "UTC"
    const dates = getDates(new Date(startDate), new Date(endDate), timeZone)
    return <TableRow>{dates?.map((d) => {
        const columnBackgroundColor = getColumnBackgroundColor(timeZone, d)
        return <TableCell key={d.toISOString()} sx={{ backgroundColor: columnBackgroundColor, textAlign: "center", maxWidth: "80px" }} >
            <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                <Box>
                    {`${moment(d)?.tz(timeZone)?.format("MM/DD/yyyy")}`}
                </Box>
                <Box>
                    {`${moment(d)?.tz(timeZone)?.format('dddd')}`}
                </Box>
            </Box>
        </TableCell>
    })}</TableRow>
}

function getTimeSlotsRows(availabilityTimeRange, arrivalWindow, timeZone = "UTC", availabilities, selectedTimeSlot, setSelectedTimeSlot) {
    if (!timeZone) timeZone = "UTC"
    if (!availabilities) return <></>
    let tableBodyContent = [];
    const dateSlotsMap = getDatesTimeSlotes(availabilityTimeRange, availabilities, timeZone)
    for (let date in dateSlotsMap) {
        const timeSlots = dateSlotsMap[date] ?? []
        const availableTimeSlots = timeSlots.filter((s) => s.is_available)
        let columContent = []
        const currentTime = moment(new Date()).tz(timeZone)
        const filteredAvailableTimeSlots = availableTimeSlots?.filter((s) => moment(Date.parse(s?.start)).tz(timeZone) > currentTime)
        if (filteredAvailableTimeSlots?.length === 0) {
            columContent.push(<Box key={"NO_TIME_SLOT"} style={{ textAlign: "center", fontWeight: "bold", maxWidth: "80px", margin: "auto" }} >{"No timeslots available"}</Box>)
        } else {
            for (let i = 0; i < filteredAvailableTimeSlots?.length; i++) {
                const timeSlot = filteredAvailableTimeSlots[i]
                const previousTimeSlot = i > 0 ? filteredAvailableTimeSlots?.[i - 1] : null
                if (!timeSlot.is_available) continue
                const startTime = moment(Date.parse(timeSlot?.start)).tz(timeZone)
                const startTimeInCell = startTime.format('hh:mm')
                const endTimeInCell = moment(Date.parse(timeSlot?.end)).tz(timeZone).format('hh:mm')
                const previousStartTime = previousTimeSlot ? moment(Date.parse(previousTimeSlot?.start)).tz(timeZone) : ""
                const shouldIncludeAmCell = (timeSlot && i === 0 && startTime.format('LT').includes("AM"))
                const shouldIncludePmCell = ((!previousStartTime || previousStartTime?.format?.('LT')?.includes?.("AM")) && startTime.format('LT').includes("PM"))
                const backgroundColor = (selectedTimeSlot?.start && timeSlot?.start && selectedTimeSlot?.start === timeSlot?.start) ? "#00a79d" : ""
                columContent.push(<Box key={timeSlot?.start} textAlign={"start"} >
                    {(shouldIncludeAmCell) ? <Box style={{ textAlign: "center", fontWeight: "bold" }} >{"AM"}</Box> : <></>}
                    {(shouldIncludePmCell) ? <Box style={{ textAlign: "center", fontWeight: "bold" }} >{"PM"}</Box> : <></>}
                    <Box data-test={"time_slot_" + startTimeInCell + "_" + endTimeInCell} style={{ textAlign: "center", marginBlock: "0.5em", paddingInline: "0.4em", backgroundColor: backgroundColor, borderRadius: "0.5em" }} onClick={() => { if (timeSlot) setSelectedTimeSlot(timeSlot, arrivalWindow) }}>{`${timeSlot?.start ? startTimeInCell : ""} - ${timeSlot?.end ? endTimeInCell : ""}`}</Box>
                </Box>)
            }
        }
        const columnBackgroundColor = getColumnBackgroundColor(timeZone, date)
        tableBodyContent.push(<TableCell key={date?.date} sx={{ backgroundColor: columnBackgroundColor, verticalAlign: "top", cursor: "pointer", maxWidth: "80px" }}>{columContent}</TableCell>)
    }
    return <TableRow>{tableBodyContent}</TableRow>
}

function getDatesTimeSlotes(availabilityTimeRange, availabilities, timeZone = 'UTC') {
    if (!timeZone) timeZone = "UTC"
    if (!availabilities) return {}
    const dates = getDates(Date.parse(availabilityTimeRange.start), Date.parse(availabilityTimeRange.end), timeZone)
    const datesSlotsMap = {}
    for (let date of dates) {
        const dateMoment = moment(date).tz(timeZone)
        const dateSlots = []
        if (Array.isArray(availabilities.available_time_slots)) {
            for (let slot of availabilities.available_time_slots) {
                const startTime = moment(slot.start)?.tz(timeZone)
                const endOfTheDate = moment(date)?.tz(timeZone).endOf("d")
                if (startTime < endOfTheDate && dateMoment <= startTime) {
                    dateSlots.push(slot)
                }
            }
        }
        datesSlotsMap[date] = dateSlots
    }
    return datesSlotsMap
}

function getColumnBackgroundColor(timeZone = "UTC", date) {
    if (!timeZone) timeZone = "UTC"
    return getIsDateToday(timeZone, date) ? 'form.secondary' : 'background.main'
}

function getIsDateToday(timeZone = "UTC", date) {
    if (!timeZone) timeZone = "UTC"
    const isSameDate = moment(date).tz(timeZone)?.format("MM/DD/yyyy") === moment(new Date())?.tz(timeZone)?.format("MM/DD/yyyy")
    return isSameDate;
}

function substractWeekForDate(dateString = (new Date()).toISOString()) {
    const date = moment(new Date(dateString))
    date?.subtract(7, "d");
    return date?.toISOString()
}

function addWeekForDate(dateString = (new Date()).toISOString()) {
    const date = moment(new Date(dateString))
    date?.add(7, "d");
    return date?.toISOString()
}

function getSelectedTechnicianFromTechnicianArray(id, technicians = []) {
    return technicians.find((t) => t.id === id)
}

function getFranchiseFormattedTime(timeISOString, timeZone = "UTC") {
    if (!timeZone) timeZone = "UTC"
    return moment(timeISOString)?.tz(timeZone)?.startOf("d")?.format?.("MM/DD/yyyy") ?? ""
}

function getDurationStringFromSeconds(seconds) {
    const minutes = Math.floor(seconds / 60)
    const durationString = `${minutes}m`
    return durationString
}