import iBSBrandLogo from "../../assets/img/brand.png"
import { Box, Tooltip, Typography } from "@mui/material";
import ImageBox from "../LogoBox/ImageBox";
import { getUserFullName } from "../../utils/miscellaneous";
import { STATUS_EMOJI } from "../../constants/common"

export default function RenderInput(props) {
    const { content } = props
    return (
        <Typography {...props}>
            {content}
        </Typography>
    );
}

export function renderBrandOption(props, brand) {
    return <Box data-test={`brand_option_${brand.id}`} {...props} key={brand?.id} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" paddingRight="1em" >
        <RenderInput key={brand?.id} content={brand?.brand_name ?? ""} />
        <Box flexGrow={2} />
        <ImageBox alt={"brand-img"} src={brand.brand_img ?? iBSBrandLogo} size={'xsmall'} />
    </Box>
}

export function renderUserOption(props, user) {
    return (
        <Tooltip key={user.id} title={user?.email ?? ""}>
            <RenderInput {...props} key={user.id} content={getUserFullName(user)} />
        </Tooltip>
    );
}

export function renderUserOptionForMiddleWearStatistics(props, user) {
    return (
        <Tooltip key={user.id} title={user?.email ?? ""}>
            <RenderInput {...props} key={user.id} content={getFranchiseOptionLabel(user)} />
        </Tooltip>
    );
}

function getFranchiseOptionLabel(user) {
    const statusSymbol = user?.is_enabled === 0 ? STATUS_EMOJI.inactive : STATUS_EMOJI.active
    return `${statusSymbol}  ${`${user.first_name} ${user.last_name}`}`
}