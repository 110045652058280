import { useState,useMemo } from 'react';
import { IconButton, TableCell, TableRow } from '@mui/material'
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import CustomTextField from "../../../../components/CustomTextField";
import { Box } from '@mui/system';


export default function EditJobTypeRow(props) {
    const { typeIndex, jobTypes, setJobTypes, jobType, onCancel, setUpdateBrandJobTypes, updateBrandJobTypes } = props

    const [currentJobTypeDetails, setCurrentJobTypeDetails] = useState(jobType)
    const [duplicatedValue, setDuplicatedValue] = useState()

    // to save or update the job types
    const onSave = () => {
        // if the type index is -1, then it is a new type
        if (typeIndex === -1) {
            // split the type name and using comma
            const newJobTypes = currentJobTypeDetails?.type_name?.trim?.().split(",").map((type) =>  (type.trim() != "" ? { type_name: type.trim(), is_enabled: 1 } : null)).filter((type) => (type != null))

            setJobTypes([...jobTypes, ...newJobTypes])
            setUpdateBrandJobTypes([...updateBrandJobTypes, ...newJobTypes])
        } else {
            const newJobTypes = jobTypes.map((job, index) => {
                if (index == typeIndex) {
                    return {...currentJobTypeDetails,type_name:currentJobTypeDetails.type_name.trim()}
                }
                return job
            })
            setJobTypes(newJobTypes)
            const index = updateBrandJobTypes.findIndex(updateBrandJobType => updateBrandJobType === jobType);

            if (index === -1) {
                setUpdateBrandJobTypes([...updateBrandJobTypes, {...currentJobTypeDetails,type_name:currentJobTypeDetails.type_name.trim()}])
            } else {
                const newUpdateBrandJobTypes = updateBrandJobTypes.toSpliced(index, 1, {...currentJobTypeDetails,type_name:currentJobTypeDetails.type_name.trim()});
                setUpdateBrandJobTypes(newUpdateBrandJobTypes)
            }
        }
        onCancel()
    }

    // check if the type name already exists in the job types list
    const isTypeNameAlreadyExists = useMemo(() => {
        // if the type index is not -1, then it is an update so we don't need to split it using comma
        return typeIndex != -1 ? 
        currentJobTypeDetails && jobTypes?.some((j) => j?.type_name?.toLowerCase?.() === currentJobTypeDetails?.type_name?.trim?.().toLowerCase?.() && jobTypes.indexOf(j) !== typeIndex)
         : currentJobTypeDetails && currentJobTypeDetails?.type_name?.trim?.().split(",")?.find((singleTypeName) =>
         jobTypes?.some((j) => j?.type_name?.toLowerCase?.() === singleTypeName?.toLowerCase?.().trim?.())
     )
    }, [currentJobTypeDetails?.type_name, jobTypes])

    // check if the type name already entered in the current job type list
    const checkDuplicateTaskName = useMemo(() => {
        const typeNames = currentJobTypeDetails?.type_name?.trim?.().split(",")
        const uniqueValues = new Set();
        if (!typeNames) return false
        for (const value of typeNames) {
            if (uniqueValues.has(value.trim().toLowerCase())) {
                setDuplicatedValue(value)
                return true;
            }
            uniqueValues.add(value.trim().toLowerCase());
        }
        setDuplicatedValue()
        return false
    }, [currentJobTypeDetails.type_name])

    return <TableRow key={typeIndex} >
        <TableCell component="th" scope="row">{typeIndex === -1 ? "#" : typeIndex+1}</TableCell>
        <TableCell component="th" scope="row">
            <Box sx={{ height: "5em" }} >
                <CustomTextField
                    inputProps={{
                        "data-test": "brand_job_type"
                    }}
                    required
                    value={currentJobTypeDetails?.type_name ?? null}
                    onChange={(e) => setCurrentJobTypeDetails({ ...currentJobTypeDetails, type_name: e?.target?.value.replace(/\s+/g, ' ') })}
                    label={"Job Type"}
                    size={"small"}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    error={typeIndex === -1 ? isTypeNameAlreadyExists || checkDuplicateTaskName : isTypeNameAlreadyExists} 
                    helperText={typeIndex === -1 ? isTypeNameAlreadyExists ? "Job type already exists" : checkDuplicateTaskName ? `Duplicate job type: ${duplicatedValue}` : "" : isTypeNameAlreadyExists ? "Job type already exists" : ""}
                />
            </Box>
        </TableCell>

        <TableCell align='left' sx={{paddingLeft:'0px'}}>
            <IconButton sx={{paddingLeft:'0px'}} data-test="brand_job_type_close" color="primary" title='Cancel' onClick={() => onCancel()} >
                <CloseIcon style={{ height: "20px", width: "20px" }} />
            </IconButton>
            <IconButton data-test="brand_job_type_save" type={"button"} color="primary" title='Save' disabled={typeIndex === -1 ? isTypeNameAlreadyExists || checkDuplicateTaskName : isTypeNameAlreadyExists || currentJobTypeDetails?.type_name?.trim?.()=="" || !currentJobTypeDetails} onClick={() => onSave()}>
                <SaveIcon style={{ height: "20px", width: "20px" }} />
            </IconButton>
        </TableCell>
    </TableRow>
}
