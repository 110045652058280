import { IconButton, TableRow, TableCell } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from "@mui/icons-material/Delete";

export default function BrandOwnerRow(props) {
    const { key, owner, onEdit, onDelete } = props
    return <TableRow key={key}>
        <TableCell component="th" scope="row">{owner.owner_name}</TableCell>
        <TableCell align="left">{owner.owner_email}</TableCell>
        <TableCell align="left" sx={{paddingLeft:'0px'}}>
            <IconButton sx={{paddingLeft:'0px'}} data-test="brand_owner_edit" color="info" title='Edit' onClick={onEdit} >
                <EditIcon style={{ height: "20px", width: "20px" }} />
            </IconButton>
            <IconButton data-test="brand_owner_delete" style={{ marginRight: "15px" }} title='Delete' color="error" onClick={onDelete} >
                <DeleteIcon style={{ height: "20px", width: "20px" }} />
            </IconButton>
        </TableCell>
    </TableRow>
}