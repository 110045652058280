import { Button, Container, Dialog, IconButton, Tooltip, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

export default function EmptyTechnicianConfirmationModal({ isOpen, wantToBookJobWithEmptyTechnicians, onCloseHandler } ) {

    function onYesPressed() {
        wantToBookJobWithEmptyTechnicians()
    }

    function onNoPressed() {
        onCloseHandler()
    }

    return <Dialog maxWidth="md" open={isOpen} onClose={onCloseHandler}>
        <Tooltip title={"Close"}>
            <IconButton onClick={onCloseHandler} style={{ position: "absolute", right: "5px", top: "5px" }} size={"small"} aria-label="delete">
                < CloseIcon style={{ height: "15px", width: "15px" }} />
            </IconButton>
        </Tooltip>
        <Container sx={{ display: "flex", flexDirection: "column", paddingTop: "2em", paddingBottom: "2em", alignContent: "center", gap: "1em" }}>
            <Typography style={{ paddingTop: "0.5em" }} variant="p">Are you sure you want to proceed without a technician ?</Typography>
            <Container sx={{ display: "flex", flexDirection: "row", justifyContent: "center", gap: "1em" }} >
                <Button size="small" sx={{marginLeft:"auto"}} onClick={onNoPressed} className="btn-secondary btn-no">No</Button>
                <Button variant="contained" size="small" onClick={onYesPressed} className="btn-primary" >Yes</Button>
            </Container>
        </Container>
    </Dialog >;
}
